import React, { useState } from "react";
import { IconChervonColor, IconChevronTop, IconExportRed } from "../icons";
import { IconChervon } from "../../transaksi/Icon/icons";
import ExportSaldo from "../exportSaldo";
import { IconCLose } from "../../../assets/icon";

// Data statis untuk demonstrasi
const staticData = [
  { month: "Jan", date: "2024-01-17", amount: 2000000 },
  { month: "Feb", date: "2024-02-14", amount: 1500000 },
  { month: "Mar", date: "2024-03-15", amount: 2500000 },
  { month: "Apr", date: "2024-04-10", amount: 1800000 },
  { month: "Mei", date: "2024-05-22", amount: 3000000 },
  { month: "Mei", date: "2024-05-17", amount: 4000000 },
  { month: "Jun", date: "2024-06-05", amount: 2200000 },
  { month: "Jul", date: "2024-07-11", amount: 2700000 },
  { month: "Aug", date: "2024-08-09", amount: 1600000 },
  { month: "Sep", date: "2024-09-18", amount: 2400000 },
  { month: "Oct", date: "2024-10-12", amount: 2800000 },
  { month: "Nov", date: "2024-11-15", amount: 1900000 },
  { month: "Dec", date: "2024-12-14", amount: 2300000 },
];

const months = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

interface DetailSaldoProps {
  onClose: () => void;
}

const DetailSaldo: React.FC<DetailSaldoProps> = ({ onClose }) => {
  const [currentMonthIndex, setCurrentMonthIndex] = useState(0);
  const [activeMonth, setActiveMonth] = useState<string | null>(null);
  const [filteredData, setFilteredData] = useState<typeof staticData>(staticData);
  const [showExport, setShowExport] = useState(false);
  const handleMonthChange = (direction: "next" | "prev") => {
    if (direction === "next") {
      setCurrentMonthIndex((prevIndex) => Math.min(prevIndex + 1, months.length - 6));
    } else {
      setCurrentMonthIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    }
  };

  const handleMonthClick = (month: string) => {
    setActiveMonth(month);
    const dataForMonth = staticData.filter((item) => item.month === month);
    setFilteredData(dataForMonth);
  };

  const handleShowAll = () => {
    setActiveMonth(null);
    setFilteredData(staticData);
  };

  const displayedMonths = months.slice(currentMonthIndex, currentMonthIndex + 6);

  const handleExport = () => {
    setShowExport(!showExport);
  };

  const handleClose = () => {
    setShowExport(false);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white w-[473px] rounded-3xl px-8 pb-10 pt-5 relative ms-64 ">
        <button onClick={onClose} className="flex justify-end w-full mb-[15px]">
          {IconCLose("24", "24")}
        </button>
        <div onClick={handleExport} className="flex justify-between">
          <p className="text-[14px] font-[Plus Jakarta Sans] font-semibold text-[#25282B]">Detail Mutasi</p>
          <div className="flex items-center gap-[10px] border border-[#E7E7F0] rounded-full py-[8px] px-[19px] cursor-pointer">
            <IconExportRed />
            <p className="mt-[-5px] text-red-500 font-semibold">Export</p>
            <div className="mt-[2px]">{IconChervonColor("red")}</div>
          </div>
        </div>
        <div className="absolute right-[32px] mt-[10px] z-40">{showExport && <ExportSaldo onClose={handleClose} />}</div>
        <div className="flex gap-2 mt-4 items-center border-b pb-[10px] border-slate-100">
          <button onClick={() => handleMonthChange("prev")} className="rotate-90 outline-none">
            <IconChervon />
          </button>
          <button onClick={handleShowAll} className={`ml-[10px] grid outline-none grid-cols-6 items-center justify-center text-center ${activeMonth === null ? "text-orange-600" : ""}`}>
            All
          </button>
          {displayedMonths.map((month) => (
            <button key={month} onClick={() => handleMonthClick(month)} className={`ml-[10px] outline-none grid grid-cols-6 items-center justify-center text-center ${month === activeMonth ? "text-orange-600" : ""}`}>
              {month}
            </button>
          ))}
          <button onClick={() => handleMonthChange("next")} className="transform -rotate-90 outline-none">
            <IconChervon />
          </button>
        </div>
        <div className="mt-4 overflow-x-auto h-[300px]">
          {filteredData.length > 0 ? (
            filteredData.map((data, index) => (
              <div key={index} className="flex justify-between mb-2">
                <div className="flex gap-[15px] text-left">
                  <div className="bg-[#DDDDDD] rotate-180 border border-[#E8E8E8] p-[12px] w-[36px] h-[38px] rounded-[9px]">
                    <IconChevronTop />
                  </div>
                  <div>
                    <p className="text-[#818284]  text-[14px]">********** 896</p>
                    <p className="text-[#4A4D4F]  text-[12px]">
                      {new Date(data.date).toLocaleDateString("id-ID", {
                        weekday: "long",
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })}
                    </p>
                  </div>
                </div>
                <p className="text-[#222831] font-medium ">Rp. {data.amount.toLocaleString()}</p>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-500">Data tidak tersedia</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailSaldo;
