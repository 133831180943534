import React from "react";
import "./style.css";
import { tseru } from "../../../../assets/icon";
import Dashbord from "../../../../assets/images/bgtentangkami.png";

const TentagKami = () => {
  return (
    <>
      <div id="tentang-kami"></div>
      <section className="border-[6px] border-[#E9EAEA] mt-28 mb-10 rounded-3xl ms-2 vector bg-[#1A1A1A] ">
        <div>
          <div
            className=" flex justify-center mt-7 me-80 -mb-6
        ">
            {tseru()}
          </div>
          <h1 className="text-[48px] font-bold text-white">
            Tentang <span className="bg-gradient-to-r from-[#EF2725]  via-[#EF2725] to-[#F26023] inline-block text-transparent bg-clip-text">Kami</span>
          </h1>

          <div className="text-base mt-4 text-white px-56">
            <p>
              Kami adalah penyedia solusi payment gateway terkemuka yang didedikasikan untuk membantu bisnis dari berbagai skala mengoptimalkan proses pembayaran mereka. Dengan teknologi canggih dan tim ahli yang berpengalaman, kami
              memastikan setiap transaksi berjalan lancar dan aman.
            </p>
          </div>

          <div className="flex justify-center mb-10 mt-8">
            <img src={Dashbord} alt="dashbord" />
          </div>
        </div>
      </section>
    </>
  );
};

export default TentagKami;
