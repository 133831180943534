export const IconChervon = () => (
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5917 0.841713C11.5142 0.763606 11.422 0.701611 11.3205 0.659304C11.2189 0.616997 11.11 0.595215 11 0.595215C10.89 0.595215 10.7811 0.616997 10.6795 0.659304C10.578 0.701611 10.4858 0.763606 10.4083 0.841713L6.59168 4.65838C6.51421 4.73649 6.42204 4.79848 6.32049 4.84079C6.21894 4.8831 6.11002 4.90488 6.00001 4.90488C5.89 4.90488 5.78108 4.8831 5.67953 4.84079C5.57798 4.79848 5.48581 4.73649 5.40834 4.65838L1.59168 0.841713C1.51421 0.763606 1.42204 0.701611 1.32049 0.659304C1.21894 0.616997 1.11002 0.595215 1.00001 0.595215C0.889999 0.595215 0.781078 0.616997 0.679529 0.659304C0.577979 0.701611 0.485812 0.763606 0.408343 0.841713C0.253134 0.997849 0.166016 1.20906 0.166016 1.42921C0.166016 1.64937 0.253134 1.86058 0.408343 2.01671L4.23334 5.84171C4.70209 6.30988 5.33751 6.57285 6.00001 6.57285C6.66251 6.57285 7.29792 6.30988 7.76668 5.84171L11.5917 2.01671C11.7469 1.86058 11.834 1.64937 11.834 1.42921C11.834 1.20906 11.7469 0.997849 11.5917 0.841713Z"
      fill="#1A1A1A"
    />
  </svg>
);

export const IconCalender = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.33333 8H4C3.26467 8 2.66667 8.598 2.66667 9.33333V10.6667C2.66667 11.402 3.26467 12 4 12H5.33333C6.06867 12 6.66667 11.402 6.66667 10.6667V9.33333C6.66667 8.598 6.06867 8 5.33333 8ZM4 10.6667V9.33333H5.33333V10.6667H4ZM12.6667 1.33333H12V0.666667C12 0.298667 11.702 0 11.3333 0C10.9647 0 10.6667 0.298667 10.6667 0.666667V1.33333H5.33333V0.666667C5.33333 0.298667 5.03533 0 4.66667 0C4.298 0 4 0.298667 4 0.666667V1.33333H3.33333C1.49533 1.33333 0 2.82867 0 4.66667V12.6667C0 14.5047 1.49533 16 3.33333 16H12.6667C14.5047 16 16 14.5047 16 12.6667V4.66667C16 2.82867 14.5047 1.33333 12.6667 1.33333ZM3.33333 2.66667H12.6667C13.7693 2.66667 14.6667 3.564 14.6667 4.66667V5.33333H1.33333V4.66667C1.33333 3.564 2.23067 2.66667 3.33333 2.66667ZM12.6667 14.6667H3.33333C2.23067 14.6667 1.33333 13.7693 1.33333 12.6667V6.66667H14.6667V12.6667C14.6667 13.7693 13.7693 14.6667 12.6667 14.6667Z"
      fill="#1A1A1A"
    />
  </svg>
);

export const IconExport = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.42 12.06L14.3467 13.1333C14.2133 13.2667 14.0467 13.3267 13.8733 13.3267C13.7 13.3267 13.5333 13.26 13.4 13.1333C13.14 12.8733 13.14 12.4533 13.4 12.1933L14.26 11.3333H9.32667C8.96 11.3333 8.66 11.0333 8.66 10.6667C8.66 10.3 8.96 10 9.32667 10H14.26L13.4 9.14C13.14 8.88 13.14 8.46 13.4 8.2C13.66 7.94 14.08 7.94 14.34 8.2L15.4133 9.27333C16.18 10.04 16.18 11.2933 15.4133 12.0667L15.42 12.06ZM8.66667 5.33333H13.0267C12.7933 4.72667 12.44 4.16667 11.9667 3.69333L9.64667 1.36667C9.17333 0.893333 8.61333 0.54 8.00667 0.306667V4.66667C8.00667 5.03333 8.30667 5.33333 8.67333 5.33333H8.66667ZM11.8733 12.6667H9.33333C8.23333 12.6667 7.33333 11.7667 7.33333 10.6667C7.33333 9.56667 8.23333 8.66667 9.33333 8.66667H11.8733C11.8733 8.15333 12.0667 7.64 12.46 7.25333C12.7067 7.00667 13.0067 6.83333 13.32 6.74667V6.66667H8.66667C7.56667 6.66667 6.66667 5.76667 6.66667 4.66667V0.0133333C6.56 0.00666667 6.45333 0 6.34 0H3.33333C1.49333 0 0 1.49333 0 3.33333V12.6667C0 14.5067 1.49333 16 3.33333 16H10C11.2067 16 12.2667 15.3533 12.8533 14.3867C12.7133 14.3 12.58 14.2 12.46 14.08C12.0733 13.6933 11.8733 13.18 11.8733 12.6667Z"
      fill="white"
    />
  </svg>
);
