import React from "react";
import {
  iconSosmed,
  ImageGoogleStore,
  ImagePlayStore,
  Logo,
} from "../../Assets/Images";

const Footer: React.FC = () => {
  return (
    <footer className="bg-[#F15A241A] bg-opacity-10 lg:pt-[50px] md:pt-[50px] rounded-[16px] pt-[25px] lg:p-3 px-[24px] lg:px-[40px]">
      <div className="mx-auto flex flex-col md:flex-row lg:flex-row gap-5   lg:gap-[60px] border-b pb-6">
        {/* Logo and Social Icons */}
        <div className="flex flex-col lg:pt-[20px]  items-start lg:gap-5  gap-5 md:gap-0 ">
          <div className="w-[136px] h-[60px] md:w-[130px] md:h-[100px] lg:w-[236px] lg:h-[102px]  ">
            <img
              src={Logo}
              alt="Logo"
              className="w-full h-full object-contain"
            />
          </div>

          <div className="flex gap-4 text-xl w-[100px] lg:w-[200px] lg:h-[35px] h-[16px]">
            <img
              src={ImageGoogleStore}
              alt=""
              className="w-full h-full object-contain"
            />
            <img
              src={ImagePlayStore}
              alt=""
              className="w-full h-full object-contain"
            />
          </div>
        </div>

        {/* Features and Contact Information on Mobile */}
        <div className="flex md:ml-[10px] lg:ml-[50px] md:pt-[24px] gap-[10px]  md:gap-0 md:w-[380px] lg:w-auto w-auto     ">
          {/* Features */}
          <div className="flex  flex-col gap-4 text-left w-[80px] md:w-[120px] lg:w-[160px]">
            <h3 className="text-[#252525] font-poppins text-[12px] lg:text-[24px] font-medium">
              Fitur
            </h3>
            <ul className="space-y-2 text-[#252525] text-[11px] w-[100px]  lg:w-full lg:text-[16px]  font-poppins">
              <li>Topup</li>
              <li>Transfer</li>
              <li>Tarik Tunai</li>
              <li>Bayar Tagihan</li>
            </ul>
          </div>

          {/* Contact Information */}
          <div className="flex   flex-col gap-4 text-left w-full md:w-[250px] lg:w-auto">
            {" "}
            {/* Ubah w-[300px] menjadi w-auto untuk fleksibilitas */}
            <h3 className="text-[#252525] text-[12px] lg:text-[24px] font-medium">
              Hubungi Kami
            </h3>
            <div className="text-[#252525] space-y-2 text-[11px] font-poppins">
              <div className="flex gap-7">
                <p className="font-medium w-[17px] md:w-auto text-[12px] lg:text-[16px]">
                  Email
                </p>
                <p>:</p>
                <p className="text-[12px] ml-[-20px]  lg:text-[16px]">
                  official@ultradigitalindonesia.co.id
                </p>
              </div>

              <div className="flex gap-6">
                <p className="font-medium text-[11px] w-[23px] lg:text-[16px] lg:w-[45px]">
                  Telepon
                </p>
                <p>:</p>
                <p className="text-[12px] ml-[-18px] lg:text-[16px] md:w-[150px] ">
                  +62 8177 0912 555
                </p>
              </div>
              <div className="flex">
                <p className="font-medium w-[60px] md:w-[66px] lg:w-[70px] text-[11px] lg:text-[16px]">
                  Address
                </p>
                <p>:</p>
                <p className="text-[11px] ml-[5px] md:ml-[5px] lg:text-[16px] lg:w-[400px] w-full md:w-[200px] ">
                  Jl. Komp. BSD No : A3/28, Desa/Kelurahan Lengkong Wetan, Kec.
                  Serpong, Kota Tanggerang Selatan, Provinsi Banten - 15310
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Help Section and Follow Us on Mobile */}
        <div className="flex  md:ml-[10px] md:pt-[24px] lg:ml-[-30px] flex-row gap-[45px] md:gap-10 lg:gap-[60px] w-full lg:w-auto">
          {/* Help Section */}
          <div className="flex flex-col gap-4 text-left font-poppins ">
            <h3 className="text-[#252525] text-[12px] lg:text-[24px] font-medium">
              Bantuan
            </h3>
            <div className="text-[#252525] text-[11px] lg:text-[16px] lg:text-base font-poppins">
              Bantuan
            </div>
          </div>

          {/* Follow Us */}
          <div className="flex md:ml-[0px] flex-col items-start gap-4 text-left lg:w-1/2">
            <h3 className="text-[#252525] text-[12px] lg:text-[24px] font-poppins font-medium">
              Ikuti Kami
            </h3>
            <div className="flex gap-4 text-xl">
              <img
                src={iconSosmed}
                alt=""
                className="w-full h-full object-fill"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="text-center my-6 text-black text-sm font-poppins">
        <p>© 2024 PT Ultra Digital Indonesia | All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
