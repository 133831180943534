import React, { useState, useEffect } from "react";
import AccsesKeyPerusahaan from "./accseskey-perusahaan";
import AccsesKeyIndividu from "./accseskey-individu";
import axios from "axios";

const Profil: React.FC = () => {
  const [legalitas, setLegalitas] = useState<string | null>(null);
  const token = localStorage.getItem("token");
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}get-profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLegalitas(response.data.users.legalitas);

        // localStorage.setItem("username", response.data.username);
        // localStorage.setItem("profile_pict", response.data.profile_pict);
        // console.log(response.data.users.legalitas);
      })
      .catch((error) => {
        console.error("Gagal mengambil data pengguna:", error);
        // if (error.response?.status === 401) {
        //   localStorage.removeItem("token");
        // }
      });
  });

  return <section>{legalitas === "perusahaan" ? <AccsesKeyPerusahaan /> : legalitas === "individu" ? <AccsesKeyIndividu /> : <p className="text-white">.</p>}</section>;
};

export default Profil;
