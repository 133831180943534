import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../features/stores";
import { setDateRange, setStatus, setTipePembayaran } from "../../../../../../features/reducers/filterTransaksi";
import { IconCalender, IconChervon, IconExport } from "../Icon/icons";
import CalendarDate from "../../../../../../components/calender";
import ExportData from "../../../../../../components/exportData";
import { formatISO } from "date-fns";
interface FilterDataProps {
  setCurrentPage: (page: number) => void;
}

const FilterData: React.FC<FilterDataProps> = ({ setCurrentPage }) => {
  const dispatch = useDispatch();
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);

  const { data } = useSelector((state: RootState) => state.transactionHistory);
  const { selectedTipePembayaran, selectedStatus, startDate, endDate } = useSelector((state: RootState) => state.filterTransaksi);

  const tipePembayaranOptions = Array.from(new Set(data?.map((item) => item.tipe_pembayaran)));
  const statusOptions = Array.from(new Set(data?.map((item) => item.status)));

  const handleDropdownToggle = (dropdownName: string) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const handleTipePembayaranChange = (tipe: string) => {
    dispatch(setTipePembayaran(selectedTipePembayaran.includes(tipe) ? selectedTipePembayaran.filter((item) => item !== tipe) : [...selectedTipePembayaran, tipe]));
  };

  const handleStatusChange = (status: string) => {
    dispatch(setStatus(selectedStatus.includes(status) ? selectedStatus.filter((item) => item !== status) : [...selectedStatus, status]));
  };

  const handleDateRangeSelect = (start: Date | string | undefined, end: Date | string | undefined) => {
    const startDate = typeof start === "string" ? new Date(start) : start;
    const endDate = typeof end === "string" ? new Date(end) : end;

    // console.log("Selected start date:", start);
    // console.log("Selected end date:", end);

    // Convert the date to ISO format
    const formattedStartDate = startDate ? formatISO(startDate) : undefined;
    const formattedEndDate = endDate ? formatISO(endDate) : undefined;

    dispatch(
      setDateRange({
        start: formattedStartDate,
        end: formattedEndDate || formattedStartDate,
      })
    );
    setCurrentPage(1);
  };

  const formatDate = (start: Date, end?: Date) => {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "long",
      year: "numeric",
    };
    const startDateStr = start.toLocaleDateString("id-ID", options);
    if (!end || start.getTime() === end.getTime()) return startDateStr;
    const endDateStr = end.toLocaleDateString("id-ID", options);
    if (start.getMonth() === end.getMonth() && start.getFullYear() === end.getFullYear()) {
      return `${start.getDate()}-${endDateStr}`;
    }
    return `${startDateStr} - ${endDateStr}`;
  };

  return (
    <div className="flex justify-between mx-auto w-full p-[15px]">
      <div className="flex gap-[20px]">
        {/* Tipe Pembayaran Dropdown */}
        <div className="relative">
          <div className="flex border border-slate-100 shadow-sm rounded-lg gap-[10px] px-[15px] py-[10px] cursor-pointer" onClick={() => handleDropdownToggle("payment")}>
            <p className="text-[14px] font-inter font-semibold text-[#1A1A1A]">Tipe Pembayaran</p>
            <div className="py-[6px]">
              <IconChervon />
            </div>
          </div>
          {openDropdown === "payment" && (
            <div className="absolute flex flex-col gap-[8px] capitalize text-[14px] font-inter mt-[10px] lg:w-[170px] text-left bg-white border rounded-lg shadow-lg p-2">
              {tipePembayaranOptions.map((tipe) => (
                <div key={tipe} className="flex">
                  <label>
                    <input type="checkbox" checked={selectedTipePembayaran.includes(tipe)} onChange={() => handleTipePembayaranChange(tipe)} className="mr-[10px] outline-none w-[16px] h-[16px]" />
                  </label>
                  <p className="mt-[-2px]">{tipe}</p>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Status Dropdown */}
        <div className="relative">
          <div className="flex border border-slate-100 shadow-sm rounded-lg gap-[10px] px-[15px] py-[10px] cursor-pointer" onClick={() => handleDropdownToggle("status")}>
            <p className="text-[14px] font-inter font-semibold text-[#1A1A1A]">Status</p>
            <div className="py-[6px]">
              <IconChervon />
            </div>
          </div>
          {openDropdown === "status" && (
            <div className="absolute flex flex-col gap-[8px] text-[14px] font-inter text-left w-[130px] mt-[10px] bg-white border rounded-lg shadow-lg p-2">
              {statusOptions.map((status) => (
                <div key={status} className="w-auto flex  h-auto  text-[14px] font-inter font-medium capitalize">
                  <label>
                    <input type="checkbox" checked={selectedStatus.includes(status)} onChange={() => handleStatusChange(status)} className="mr-[10px] outline-none text-[#8F90A6] w-[16px] h-[16px]" />
                  </label>
                  <p className="mt-[-2px]">{status}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="flex gap-[20px]">
        <div className="flex border border-slate-100 shadow-sm rounded-lg gap-[10px] px-[15px] py-[10px] cursor-pointer" onClick={() => handleDropdownToggle("tanggal")}>
          <p className="text-[14px] font-inter font-semibold text-[#1A1A1A]">{startDate && endDate ? formatDate(new Date(startDate), new Date(endDate)) : "Tanggal Transaksi"}</p>
          <div className="py-[3px]">
            <IconCalender />
          </div>
        </div>
        {openDropdown === "tanggal" && (
          <div className="absolute mt-[53px]">
            <CalendarDate
              onDateRangeSelect={(start, end) => {
                handleDateRangeSelect(start, end);
              }}
              onClose={() => setOpenDropdown(null)}
            />
          </div>
        )}
        <div onClick={() => handleDropdownToggle("export")} className="flex border border-slate-100 shadow-sm rounded-lg gap-[10px] px-[15px] py-[10px] cursor-pointer bg-gradient-to-r from-[#EF2725] to-[#F26023] ">
          <div className="py-[3px]">
            <IconExport />
          </div>
          <p className="text-[14px] text-white font-inter font-semibold ">Export</p>
        </div>
        {openDropdown === "export" && (
          <div className="absolute right-[46px] mt-[53px]">
            <ExportData onClose={() => setOpenDropdown(null)} />
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterData;
