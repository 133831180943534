// src/components/Pagination.tsx

import React from "react";
import { IconChervon } from "../Icon/icons";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  // Generate the range of page numbers to display
  const pageRange = () => {
    const range: number[] = [];
    const start = Math.max(1, currentPage - 3);
    const end = Math.min(totalPages, currentPage + 3);

    for (let i = start; i <= end; i++) {
      range.push(i);
    }

    return range;
  };

  return (
    <div>
      {totalPages > 1 && (
        <div className="flex gap-[9px] justify-end mt-4">
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-4 py-2 rotate-90"
          >
            <IconChervon />
          </button>
          {pageRange().map((page) => (
            <button
              key={page}
              onClick={() => onPageChange(page)}
              className={`px-4 py-2 text-[14px] font-inter ${
                page === currentPage
                  ? "bg-gradient-to-r from-[#EF2725] to-[#F26023] rounded-full text-white"
                  : "border border-[#EF2725] rounded-full text-gray-700"
              }`}
            >
              {page}
            </button>
          ))}
          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-4 py-2 transform -rotate-90"
          >
            <IconChervon />
          </button>
        </div>
      )}
    </div>
  );
};

export default Pagination;
