export const IconChevron = () => (
  <svg width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.5951 17.3875C8.71226 17.2713 8.80525 17.1331 8.86871 16.9807C8.93217 16.8284 8.96484 16.665 8.96484 16.5C8.96484 16.335 8.93217 16.1716 8.86871 16.0193C8.80525 15.867 8.71226 15.7287 8.5951 15.6125L2.8701 9.88751C2.75293 9.77131 2.65994 9.63306 2.59648 9.48073C2.53302 9.32841 2.50035 9.16503 2.50035 9.00001C2.50035 8.835 2.53302 8.67162 2.59648 8.51929C2.65994 8.36697 2.75293 8.22872 2.8701 8.11251L8.5951 2.38751C8.71226 2.27131 8.80525 2.13306 8.86871 1.98074C8.93217 1.82841 8.96484 1.66503 8.96484 1.50001C8.96484 1.335 8.93217 1.17162 8.86871 1.01929C8.80525 0.866969 8.71226 0.728718 8.5951 0.612514C8.36089 0.3797 8.04408 0.249023 7.71385 0.249023C7.38361 0.249023 7.0668 0.3797 6.8326 0.612514L1.0951 6.35001C0.392842 7.05314 -0.00160503 8.00626 -0.00160503 9.00001C-0.00160503 9.99377 0.392842 10.9469 1.0951 11.65L6.8326 17.3875C7.0668 17.6203 7.38361 17.751 7.71385 17.751C8.04408 17.751 8.36089 17.6203 8.5951 17.3875Z"
      fill="#1A1A1A"
    />
  </svg>
);

export const IconClose = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 31 31" fill="none">
    <path d="M31 16C31 24.2843 24.0604 31 15.5 31C6.93959 31 0 24.2843 0 16C0 7.71573 6.93959 1 15.5 1C24.0604 1 31 7.71573 31 16Z" fill="black" fill-opacity="0.43" />
    <path d="M12.3409 9.90909L15.4773 15.2614L18.6136 9.90909H20.9318L16.7045 16.4545L20.9318 23H18.6136L15.4773 17.9205L12.3409 23H10.0227L14.1818 16.4545L10.0227 9.90909H12.3409Z" fill="white" />
  </svg>
);
