import React, { useState, useEffect } from "react";
import axios, { AxiosResponse, AxiosError } from "axios";
import Bgregis from "../../assets/images/bg-regis.png";
import Google from "../../assets/images/google.png";
import { arrowauth, arrowdown, tsreuauth, eyeIcon, eyeSlashIcon } from "../../assets/icon";
import { Link, useNavigate } from "react-router-dom";
import Back from "../../assets/images/back.png";
import Succsesimg from "../../assets/images/succses.png";

type LegalitasType = "individu" | "perusahaan" | "Persuahaan" | "Individu";

interface RegisterResponse {
  message: string;
}

const Register: React.FC = () => {
  const [legalitas, setLegalitas] = useState<LegalitasType>("individu");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [username, setUsername] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [no_telp, setNo_telp] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);

  // New states for password visibility
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState<boolean>(false);

  const navigate = useNavigate();

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleLegalitasChange = (value: LegalitasType) => {
    setLegalitas(value);
    setIsDropdownOpen(false);
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    // Ganti semua angka di awal dengan "+62"
    if (/^\d/.test(value)) {
      value = "+62" + value.replace(/^\d+/, "");
    }

    // Pastikan hanya angka yang diizinkan
    value = value.replace(/[^0-9+]/g, "");

    setNo_telp(value);
  };

  useEffect(() => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[?=.*?[#?!@$%^&*-])[A-Za-z\d?=.*?[#?!@$%^&*-]{8,}$/;

    if (!passwordRegex.test(password)) {
      setPasswordError("");
      setIsFormValid(false);
    } else if (password !== confirmPassword) {
      setPasswordError("Password dan konfirmasi password tidak cocok.");
      setIsFormValid(false);
    } else if (!/^\+62[1-9]\d{8,11}$/.test(no_telp)) {
      // setErrorMessage("Nomor telepon harus memiliki 11 atau 12 angka.");
      setIsFormValid(false);
    } else if (!/^[a-zA-Z0-9._%]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/.test(email)) {
      setIsFormValid(false);
    } else {
      setPasswordError(null);
      setIsFormValid(username.trim() !== "" && email.trim() !== "" && no_telp.trim() !== "" && password.trim() !== "" && confirmPassword.trim() !== "");
    }
  }, [username, email, no_telp, password, confirmPassword]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage(null);
    setIsLoading(true);

    const formData = {
      legalitas,
      username,
      email,
      no_telp,
      password,
    };

    try {
      // console.log("Sending data:", formData);
      const response: AxiosResponse<RegisterResponse> = await axios.post(`${process.env.REACT_APP_API_URL}register-user`, formData);
      // console.log("Response:", response);

      if (response.status === 200) {
        setIsPopupVisible(true);
        // setTimeout(() => {
        //   setIsPopupVisible(false);
        //   navigate("/login"); // Arahkan ke halaman login setelah popup ditutup
        // }, 10000); // 10000 ms = 10 detik
        setUsername("");
        setEmail("");
        setNo_telp("");
        setPassword("");
        setConfirmPassword("");
        // navigate("/login");
      }
    } catch (error) {
      const axiosError = error as AxiosError<RegisterResponse>;
      setErrorMessage(axiosError.response?.data?.message || "Terjadi kesalahan. Silakan coba lagi.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className=" bg-[#1A1A1A] border-[5px] rounded-2xl m-4 px-8 py-14 font-inter">
      <div className="w-10 h-10 mb-10 -mt-7 cursor-pointer">
        <Link to="/">
          <img src={Back} alt="back" />
        </Link>
      </div>
      <div className="flex justify-center items-center gap-24">
        <div className="flex flex-col justify-center">
          <div className="flex justify-center">
            <img src={Bgregis} alt="Registrasi" />
          </div>
          <div className="flex justify-end -mt-32 mb-5">{arrowauth()}</div>
          <div className="mb-5 w-[590px] px-8 py-4 rounded-3xl bg-[#ffffff06]">
            <div className="text-[40px] text-white font-bold">
              <div className="ms-5 -mb-6">{tsreuauth()}</div>
              <div className="-mb-4"> Daftar Sekarang untuk</div>
              <div className="">
                <span className="bg-gradient-to-r from-[#EF2725]  via-[#EF2725] to-[#F26023] inline-block text-transparent bg-clip-text">Pembayaran</span> yang Lebih Baik!
              </div>
            </div>
            <div className="text-base text-white mb-2 mt-6">
              Daftar sekarang dan nikmati solusi pembayaran yang cepat, aman, dan mudah untuk bisnis Anda. Mulailah perjalanan Anda menuju pengelolaan keuangan yang lebih efisien bersama UdinPay.
            </div>
          </div>
        </div>
        <div className="w-[513px] bg-white rounded-3xl px-[50px] py-8 text-start ">
          <form className="flex flex-col gap-3  focus:outline-none outline-none " onSubmit={handleSubmit}>
            <label className="flex flex-col">
              <span className="text-gray-700 text-sm">Pilih Legalitas</span>
              <div
                className="cursor-pointer appearance-none bg-[#F9F9FA] mt-2 py-4 px-3 rounded-2xl placeholder-[#D9D9D9] text-sm flex justify-between items-center focus:outline-none focus:ring-2 focus:ring-[#EF2725] transition-all capitalize"
                onClick={toggleDropdown}>
                {legalitas}
                <span className={`ml-2 transition-transform ${isDropdownOpen ? "rotate-180" : "rotate-0"}`}>{arrowdown()}</span>
              </div>
              {isDropdownOpen && (
                <ul className=" mt-1 w-full bg-white  py-1 ">
                  <li className="text-sm py-3 px-3 hover:bg-[#E8EEF8] rounded cursor-pointer" onClick={() => handleLegalitasChange("individu")}>
                    Individu
                  </li>
                  <li className="text-sm py-3 px-3 hover:bg-[#E8EEF8] rounded cursor-pointer" onClick={() => handleLegalitasChange("perusahaan")}>
                    Perusahaan
                  </li>
                </ul>
              )}
            </label>
            <label className="flex flex-col">
              <span className="text-gray-700 text-sm">{legalitas === "perusahaan" ? "Nama Perusahaan" : "Nama Lengkap"}</span>
              <input
                type="text"
                className="bg-[#F9F9FA] mt-2 py-4 px-3 rounded-2xl placeholder-[#D9D9D9] placeholder:capitalize text-sm outline-none uppercase "
                placeholder={legalitas === "perusahaan" ? "Nama Perusahaan" : "Nama Lengkap"}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </label>
            <label className="flex flex-col">
              <span className="text-gray-700 text-sm">{legalitas === "perusahaan" ? "Email Perusahaan" : "Email"}</span>
              <input
                type="email"
                className="bg-[#F9F9FA] mt-2 py-4 px-3 rounded-2xl placeholder-[#D9D9D9] text-sm outline-none"
                placeholder={legalitas === "perusahaan" ? "Contoh: perusahaan@gmail.com" : "Contoh: ahmadjaidi@gmail.com"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>

            <label className="flex flex-col">
              <span className="text-gray-700 text-sm">{legalitas === "perusahaan" ? "Nomor Telepon Perusahaan" : "Nomor Telepon"}</span>
              <input
                maxLength={15}
                type="text"
                className="bg-[#F9F9FA] mt-2 py-4 px-3 rounded-2xl placeholder-[#D9D9D9] text-sm outline-none"
                placeholder={legalitas === "perusahaan" ? "Contoh: +6281338711518" : "Contoh: +6281338711518"}
                value={no_telp}
                onChange={handlePhoneChange}
              />
            </label>

            <label className="flex flex-col">
              <span className="text-gray-700 text-sm">Password</span>
              <div className="relative">
                <input
                  type={isPasswordVisible ? "text" : "password"}
                  className="bg-[#F9F9FA] mt-2 py-4 px-3 rounded-2xl placeholder-[#D9D9D9] text-sm w-full outline-none"
                  placeholder="Masukkan password Anda"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span className="absolute inset-y-0 right-0 pr-4 mt-2 flex items-center cursor-pointer" onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                  {isPasswordVisible ? eyeIcon() : eyeSlashIcon()}
                </span>
              </div>

              <span className="text-xs my-3 text-gray-500">Password memiliki minimal 8 karakter, termasuk huruf, angka, dan simbol.</span>
            </label>
            <label className="flex flex-col">
              <span className="text-gray-700 text-sm">Konfirmasi Password</span>
              <div className="relative">
                <input
                  type={isConfirmPasswordVisible ? "text" : "password"}
                  className="bg-[#F9F9FA] mt-2 py-4 px-3 rounded-2xl placeholder-[#D9D9D9] text-sm w-full outline-none"
                  placeholder="Ulangi password Anda"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <span className="absolute inset-y-0 right-0 pr-4 mt-2 flex items-center cursor-pointer" onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}>
                  {isConfirmPasswordVisible ? eyeIcon() : eyeSlashIcon()}
                </span>
              </div>
              {passwordError && <p className="text-red-500 text-xs mt-1">{passwordError}</p>}
            </label>
            {errorMessage && <p className="text-red-500 text-xs mt-1">{errorMessage}</p>}

            <button
              type="submit"
              className={`${isFormValid ? "bg-[#EF2725] hover:bg-red-600 cursor-pointer" : "bg-[#F3F4F8] text-[#AEAEAE] cursor-not-allowed"} mt-6 py-3 rounded-full text-white transition-all`}
              disabled={!isFormValid || isLoading}>
              {isLoading ? "Loading..." : "Daftar"}
            </button>
          </form>
          <p className="text-sm text-center mt-8">
            Sudah punya akun?{" "}
            <Link to="/login" className="text-[#EF2725] font-bold">
              Masuk
            </Link>
          </p>
          <div className="mt-6 flex items-center justify-center gap-2">
            <div className="h-[1px] bg-[#D9D9D9] w-full" />
            <div className="text-sm text-[#D9D9D9] font-bold">Atau</div>
            <div className="h-[1px] bg-[#D9D9D9] w-full" />
          </div>
          <div className="mt-6 flex justify-center gap-4 items-center">
            <button className="py-2 w-full rounded-full border border-[#DDE5E9]  mt-3  text-[#4A4D4F]">
              <Link className=" flex justify-center items-center gap-3" to="https://id-id.facebook.com/login.php/">
                <img src={Google} alt="" /> Masuk dengan Google
              </Link>
            </button>
          </div>
        </div>
        {isPopupVisible && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white px-20 py-10 rounded-2xl">
              <div className="flex justify-center text-center mb-5">
                <img src={Succsesimg} alt="berhasil" />
              </div>

              <p className="mb-4 text-orange-600 text-lg font-medium">Pendaftaran Anda berhasil</p>
              <button
                onClick={() => {
                  setIsPopupVisible(false);
                  navigate("/login"); // Arahkan ke halaman login setelah popup ditutup
                }}
                className="bg-white  text-orange-600 border border-orange-600  py-2 px-28 rounded-full ">
                Lanjut
              </button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Register;
