import React, { useState, useEffect } from "react";
import axios from "axios";
import { IconChevron } from "../icon";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

interface CompanyData {
  nama_perusahaan: string;
  no_akta_perusahaan: string;
  nomor_nib: string;
  no_npwp_perusahaan: string;
  file_akta_perusahaan: string;
  file_nib: string;
  file_npwp_perusahaan: string;
}

const GetPengajuan: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [companyData, setCompanyData] = useState<CompanyData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_API_URL}profile-perusahaan`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setCompanyData(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setError("Gagal mengambil data.");
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <p className="text-center text-orange-600">Loading...</p>;
  }

  if (error) {
    return <p className="text-center text-red-600">{error}</p>;
  }

  return (
    <>
      <Link to="/me/proyek">
        <div className="flex gap-2 items-center my-3 text-lg font-semibold">{IconChevron()} Kembali</div>
      </Link>

      <div className="p-10 bg-white border border-[#DDE5E9] rounded-2xl mb-8">
        <div className="bg-[#FEEFE9]  text-orange-600 px-4 py-3 rounded-lg mb-12 text-start text-sm">Verifikasi Sedang Diproses. Mohon tunggu, data Anda sedang dalam proses verifikasi. Ini mungkin memerlukan beberapa saat.</div>

        <div className="mt-5">
          <table className="w-full text-left table-fixed">
            <tbody className="flex flex-col gap-6">
              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831]  w-[30%]">Nama Perusahaan</td>
                <td>:</td>
                <td className="text-[#222831]"> {companyData?.nama_perusahaan}</td>
              </tr>
              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[30%] ">Nomor Akta Perusahaan</td>
                <td>:</td>
                <td className="text-[#222831] ">{companyData?.no_akta_perusahaan}</td>
              </tr>
              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[30%] ">Nomor NIB</td>
                <td>:</td>
                <td className="text-[#222831] ">{companyData?.nomor_nib}</td>
              </tr>
              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[30%] ">Nomor NPWP Perusahaan</td>
                <td>:</td>
                <td className="text-[#222831] ">{companyData?.no_npwp_perusahaan}</td>
              </tr>
              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[30%] ">File Akta Perusahaan</td>
                <td>:</td>
                <td className="text-[#222831]  hover:text-orange-600 truncate">
                  <a href={companyData?.file_akta_perusahaan} target="_blank" rel="noopener noreferrer" className="no-underline">
                    {companyData?.file_akta_perusahaan.split("/").pop()}
                  </a>
                </td>
              </tr>
              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[30%] ">File NIB</td>
                <td>:</td>
                <td className="text-[#222831] hover:text-orange-600 truncate">
                  <a href={companyData?.file_nib} target="_blank" rel="noopener noreferrer" className="no-underline">
                    {companyData?.file_nib.split("/").pop()}
                  </a>
                </td>
              </tr>
              <tr className="flex gap-6">
                <td className="font-semibold text-[#222831] w-[30%] ">File NPWP Perusahaan</td>
                <td>:</td>
                <td className="text-[#222831] hover:text-orange-600 truncate">
                  <a href={companyData?.file_npwp_perusahaan} target="_blank" rel="noopener noreferrer" className="no-underline">
                    {companyData?.file_npwp_perusahaan.split("/").pop()}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default GetPengajuan;
