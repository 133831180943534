import React from "react";
import NavbarPG from "../landingpagepayment/components/navbar";
import HeroSection from "./components/herosection";
import FiturUnggulan from "./components/fiturunggulan";
import TentangKami from "./components/tentangkami";
import CaraKerja from "./components/carakerja";
import Penggunaan from "./components/penggunaan";
import Ewalet from "./components/ewalet";
import Footer from "./components/foother";

const DashbordUtama = () => {
  return (
    <main className="font-signika max-w-[1500px] mx-auto flex flex-auto justify-center text-center">
      <NavbarPG />
      <div className="mx-10">
        <HeroSection />
        <FiturUnggulan />
        <TentangKami />
        <CaraKerja />
        <Penggunaan />
        <Ewalet />
        <Footer />
      </div>
    </main>
  );
};

export default DashbordUtama;
