import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { id } from "date-fns/locale";
import "./index.css";

interface CalendarDateProps {
  selectedStartDate?: Date;
  selectedEndDate?: Date;
  onDateRangeSelect: (start: Date | undefined, end: Date | undefined) => void;
  onClose: () => void;
}

const CalendarDate: React.FC<CalendarDateProps> = ({
  selectedStartDate,
  selectedEndDate,
  onDateRangeSelect,
  onClose,
}) => {
  const [startDate, setStartDate] = useState<Date | undefined>(
    selectedStartDate
  );
  const [endDate, setEndDate] = useState<Date | undefined>(selectedEndDate);
  const [showRange, setShowRange] = useState<boolean>(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);

  useEffect(() => {
    setStartDate(selectedStartDate);
    setEndDate(selectedEndDate);
  }, [selectedStartDate, selectedEndDate]);

  useEffect(() => {
    setIsSaveDisabled(!(startDate && (endDate || !endDate))); // Allow saving if startDate is set and either endDate is set or endDate is not needed
  }, [startDate, endDate]);

  const handleDateChange = (date: Date | null) => {
    if (date) {
      if (!startDate) {
        setStartDate(date);
        setEndDate(undefined); // Clear end date when starting a new range
      } else if (!endDate) {
        if (date.getTime() < startDate.getTime()) {
          setStartDate(date);
          setEndDate(undefined); // Reset end date if new start date is earlier
        } else {
          setEndDate(date);
        }
      } else {
        // If both dates are set, reset to only start date
        setStartDate(date);
        setEndDate(undefined);
      }
    }
  };

  const formatDateRange = () => {
    if (startDate && endDate) {
      const startDay = startDate.toLocaleDateString("id-ID", {
        day: "numeric",
      });
      const endDay = endDate.toLocaleDateString("id-ID", { day: "numeric" });
      const month = startDate.toLocaleDateString("id-ID", { month: "long" });
      const year = startDate.toLocaleDateString("id-ID", { year: "numeric" });

      return `${startDay}-${endDay} ${month} ${year}`;
    } else if (startDate) {
      return startDate.toLocaleDateString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    }
    return "";
  };

  const handleSave = () => {
    if (!isSaveDisabled) {
      onDateRangeSelect(startDate, endDate);
      setShowRange(true);
      onClose();
    }
  };

  const handleCancel = () => {
    setStartDate(undefined);
    setEndDate(undefined);
    onClose();
  };

  return (
    <div className="w-auto h-auto pb-6 bg-white rounded-xl border border-slate-100 shadow-sm flex-col justify-start items-start gap-3 inline-flex">
      {/* Date Picker */}
      <div className="px-5">
        <DatePicker
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          onChange={(date: Date | Date[] | null) => {
            if (!Array.isArray(date) && date) {
              handleDateChange(date);
            }
          }}
          inline
          dayClassName={(date) => {
            if (startDate && endDate) {
              const current = date.getTime();
              const start = startDate.getTime();
              const end = endDate.getTime();

              if (current === start || current === end) {
                return "selected";
              } else if (current > start && current < end) {
                return "in-range";
              }
            } else if (startDate && date.getTime() === startDate.getTime()) {
              return "selected";
            } else if (endDate && date.getTime() === endDate.getTime()) {
              return "selected";
            }
            return "";
          }}
          locale={id}
          className="calendar"
        />
        {showRange && (
          <div className="text-[#181819] text-sm font-medium font-['Plus Jakarta Sans'] leading-tight mt-4">
            {formatDateRange()}
          </div>
        )}
      </div>
      <div className="w-[280px] px-4 justify-center items-center gap-[10px] inline-flex">
        <button
          className="w-[132px] h-[42px] px-5 py-2 rounded-[20px] border border-[#f15a24] justify-center items-center gap-2 flex"
          onClick={handleCancel}
        >
          <div className="text-[#f15a24] text-xs font-medium font-['Roboto']">
            Batal
          </div>
        </button>
        <button
          className={`w-[131px] h-[42px] px-5 py-2 ${
            isSaveDisabled ? "bg-gray-400 cursor-not-allowed" : "bg-[#f15a24]"
          } rounded-[20px] justify-center items-center gap-2 flex`}
          onClick={handleSave}
          disabled={isSaveDisabled}
        >
          <div className="text-white text-sm font-medium font-['Plus Jakarta Sans'] leading-tight">
            Simpan
          </div>
        </button>
      </div>
    </div>
  );
};

export default CalendarDate;
