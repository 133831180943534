import React from "react";
import { arrowbotton1A, arrowbotton1B, arrowbotton1C, arrowbotton1AR, arrowbotton1BR, arrowbotton1CR, logo1, star } from "../../../../assets/icon";
import People2 from "../../../../assets/images/people2.png";
import "./style.css";

const Penggunaan = () => {
  return (
    <section id="payment-gateway" className="border-[7px] border-[#E9EAEA] mt-14 mb-10 rounded-3xl ms-2 vector bg-[#1A1A1A] ">
      <div className="flex justify-center gap-1">
        <div className="mt-3">{star()}</div>
        <div>
          <h1 className="text-5xl font-bold text-white mt-16">
            Penggunaan <span className="bg-gradient-to-r from-[#EF2725]  via-[#EF2725] to-[#F26023] inline-block text-transparent bg-clip-text">Payment</span> Gateway
          </h1>
          <div className="text-xl mt-4 text-white ">
            <p>Kami mempunyai bebberapa fitur unggulan di payment gateway</p>
          </div>
        </div>
      </div>

      <div className="flex justify-between mt-28">
        <div className="text-start w-[280px] ms-16 flex flex-col gap-40 mb-14 ">
          <div>
            <div className=" mb-3">{logo1()}</div>
            <div className="text-white font-bold text-[30px]">E-Commerce</div>
            <p className="text-white">Solusi ideal untuk toko online yang ingin menyediakan berbagai metode pembayaran yang aman dan cepat bagi pelanggan mereka.</p>
          </div>

          <div>
            <div className="absolute ms-40">{arrowbotton1BR()}</div>
            <div className=" mb-3">{logo1()}</div>
            <div className="text-white font-bold text-[30px]">Media Sosial</div>
            <p className="text-white">Payment gateway kami sangat ideal untuk platform social media yang memungkinkan transaksi antar pengguna, seperti penjualan produk atau layanan langsung dari profil pengguna..</p>
          </div>

          <div>
            <div className="ms-36 mb-3">{arrowbotton1CR()}</div>
            <div className=" mb-3">{logo1()}</div>
            <div className="text-white font-bold text-[30px]">Metode Pembayaran</div>
            <p className="text-white">Metode pembayaran yang beragam dan fleksibel adalah kunci untuk memberikan pengalaman belanja yang memuaskan kepada pelanggan.</p>
          </div>
        </div>

        {/* IMG */}
        <div className="mt-24 flex justify-start mb-40  ">
          <div className="">{arrowbotton1AR()}</div>
          <img className="-ms-32" src={People2} alt="" />
          <div className="-ms-28">{arrowbotton1A()}</div>
        </div>
        {/* IMG */}

        <div className="text-end  w-[280px] me-16 flex flex-col gap-40 mb-14 ">
          <div>
            <div className="  mb-3 ms-44">{logo1()}</div>
            <div className="text-white font-bold text-[30px]">Marketplace</div>
            <p className="text-white">Metode pembayaran yang beragam dan fleksibel adalah kunci untuk memberikan pengalaman belanja yang memuaskan kepada pelanggan.</p>
          </div>

          <div>
            <div className="absolute -ms-8">{arrowbotton1B()}</div>
            <div className="  mb-3 ms-44">{logo1()}</div>

            <div className="text-white font-bold text-[30px]">UMKM</div>

            <p className="text-white">UMKM dapat memanfaatkan payment gateway kami untuk menerima berbagai metode pembayaran, baik online maupun offline</p>
          </div>

          <div>
            <div className="me-1 mb-3">{arrowbotton1C()}</div>
            <div className="  mb-3 ms-44">{logo1()}</div>
            <div className="text-white font-bold text-[30px]">Retail</div>
            <p className="text-white">Solusi pembayaran yang efisien untuk toko ritel, baik online maupun offline, dengan dukungan multi-channel</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Penggunaan;
