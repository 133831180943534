import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import GantiRekeningPopup from "./pagerekening"; // Import the new component

interface PinPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const PinPopup: React.FC<PinPopupProps> = ({ isOpen, onClose }) => {
  const [pin, setPin] = useState<string[]>(Array(6).fill(""));
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showGantiRekening, setShowGantiRekening] = useState<boolean>(false); // State to control the Ganti Rekening popup
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (/^\d$/.test(value)) {
      const newPin = [...pin];
      newPin[index] = value;
      setPin(newPin);

      // Move to the next input field
      if (index < 5 && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace") {
      const newPin = [...pin];
      newPin[index] = "";
      setPin(newPin);

      // Move to the previous input field
      if (index > 0 && !pin[index] && inputRefs.current[index - 1]) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  useEffect(() => {
    if (pin.every((digit) => digit !== "")) {
      verifyPin();
    }
  });

  const verifyPin = () => {
    const pinString = pin.join("");
    const token = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}pin`,
        { pin: pinString },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === "success") {
          setShowGantiRekening(true); // Show the Ganti Rekening popup on success
        } else {
          setErrorMessage("PIN salah, silakan coba lagi.");
        }
      })
      .catch((error) => {
        setErrorMessage("PIN salah, silahkan coba lagi");
      });
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white rounded-3xl px-6 py-10 relative ms-64">
          <h2 className="text-[32px] font-semibold mb-4">Masukan PIN</h2>

          <div className="flex justify-center gap-4 my-9">
            {pin.map((digit, index) => (
              <input
                key={index}
                type="password"
                maxLength={1}
                value={digit}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                ref={(el) => (inputRefs.current[index] = el)}
                className={`w-5 h-5 text-center text-[#F15A24] text-[24px] font-bold rounded-full ${digit ? "bg-[#F15A24]" : "bg-[#e7e7e7]"} focus:outline-none`}
              />
            ))}
          </div>

          {errorMessage && <div className="text-red-500 text-center mb-4">{errorMessage}</div>}

          <div className="text-center text-sm text-gray-600 mb-4">
            Ingat PIN anda?{" "}
            <button className="text-[#f15a24] font-medium">
              <Link to="/me/pin">Lupa PIN</Link>
            </button>
          </div>

          <button className="w-[350px] px-5 py-2.5 bg-white border border-[#f15a24] text-[#f15a24] rounded-full font-medium" onClick={onClose}>
            Batal
          </button>
        </div>
      </div>

      <GantiRekeningPopup isOpen={showGantiRekening} onClose={() => setShowGantiRekening(false)} />
    </>
  );
};

export default PinPopup;
