import React from "react";
import Transaksi from "./transaksi/";
import Saldo from "./saldo";

const TransaksiProyek = () => {
  return (
    <div>
      <div className="mb-5">
        <Saldo />
        <Transaksi />
      </div>
    </div>
  );
};

export default TransaksiProyek;
