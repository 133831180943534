import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

import "../style.css";
import { IconChevron, IconClose } from "../icon";

interface PaymentChannel {
  _id: string;
  namaBank: string;
  biayaLayanan: number;
  logoBank: string;
}

interface PaymentData {
  dataAksesPembayaran: {
    _id: string;
    idPaymentChannel: PaymentChannel;
    isActive: boolean;
    status: string;
    description: string;
    idPaymentFee: string | null;
  }[];
  dataOpsiPayment: {
    _id: string;
    feeType: string;
  }[];
}

const Bank = () => {
  const { id } = useParams();
  const [paymentData, setPaymentData] = useState<PaymentData | null>(null);
  const [selectedFee, setSelectedFee] = useState<{ [key: string]: string | null }>({});
  const [isActive, setIsActive] = useState<{ [key: string]: boolean }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState<{ [key: string]: boolean }>({});
  const [showPopupError, setShowPopupError] = useState<{ [key: string]: boolean }>({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (id) {
      setIsLoading(true);

      axios
        .get(`${process.env.REACT_APP_API_URL}get-akses-pembayaran/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const paymentResponse = response.data.data;

          // Initialize selectedFee with data from API
          const initializedSelectedFee: { [key: string]: string | null } = {};
          paymentResponse.dataAksesPembayaran.forEach((payment: any) => {
            initializedSelectedFee[payment._id] = payment.idPaymentFee || null;
          });

          setPaymentData(paymentResponse);
          setIsActive((prev) => {
            const updatedIsActive = { ...prev };
            paymentResponse.dataAksesPembayaran.forEach((payment: any) => {
              if (!updatedIsActive.hasOwnProperty(payment._id)) {
                updatedIsActive[payment._id] = payment.isActive;
              }
            });
            return updatedIsActive;
          });
          setSelectedFee(initializedSelectedFee);
        })
        .catch((error) => {
          console.error("Error fetching payment data:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id, token]);

  const handleToggleChange = (id: string) => {
    const newStatus = !isActive[id];
    const updatedIsActive = { ...isActive, [id]: newStatus };

    // Simpan status terbaru ke localStorage
    localStorage.setItem("isActive", JSON.stringify(updatedIsActive));

    setIsActive(updatedIsActive);
    updateStatus(id, newStatus, selectedFee[id]);
  };

  const handleFeeChange = (id: string, feeId: string) => {
    const updatedSelectedFee = { ...selectedFee, [id]: feeId };

    // Simpan fee terbaru ke localStorage
    localStorage.setItem("selectedFee", JSON.stringify(updatedSelectedFee));

    setSelectedFee(updatedSelectedFee);
    updateStatus(id, isActive[id], feeId);
  };

  useEffect(() => {
    // Ambil state dari localStorage jika ada
    const savedIsActive = localStorage.getItem("isActive");
    const savedSelectedFee = localStorage.getItem("selectedFee");

    if (savedIsActive) {
      setIsActive(JSON.parse(savedIsActive));
    }
    if (savedSelectedFee) {
      setSelectedFee(JSON.parse(savedSelectedFee));
    }
  }, []);

  const updateStatus = (id: string, status: boolean, feeId: string | null) => {
    setIsLoading(true);

    axios
      .put(
        `${process.env.REACT_APP_API_URL}update-akses-pembayaran/${id}`,
        {
          isActive: status,
          idPaymentFee: feeId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === "success") {
          setShowPopup((prev) => ({ ...prev, [id]: true }));
          setTimeout(() => {
            setShowPopup((prev) => ({ ...prev, [id]: false }));
          }, 2000);
        }
      })
      .catch((error) => {
        console.error("Error updating payment status:", error);
        setShowPopupError((prev) => ({ ...prev, [id]: true }));
        setTimeout(() => {
          setShowPopupError((prev) => ({ ...prev, [id]: false }));
        }, 2000);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (!paymentData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Link to="/me/proyek">
        <div className="text-start mb-8 flex gap-2 items-center text-xl font-medium">{IconChevron()} Kembali</div>
      </Link>
      <div className="flex flex-wrap gap-8 mb-5">
        {paymentData.dataAksesPembayaran.map((payment, index) => (
          <div key={payment._id} className={`relative flex flex-col gap-4 border border-gray-300 p-5 rounded-xl w-[280px] content-start ${index === 0 ? "" : ""}`}>
            <div className={`relative flex flex-col gap-4 ${index === 0 && payment.status !== "Disetujui" ? "blur-sm cursor-not-allowed pointer-events-none" : ""}`}>
              {" "}
              {/* Apply blur only to content except the button */}
              <div className="flex justify-between items-center">
                <img src={payment.idPaymentChannel?.logoBank} alt={payment.idPaymentChannel?.namaBank} className="h-10 w-auto" />
                <label className="relative inline-flex items-center cursor-pointer">
                  <input type="checkbox" checked={isActive[payment._id] || false} onChange={() => handleToggleChange(payment._id)} className="sr-only peer " />
                  <div className={`w-11 h-6 rounded-full ${isActive[payment._id] ? "bg-orange-600" : "bg-gray-300"} peer-focus:outline-none peer-focus:ring-0`}></div>
                  <div className="w-5 h-5 bg-white rounded-full absolute left-[2px] bottom-[2px] peer-checked:translate-x-full transition-transform"></div>
                </label>
              </div>
              <div className="text-start text-sm">
                Biaya Layanan{" "}
                <span className="font-semibold">
                  {payment.idPaymentChannel?.biayaLayanan}
                  {payment.idPaymentChannel?.namaBank === "QRIS" && " " && "%"}
                </span>{" "}
                Akan dibebankan ke:
              </div>
              <div className="flex gap-3">
                {payment.idPaymentChannel?.namaBank === "QRIS" ? (
                  // Jika bank adalah QRIS, hanya render merchant label
                  <div className="flex items-center gap-2">
                    <span>Merchant</span>
                  </div>
                ) : (
                  // Jika bukan QRIS, lakukan mapping opsi pembayaran
                  paymentData.dataOpsiPayment.map((feeOption) => {
                    const isSelected = selectedFee[payment._id] === feeOption._id; // Mengambil state dari selectedFee
                    const hasValidFeeOption = isSelected !== null; // Pengecekan apakah feeType dan selectedFee bukan null

                    return (
                      <label key={feeOption._id} className="flex items-center gap-2">
                        <input
                          type="radio"
                          value={feeOption._id}
                          checked={isSelected} // Tetap ter-check meski di-refresh
                          onChange={() => handleFeeChange(payment._id, feeOption._id)}
                          disabled={!isActive[payment._id] || isLoading}
                          className={`h-5 w-5 cursor-pointer 
            ${
              hasValidFeeOption
                ? "accent-orange-600 bg-orange-600 border-orange-600" // Jika feeType dan selectedFee valid (tidak null), tampilkan warna orange
                : "accent-transparent bg-gray-200 border-gray-300" // Jika feeType atau selectedFee null, tampilkan warna gray
            } 
            rounded-full border-2`} // Menambahkan style yang mirip dengan contoh gambar
                        />
                        {feeOption.feeType}
                      </label>
                    );
                  })
                )}
              </div>
              {/* Popup kecil yang muncul selama 2 detik */}
              {showPopup[payment._id] && <div className="absolute top-[50px] ms-10 bg-[#000000cb] text-white border border-black s p-3 rounded-lg shadow-md">Berhasil diperbarui!</div>}
              {showPopupError[payment._id] && <div className="absolute top-[50px] ms-10 bg-[#ed000083] text-white border border-black s p-3 rounded-lg shadow-md">Gagal Memperbarui</div>}
            </div>

            {index === 0 || payment.idPaymentChannel?.namaBank === "BCA" ? (
              payment.status === "Belum disetujui" || payment.status === "Diproses" ? (
                <>
                  <div className="bg-[#FDE6DE] py-2 px-3 text-[#F15A24] border border-[#F15A24] rounded-lg absolute right-4 top-5 cursor-not-allowed ms-5">
                    <p>Dokumen Anda Sedang Dalam Proses Tinjauan Oleh Tim Kami</p>
                  </div>
                  <button className="bg-orange-600 py-2 px-8 text-white rounded-full absolute bottom-4 ms-10">
                    <Link to="/me/proyek/pembayaran/detail">Cek Sekarang</Link>
                  </button>
                </>
              ) : payment.status === "Ditolak" ? (
                <>
                  <div className="bg-[#FDE6DE] py-2 px-3 text-[#F15A24] border border-[#F15A24] rounded-lg absolute top-14 ">
                    <p className="font-semibold">Data Anda Ditolak</p>

                    <div className="relative w-[218px] cursor-pointer" onClick={() => setIsPopupOpen(true)}>
                      <p className="truncate" style={{ overflow: "hidden", WebkitLineClamp: 3, WebkitBoxOrient: "vertical" }}>
                        {payment.description}
                        <span>...</span>
                      </p>
                    </div>

                    {/* Full description popup */}
                    {isPopupOpen && (
                      <div className="absolute -top-[220px] -right-[850px] w-[1500px] h-screen bg-black bg-opacity-50  flex justify-center items-center z-20">
                        <div className="bg-[#FDE6DE] px-9 py-6 rounded-xl ms-48 max-w-[400px] border border-orange-600">
                          <div onClick={() => setIsPopupOpen(false)} className="flex justify-end text-white -mt-2 -me-4 text-xl cursor-pointer ">
                            {IconClose()}
                          </div>
                          <p className="text-[#F15A24] font-semibold">Alasan Penolakan</p>
                          <p className="mt-3 mb-5">{payment.description}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  {payment.status !== "Disetujui" && (
                    <button className="bg-orange-600 px-4 py-2  text-white rounded-full absolute bottom-4 right-12  ">
                      <Link to={`${payment._id}`}>Ajukan Penggunaan</Link>
                    </button>
                  )}
                </>
              )
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Bank;
