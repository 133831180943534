import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../../../services/axiosInstance";
import { UserResponse } from "../interface/profile.interface"; // Adjust the path as necessary

interface ProfileState {
  user: UserResponse["users"] | null; // User data or null if not yet fetched
  status: "idle" | "loading" | "succeeded" | "failed";
  loading: boolean;
  error: string | null;
}

const initialState: ProfileState = {
  user: null,
  status: "idle",
  loading: false,
  error: null,
};

export const fetchUserProfile = createAsyncThunk<UserResponse, void>("profile/fetchUserProfile", async () => {
  try {
    const response = await axiosInstance.get(`/get-profile`);
    return response.data; // Ensure this matches the `UserResponse` structure
  } catch (error) {
    console.error("Error fetching profile:", error);
    throw error;
  }
});

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state) => {
        state.loading = true;
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action: PayloadAction<UserResponse>) => {
        state.loading = false;
        state.status = "succeeded";
        state.user = action.payload.users; // Set user data from response
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch user profile";
        console.error("Error fetching user profile:", action.error.message);
      });
  },
});

export default profileSlice.reducer;
