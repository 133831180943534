import React from "react";
import { Doughnut } from "react-chartjs-2";
import TransactionUdin from "./TransaksiUdin";

const DataValue = {
  title: "Transaksi Kartu",
  value: 0,
  judul: "Kartu",
  percentase: [
    {
      color: "#1A1A1A",
      title: "EDC",
      value: 100,
    },
    {
      color: "#F15A24",
      title: " Electronic",
      value: 100,
    },
  ],
};

const CardTransaction: React.FC = () => {
  const getData = (value: number) => {
    return {
      datasets: [
        {
          data: [value, 100 - value],
          backgroundColor: ["#F15A24", "#D9D9D9"],
          borderWidth: 0,
        },
      ],
    };
  };

  return (
    <div className=" grid grid-cols-2 gap-[20px] w-full">
      <div className=" items-center text-start rounded-2xl shadow-[2px_2px_20px_10px_rgba(0,0,0,0.04)] border border-[#DDE5E9] px-6 py-5">
        <div>
          <h3 className="text-xl ">{DataValue.title}</h3>
        </div>
        <div className="flex justify-center w-full lg:mt-[-15px]">
          <div className="relative h-[160px] w-[160px] ">
            <Doughnut
              data={getData(DataValue.value)}
              options={{
                cutout: "70%",
                plugins: {
                  tooltip: { enabled: false },
                },
              }}
            />
            <span className="text-sm font-bold text-[#F15A24] absolute inset-0 lg:mt-[12px] flex items-center justify-center">{DataValue.value}%</span>
          </div>
        </div>

        <div>
          <h1 className="text-xl font-bold flex justify-center w-full lg:mt-[30px]">{DataValue.judul}</h1>
          {/* disini jika data nya satu maka letakan di tengah */}
          <div
            className={`grid grid-cols-2
               gap-[10px] mt-[15px]`}>
            {DataValue.percentase.map((persen, index) => (
              <div key={index}>
                <div className="flex gap-[10px]">
                  <p className={`w-[30px] h-[24px] bg-[${persen.color}] rounded-full`} />
                  <h1 className="text-[16px] text-[#555770] font-inter flex  w-full">
                    {persen.title} ( {DataValue.value}% )
                  </h1>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div>
        <TransactionUdin />
      </div>
    </div>
  );
};

export default CardTransaction;
