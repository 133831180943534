export const IconSukses = () => (
  <svg
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" width="60" height="60" rx="30" fill="#FDE6DE" />
    <path
      d="M30.5 5C16.7146 5 5.5 16.2146 5.5 30C5.5 43.7854 16.7146 55 30.5 55C44.2854 55 55.5 43.7854 55.5 30C55.5 16.2146 44.2854 5 30.5 5ZM30.3104 37.1229C29.5042 37.9292 28.4437 38.3312 27.3792 38.3312C26.3146 38.3312 25.2437 37.925 24.4292 37.1125L18.6333 31.4958L21.5354 28.5021L27.3542 34.1417L39.4562 22.2646L42.3812 25.2333L30.3104 37.1229Z"
      fill="url(#paint0_linear_1495_37789)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1495_37789"
        x1="-9.34963"
        y1="-12.0426"
        x2="54.1216"
        y2="55.6892"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EF2725" />
        <stop offset="1" stopColor="#F26023" />
      </linearGradient>
    </defs>
  </svg>
);

export const IconChevronTop = () => (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.82877 11L5.82877 2.66666L8.57043 5.40832C8.6479 5.48643 8.74007 5.54843 8.84162 5.59073C8.94317 5.63304 9.05209 5.65482 9.1621 5.65482C9.27211 5.65482 9.38103 5.63304 9.48258 5.59073C9.58413 5.54843 9.6763 5.48643 9.75377 5.40832C9.90898 5.25219 9.99609 5.04098 9.99609 4.82082C9.99609 4.60067 9.90898 4.38946 9.75377 4.23332L6.17043 0.658323C5.85931 0.345333 5.43675 0.168517 4.99543 0.166656C4.55701 0.170712 4.13785 0.34736 3.82877 0.658323L0.245432 4.23332C0.168281 4.31102 0.107189 4.40316 0.0656443 4.50447C0.0240993 4.60577 0.00291538 4.71427 0.00330257 4.82377C0.00368881 4.93327 0.0256395 5.04161 0.0678997 5.14263C0.110159 5.24364 0.171901 5.33534 0.249599 5.41249C0.327298 5.48964 0.419434 5.55073 0.520742 5.59228C0.622051 5.63382 0.730551 5.65501 0.840047 5.65462C0.949542 5.65423 1.05789 5.63228 1.1589 5.59002C1.25991 5.54776 1.35161 5.48602 1.42877 5.40832L4.1621 2.66666L4.1621 11C4.1621 11.221 4.2499 11.433 4.40618 11.5892C4.56246 11.7455 4.77442 11.8333 4.99543 11.8333C5.21645 11.8333 5.42841 11.7455 5.58469 11.5892C5.74097 11.433 5.82877 11.221 5.82877 11Z"
      fill="#1A1A1A"
    />
  </svg>
);

export const IconExportRed = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.42 12.06L14.3467 13.1333C14.2133 13.2667 14.0467 13.3267 13.8733 13.3267C13.7 13.3267 13.5333 13.26 13.4 13.1333C13.14 12.8733 13.14 12.4533 13.4 12.1933L14.26 11.3333H9.32667C8.96 11.3333 8.66 11.0333 8.66 10.6667C8.66 10.3 8.96 10 9.32667 10H14.26L13.4 9.14C13.14 8.88 13.14 8.46 13.4 8.2C13.66 7.94 14.08 7.94 14.34 8.2L15.4133 9.27333C16.18 10.04 16.18 11.2933 15.4133 12.0667L15.42 12.06ZM8.66667 5.33333H13.0267C12.7933 4.72667 12.44 4.16667 11.9667 3.69333L9.64667 1.36667C9.17333 0.893333 8.61333 0.54 8.00667 0.306667V4.66667C8.00667 5.03333 8.30667 5.33333 8.67333 5.33333H8.66667ZM11.8733 12.6667H9.33333C8.23333 12.6667 7.33333 11.7667 7.33333 10.6667C7.33333 9.56667 8.23333 8.66667 9.33333 8.66667H11.8733C11.8733 8.15333 12.0667 7.64 12.46 7.25333C12.7067 7.00667 13.0067 6.83333 13.32 6.74667V6.66667H8.66667C7.56667 6.66667 6.66667 5.76667 6.66667 4.66667V0.0133333C6.56 0.00666667 6.45333 0 6.34 0H3.33333C1.49333 0 0 1.49333 0 3.33333V12.6667C0 14.5067 1.49333 16 3.33333 16H10C11.2067 16 12.2667 15.3533 12.8533 14.3867C12.7133 14.3 12.58 14.2 12.46 14.08C12.0733 13.6933 11.8733 13.18 11.8733 12.6667Z"
      fill="red"
    />
  </svg>
);

export const IconChervonColor = (fill: string) => (
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5917 0.841713C11.5142 0.763606 11.422 0.701611 11.3205 0.659304C11.2189 0.616997 11.11 0.595215 11 0.595215C10.89 0.595215 10.7811 0.616997 10.6795 0.659304C10.578 0.701611 10.4858 0.763606 10.4083 0.841713L6.59168 4.65838C6.51421 4.73649 6.42204 4.79848 6.32049 4.84079C6.21894 4.8831 6.11002 4.90488 6.00001 4.90488C5.89 4.90488 5.78108 4.8831 5.67953 4.84079C5.57798 4.79848 5.48581 4.73649 5.40834 4.65838L1.59168 0.841713C1.51421 0.763606 1.42204 0.701611 1.32049 0.659304C1.21894 0.616997 1.11002 0.595215 1.00001 0.595215C0.889999 0.595215 0.781078 0.616997 0.679529 0.659304C0.577979 0.701611 0.485812 0.763606 0.408343 0.841713C0.253134 0.997849 0.166016 1.20906 0.166016 1.42921C0.166016 1.64937 0.253134 1.86058 0.408343 2.01671L4.23334 5.84171C4.70209 6.30988 5.33751 6.57285 6.00001 6.57285C6.66251 6.57285 7.29792 6.30988 7.76668 5.84171L11.5917 2.01671C11.7469 1.86058 11.834 1.64937 11.834 1.42921C11.834 1.20906 11.7469 0.997849 11.5917 0.841713Z"
      fill={fill}
    />
  </svg>
);
