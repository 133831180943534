export const LogiUdin = require("./logoUdin.png");
export const BgheroUdin = require("./bgHeroUdin.png");
export const ImagePhone = require("./ImagePhone.png");
export const IconPlus = require("./iconPlus.png");
export const IconAlert = require("./IconAlert.png");
export const IconDompet = require("./IconDompett.png");
export const IconPesawat = require("./icon2.png");
export const ImagePhone2 = require("./Frame 427322740.png");
export const ImagePhone3 = require("./imagePhone3.png");
export const ImagePhone4 = require("./ImagePhone4.png");
export const IconPoin = require("./IconPoin.png");
export const ImageGoogleStore = require("./GooglePlay.png");
export const ImagePlayStore = require("./AppStore.png");
export const Kominfo = require("./KOMINFO.png");
export const Ojk = require("./OJK .png");
export const Shape = require("./shape.png");
export const ImageMap = require("./ImageMap.png");
export const Icon3 = require("./Icon3.png");
export const Pallet = require("./Pallet.png");
export const Pallet2 = require("./pallet1.png");
export const Pallet3 = require("./pallet5.png");
export const Pallet4 = require("./Pallet4.png");
export const frameUdin = require("./frameUdin.png");
export const BgChatMe = require("./bgChatMe.png");
export const FrameAbout = require("./FrameAbout.png");
export const ImageBanner = require("./imageBanner.png");
export const BgCamingson = require("./BgCamingSon.png");
export const ImageGadget = require("./ImageGadget.png");
export const Imagegatdet2 = require("./ImageGatdet2.png");
export const iconScam = require("./scan.png");
export const iconLock = require("./iconLock.png");
export const IconCheclis = require("./iconChecklis.png");
export const ImagePhone5 = require("./imagePhone2.png");
export const Image3 = require("./image3.png");
export const ImageGrop1 = require("./Group2.png");
export const LogoImage = require("./Frame 427322335.png");
export const iconSosmed = require("./IconSosmed.png");
export const Logo = require("./Logo.png");
