import React, { useState } from "react";
import { arrowrightberanda } from "../../assets/icon";
import { removeToken } from "../../../services/axiosInstance";
import { persistor } from "../../features/stores";
import { useNavigate } from "react-router-dom";
import "./style.css";

const Logout: React.FC = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    await persistor.purge();
    removeToken();
    // Clear all items from localStorage
    localStorage.clear();
    navigate("/login");
    // window.location.href = "/login";
  };

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  return (
    <section>
      <div className="flex flex-col mb-7 space-y-6 ">
        <div className="flex w-full ">
          <div className="border border-[#DDE5E9] rounded-2xl flex gap-14 justify-between w-full px-6 py-[17px]">
            <div className="w-[583px]">
              <h1 className="text-xl font-bold text-[#1A1A1A] text-start">Keluar dari Dashboard</h1>
              <p className="text-[#1A1A1A] text-sm text-start mt-3">Pastikan semua pekerjaan Anda telah disimpan. Jika ada perubahan yang belum disimpan, Anda mungkin perlu memulai kembali saat login kembali.</p>
            </div>
            <button onClick={togglePopup} className="flex gap-4 justify-center px-8 mt-10 rounded-full text-white items-center bg-[#F15A24] font-medium">
              Keluar {arrowrightberanda()}
            </button>
          </div>
        </div>
      </div>

      {isPopupVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white w-[490px] px-9 py-12 rounded-2xl shadow-lg border border-gray-300 ms-56">
            <h2 className="text-2xl font-semibold text-gray-800">Anda Yakin Ingin Keluar?</h2>
            <p className="text-lg text-gray-600 mt-2">Keluar dari akun Anda akan menghentikan sesi aktif Anda. Pastikan semua transaksi dan aktivitas telah selesai.</p>
            <div className="flex flex-col gap-4 mt-6">
              <button onClick={handleLogout} className="w-full bg-[#F15A24] text-white py-2 rounded-full text-center font-medium">
                Ya, Keluar
              </button>
              <button onClick={togglePopup} className="w-full border border-[#F15A24] text-[#F15A24] py-2 rounded-full text-center font-medium">
                Batal
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Logout;
