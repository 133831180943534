import React, { useState, useEffect } from "react";
import { pdficon } from "../../../assets/icon";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { IconChevron } from "../icon";
import { useParams } from "react-router-dom";

const DataPerusahaan: React.FC = () => {
  const { id } = useParams();
  const [no_akta_perusahaan, setno_akta_perusahaan] = useState("");
  const [file_akta, setfile_akta] = useState<string>(""); // URL for the akta file from the backend
  const [no_nib, setno_nib] = useState("");
  const [file_nib, setfile_nib] = useState<string>(""); // URL for the nib file from the backend
  const [no_npwp_perusahaan, setno_npwp_perusahaan] = useState("");
  const [file_npwp, setfile_npwp] = useState<string>(""); // URL for the npwp file from the backend

  const isFormValid = () => no_akta_perusahaan.length === 3 && no_nib.length === 13 && no_npwp_perusahaan.length === 16 && file_akta && file_nib && file_npwp;

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_API_URL}profile-perusahaan`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const data = response.data.data;
        setno_akta_perusahaan(data.no_akta_perusahaan || "");
        setno_nib(data.nomor_nib || "");
        setno_npwp_perusahaan(data.no_npwp_perusahaan || "");
        // Assuming file URLs are returned by the backend:
        setfile_akta(data.file_akta_perusahaan || "");
        setfile_nib(data.file_nib || "");
        setfile_npwp(data.file_npwp_perusahaan || "");
      })
      .catch((error) => {
        console.error("Error fetching company profile data:", error);
      });
  }, []);

  const handleVerify = (e: React.FormEvent) => {
    e.preventDefault();

    if (!isFormValid()) return;

    const formData = new FormData();
    formData.append("no_akta_perusahaan", no_akta_perusahaan);
    formData.append("no_nib", no_nib);
    formData.append("no_npwp_perusahaan", no_npwp_perusahaan);

    // Use URLs directly since we're not allowing file re-upload
    formData.append("file_akta", file_akta);
    formData.append("file_nib", file_nib);
    formData.append("file_npwp", file_npwp);

    const token = localStorage.getItem("token");

    axios
      .post(`${process.env.REACT_APP_API_URL}request-akses-pembayaran/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data) {
          // Assuming the response contains an array of "dataAksesPembayaran",
          // and we're taking the first project to navigate.

          // Navigate to the 'getPengajuan' page with the id_project
          navigate(`/me/proyek/pembayaran/${id}`);
        }
      })
      .catch((error) => {
        alert("gagal mengirim data");
        console.error("Gagal mengirim data:", error);
      });
  };

  const navigate = useNavigate();

  useEffect(() => {
    const isVerified = localStorage.getItem("is_verified");
    if (isVerified === "true") {
      navigate("");
    }
  }, [navigate]);

  return (
    <section>
      <div className="mb-10 p-[50px] bg-white border border-[#DDE5E9] rounded-2xl">
        <Link to="/me/proyek">
          <div className="w-[100px] flex gap-[20px] cursor-pointer">
            <IconChevron />
            <p className="text-[18px] mt-[-5px] font-semibold text-start mb-10">Kembali</p>
          </div>
        </Link>

        <h2 className="text-xl font-bold mb-4 text-start">Data</h2>

        {/* Nomor Akta Perusahaan */}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="w-full">
            <label className="block text-gray-700">Nomor Akta Perusahaan</label>
            <input type="number" className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9] cursor-not-allowed" value={no_akta_perusahaan} onChange={(e) => setno_akta_perusahaan(e.target.value.slice(0, 3))} readOnly />
          </div>

          <div className="w-full">
            <label className="block text-gray-700">File Akta Perusahaan</label>
            <div className="flex items-center justify-between bg-white p-[8px] border-[1.5px] border-dashed border-orange-600 rounded-2xl">
              <div className="flex gap-2 items-center">
                {pdficon()}
                <p className="text-gray-700 text-sm w-[200px] truncate">{file_akta.split("/").pop()}</p>
              </div>

              <a href={file_akta} target="_blank" rel="noopener noreferrer">
                <button className=" text-white  bg-orange-600 py-2 px-4 rounded-xl">Buka File</button>
              </a>
            </div>
          </div>
        </div>

        {/* Similar structure for NIB and NPWP */}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="w-full">
            <label className="block text-gray-700">Nomor NIB</label>
            <input type="number" className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9] cursor-not-allowed" value={no_nib} onChange={(e) => setno_nib(e.target.value.slice(0, 13))} readOnly />
          </div>

          <div className="w-full">
            <label className="block text-gray-700">File NIB</label>
            <div className="flex items-center justify-between bg-white p-[8px] border-[1.5px] border-dashed border-orange-600 rounded-2xl ">
              <div className="flex gap-2 items-center">
                {pdficon()}
                <p className="text-gray-700 text-sm w-[200px] truncate">{file_nib.split("/").pop()}</p>
              </div>

              <a href={file_nib} target="_blank" rel="noopener noreferrer">
                <button className=" text-white  bg-orange-600 py-2 px-4 rounded-xl">Buka File</button>
              </a>
            </div>
          </div>
        </div>

        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="w-full">
            <label className="block text-gray-700">Nomor NPWP Perusahaan</label>
            <input type="number" className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9] cursor-not-allowed " value={no_npwp_perusahaan} onChange={(e) => setno_npwp_perusahaan(e.target.value.slice(0, 16))} readOnly />
          </div>

          <div className="w-full">
            <label className="block text-gray-700">File NPWP Perusahaan</label>
            <div className="flex items-center justify-between bg-white p-[8px] border-[1.5px] border-dashed border-orange-600 rounded-2xl">
              <div className="flex gap-2 items-center">
                {pdficon()}
                <p className="text-gray-700 text-sm w-[200px] truncate">{file_npwp.split("/").pop()}</p>
              </div>
              <a href={file_npwp} target="_blank" rel="noopener noreferrer">
                <button className=" text-white  bg-orange-600 py-2 px-4 rounded-xl">Buka File</button>
              </a>
            </div>
          </div>
        </div>

        <button onClick={handleVerify} className={`flex justify-start mt-10 py-2 px-14 bg-orange-600 text-white rounded-full font-semibold ${!isFormValid() && "bg-slate-200 cursor-not-allowed"}`} disabled={!isFormValid()}>
          Lanjut Verifikasi
        </button>
      </div>
    </section>
  );
};

export default DataPerusahaan;
