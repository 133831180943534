// Spinner.tsx
const Spinner = () => {
  return (
    <div className="flex justify-center items-center min-h-screen text-center">
      <div className="w-8 h-8 border-t-4 border-b-4 border-orange-600 rounded-full animate-spin"></div>
      {/* <p className="ml-3 text-blue-500">Loading...</p> */}
    </div>
  );
};

export default Spinner;
