import React from "react";
import { koma, arrow1 } from "../../../../assets/icon";
import Heroimg from "../../../../assets/images/bgdasbord.png";
import { Link } from "react-router-dom";

const HeroSection = () => {
  //   const herosection = require("../../../../assets/images/maindashbord.png");
  return (
    <section id="beranda" className="mt-28 font-bold mb-4">
      <div>
        <div className="ms-[800px] -mb-8">{koma()}</div>
        <div className="text-[60px] text-start relative">
          <h1>
            Solusi Pembayaran <span className="bg-gradient-to-r from-[#EF2725]  via-[#EF2725] to-[#F26023] inline-block text-transparent bg-clip-text">Terintegrasi</span>
          </h1>
          <h1>
            untuk <span className="bg-gradient-to-r from-[#EF2725]  via-[#EF2725] to-[#F26023] inline-block text-transparent bg-clip-text">Bisnis</span> Anda
          </h1>
        </div>
      </div>

      <div className="flex justify-between  gap-4">
        <div>
          <img src={Heroimg} alt="" />
        </div>
        <div className="text-end ">
          <div className="-mt-20 mb-24 ms-20">{arrow1()}</div>
          <p className="font-normal mb-8 -mt-3 text-[22px] w-[550px]">Pengalaman transaksi yang lebih cepat, aman dan mudah. Dengan UDIN, proses pembayaran lebih terjamin</p>
          <Link to="login">
            <button className="bg-black text-base font-medium text-white px-10 py-3 rounded-full border-[3px] border-[#bbbbbb] hover:bg-gray-800"> Mulai Sekarang</button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
