import React, { useEffect } from "react";
import DonutChart from "./donatchart";
import { arrowrightberanda, userberanda, transaksi, arrowgraph, arrowgraphwhite } from "../../assets/icon";
import "./style.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../features/stores";
import { fetchTransactionData } from "../../features/reducers/transactionSlice";
import ChanelPayment from "./chanelPayment";
import CardTransaction from "./cardTransaction";

const Beranda: React.FC = () => {
  const token = localStorage.getItem("token");
  const dispatch: AppDispatch = useDispatch();
  const { data, status, error } = useSelector((state: RootState) => state.transactions);

  const { user } = useSelector((state: RootState) => state.profile);

  const { merchantData } = useSelector((state: RootState) => state.merchant);

  const merchantId = merchantData && merchantData.length > 0 ? merchantData[0].merchant_id : null;
  useEffect(() => {
    if (merchantId && token) {
      dispatch(fetchTransactionData({ merchantId: merchantId, token }));
    }
  }, [dispatch, merchantId, token]);

  if (status === "loading") {
    return <div className="flex justify-center text-6xl items-center text-orange-600 font-semibold">....</div>;
  }

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  // Tambahkan fungsi untuk menentukan warna berdasarkan nama bank
  const getBankColor = (bankName: string) => {
    switch (bankName) {
      case "Bank BRI":
        return "#1A1A1A";
      case "Bank Mandiri":
        return "#F15A24";
      case "Bank BNI":
        return "#F8931F";
      case "Bank BCA":
        return "#555770";
      default:
        return "#000000";
    }
  };

  return (
    <section>
      <div className="flex relative">
        <div className="flex flex-col  mb-7  w-full space-y-6 ">
          {/* jika data is_active itu data nya verifikasi maka tampilan ini di hilangkan */}
          {user?.is_aktif !== "Verifikasi" && (
            <div className="flex">
              <div className="border border-[#DDE5E9] rounded-2xl flex items-center gap-14 justify-start w-full h-auto shadow-sm px-10 py-[18px] contentOne">
                <div className="w-full">
                  <h1 className="text-xl font-bold text-[#1A1A1A] text-start">Silahkan Lengkapi Data-Data Anda Terlebih Dahulu!</h1>
                  <p className="text-[#1A1A1A] text-sm text-start mt-3">Untuk pengalaman yang lebih personal dan sesuai dengan kebutuhan, ayo lengkapi data-data kamu dulu.</p>
                </div>
                <button className="px-8 py-3 mt-10 w-[300px] rounded-full text-white bg-[#F15A24] font-medium">
                  <Link to="/me/data" className="flex gap-4 justify-center items-center">
                    Lengkapi Data {arrowrightberanda()}
                  </Link>
                </button>
              </div>
            </div>
          )}

          {/* Stats Overview */}
          <div className="flex gap-7 w-full ">
            <div className="w-full">
              <div className="flex justify-center gap-3 mb-4 w-full">
                <div className="w-full h-[166px] bg-[#1A1A1A] text-white rounded-2xl p-6 flex flex-col items-start justify-between">
                  <div className="flex items-center gap-3 text-md">
                    <div className="bg-[#FEEFE926] rounded-lg p-2">{transaksi()}</div>
                    Transaksi Hari ini
                  </div>
                  <p className="text-[32px] font-bold">Rp {data?.totalJumlahTransaksi?.toLocaleString("id-ID")}</p>
                  <div className="flex gap-3 items-center -mb-2">
                    {Number(data?.persentaseTransaksi) > 0 && <div className={`${Number(data?.persentaseTransaksi) < 0 ? "transform scale-y-[-1]" : ""}`}>{arrowgraph()}</div>}

                    <p className="text-xs text-white">
                      <span className="text-[#F15A24]">{data?.persentaseTransaksi !== undefined ? Math.abs(Number(data.persentaseTransaksi)) : 0}%</span> Hari ini
                    </p>
                  </div>
                </div>

                <div className="w-full bg-[#F15A24] text-white rounded-2xl p-6 flex flex-col items-start justify-between">
                  <div className="flex items-center gap-3 text-md">
                    <div className="bg-[#FEEFE926] rounded-lg p-2">{userberanda()}</div>
                    Total User Transaksi
                  </div>
                  <p className="text-[32px] font-bold">{data?.totalUserTransaksi}</p>
                  <div className="flex gap-3 items-center -mb-2">
                    {Number(data?.persentaseUserTransaksi) > 0 && <div className={`${Number(data?.persentaseUserTransaksi) < 0 ? "transform scale-y-[-1]" : ""}`}>{arrowgraphwhite()}</div>}
                    <p className="text-xs text-white">{data?.persentaseTransaksi !== undefined ? Math.abs(Number(data.persentaseUserTransaksi)) : 0}% Hari ini</p>
                  </div>
                </div>
              </div>

              {/* Payment Channels */}
              <div className="space-y-4">
                <h2 className="text-lg text-start font-semibold text-[#1A1A1A]">Akses Pembayaran</h2>
                <ChanelPayment />
              </div>
            </div>

            {/* STATISTIK PEMBAYARAN */}
            <div className="w-[550px] bg-white rounded-2xl p-5 flex flex-col shadow-[2px_2px_20px_10px_rgba(0,0,0,0.04)] border border-[#DDE5E9]">
              <h2 className="text-lg font-semibold text-start mb-11">Transaksi Bank</h2>

              {/* Diagram Donat */}
              <div className="px-4 mb-12">
                <DonutChart />
              </div>
              {data?.persentaseTransaksiPerBank.map((item, index) => (
                <ul key={index} className="space-y-5 w-full">
                  <li className="flex justify-between font-medium text-[#555770]">
                    <div className="flex gap-4">
                      <span
                        className="w-6 h-6 rounded-full"
                        style={{
                          backgroundColor: getBankColor(item.bank_name),
                        }}></span>
                      <span>{item.bank_name}</span>
                    </div>
                    <span className="text-[22px] font-bold">{item.percentage}%</span>
                  </li>
                </ul>
              ))}
            </div>
          </div>
          <div>
            <CardTransaction />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Beranda;
