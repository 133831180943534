import React from "react";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { RootState } from "../../../features/stores";

const ChanelPayment: React.FC = () => {
  const { data } = useSelector((state: RootState) => state.transactions);
  const getData = (value: number) => {
    return {
      datasets: [
        {
          data: [value, 100 - value],
          backgroundColor: ["#F15A24", "#FBCCBB"],
          borderWidth: 0,
        },
      ],
    };
  };

  return (
    <div className="space-y-4 relative ">
      {data?.persentaseAksesPembayaranHariIni.map((item, index) => (
        <div key={index} className="flex justify-between items-center text-start rounded-2xlshadow-[2px_2px_20px_10px_rgba(0,0,0,0.04)] border border-[#DDE5E9] px-6 py-5">
          <div>
            <h3 className="text-xl font-bold">{item.payment_method}</h3>
            <p className="text-sm text-gray-500 mt-1"> Akses Pembayaran</p>
          </div>
          <div className="relative h-20 w-20">
            <Doughnut
              data={getData(item.percentage)}
              options={{
                cutout: "70%",
                plugins: {
                  tooltip: { enabled: false },
                },
              }}
            />
            <span className="text-sm font-bold text-[#F15A24] absolute inset-0 lg:mt-[12px] flex items-center justify-center">{item.percentage}%</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChanelPayment;
