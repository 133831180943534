import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import {
  PercentageData,
  TransactionResponse,
} from "../interface/transaction.interface";

interface TransactionState {
  data: PercentageData | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: TransactionState = {
  data: null,
  status: "idle",
  error: null,
};

// Create async thunk for fetching transaction data
export const fetchTransactionData = createAsyncThunk<
  PercentageData,
  { merchantId: string; token: string }
>("transactions/fetchTransactionData", async ({ merchantId, token }) => {
  const response = await axios.get<TransactionResponse>(
    `${process.env.REACT_APP_API_URL}/v1.0/transaction-percentage/${merchantId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  console.log("Data transaksi", response.data.data);
  return response.data.data;
});

// Create the slice
const transactionSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactionData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchTransactionData.fulfilled,
        (state, action: PayloadAction<PercentageData>) => {
          state.status = "succeeded";
          state.data = action.payload;
        }
      )
      .addCase(fetchTransactionData.rejected, (state, action) => {
        state.status = "failed";
        state.error =
          action.error.message || "Failed to fetch transaction data";
      });
  },
});

export default transactionSlice.reducer;
