export const IconPallet1 = () => (
  <svg
    width="241"
    height="416"
    viewBox="0 0 241 416"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M67.2132 76.6819C55.2851 91.0302 36.3174 98.6054 18.4959 106.917C0.674456 115.228 -16.9305 125.123 -24.6425 142.495C-37.8398 172.242 -17.0216 218.312 17.0682 249.662C51.158 281.012 95.2584 299.118 136.021 306.929C151.604 309.915 167.057 311.615 182.82 316.525C198.583 321.435 215.181 330.151 226.639 343.325C243.012 362.149 244.579 386.367 233.656 399.977C222.733 413.587 201.666 417.672 179.727 415.008C157.788 412.344 134.864 403.615 112.488 394.001C82.6727 381.192 52.8766 366.493 25.1131 347.481C-2.65043 328.469 -28.4254 304.87 -46.5049 278.092C-71.6899 240.785 -80.1255 200.135 -79.2782 163.978C-77.6642 95.0245 -43.6769 37.6044 13.3161 7.55864C21.1406 3.43541 29.7639 -0.258064 40.301 0.563516C60.0646 2.1105 71.9525 18.0824 76.1551 34.8223C80.277 51.2378 75.8907 66.2395 67.2132 76.6819Z"
      fill="url(#paint0_linear_18_2705)"
      fillOpacity="0.5"
    />
    <defs>
      <linearGradient
        id="paint0_linear_18_2705"
        x1="156.128"
        y1="383.184"
        x2="73.1036"
        y2="63.8905"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCC00" />
        <stop offset="0.2294" stopColor="#FFCC00" stop-opacity="0.69" />
        <stop offset="0.711" stopColor="#FF6600" />
        <stop offset="1" stopColor="#EF401A" />
      </linearGradient>
    </defs>
  </svg>
);

export const IconHamberger = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.3332 24.0067C29.3332 24.74 28.7385 25.3333 28.0065 25.3333H17.3265C16.9746 25.3333 16.6372 25.1935 16.3884 24.9447C16.1396 24.696 15.9998 24.3585 15.9998 24.0067C15.9998 23.6548 16.1396 23.3174 16.3884 23.0686C16.6372 22.8198 16.9746 22.68 17.3265 22.68H28.0065C28.7398 22.68 29.3332 23.2733 29.3332 24.0067ZM29.3332 16C29.3332 16.7333 28.7385 17.3267 28.0065 17.3267H3.99317C3.64132 17.3267 3.30387 17.1869 3.05508 16.9381C2.80628 16.6893 2.6665 16.3518 2.6665 16C2.6665 15.6481 2.80628 15.3107 3.05508 15.0619C3.30387 14.8131 3.64132 14.6733 3.99317 14.6733H28.0065C28.7398 14.6733 29.3332 15.268 29.3332 16ZM28.0065 9.31999C28.3584 9.31999 28.6958 9.18022 28.9446 8.93142C29.1934 8.68262 29.3332 8.34518 29.3332 7.99332C29.3332 7.64147 29.1934 7.30403 28.9446 7.05523C28.6958 6.80643 28.3584 6.66666 28.0065 6.66666H11.9932C11.8189 6.66666 11.6464 6.70097 11.4855 6.76764C11.3245 6.83431 11.1783 6.93204 11.0551 7.05523C10.9319 7.17842 10.8342 7.32467 10.7675 7.48563C10.7008 7.64659 10.6665 7.8191 10.6665 7.99332C10.6665 8.16754 10.7008 8.34006 10.7675 8.50102C10.8342 8.66198 10.9319 8.80823 11.0551 8.93142C11.1783 9.05461 11.3245 9.15233 11.4855 9.219C11.6464 9.28567 11.8189 9.31999 11.9932 9.31999H28.0065Z"
      fill="#252525"
    />
  </svg>
);

export const IconClose = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.3335 9.33331L22.6668 22.6666M9.3335 22.6666L22.6668 9.33331"
      stroke="black"
      strokeWidth="1.5"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const IconIg = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="17" fill="#1E1E1E" />
    <g id="Halaman Utama Tab Design" clipPath="url(#clip0_0_1)">
      <rect
        width="834"
        height="3188"
        transform="translate(-24 -3047)"
        fill="#F7F7F7"
      />
      <g id="Footer">
        <path
          d="M-24 -91.8333C-24 -104.628 -13.6279 -115 -0.833336 -115H786.833C799.628 -115 810 -104.628 810 -91.8333V141H-24V-91.8333Z"
          fill="#F15A24"
          fillOpacity="0.1"
        />
        <g id="Frame 427322319">
          <mask id="path-2-inside-1_0_1" fill="white">
            <path d="M0 -83H786V75.1333H0V-83Z" />
          </mask>
          <path
            d="M786 74.5542H0V75.7125H786V74.5542Z"
            fill="#BFBFBF"
            mask="url(#path-2-inside-1_0_1)"
          />
          <g id="Frame 427322317">
            <g id="Frame 427322278">
              <path
                id="Vector"
                d="M4.64 0.166656H11.36C13.92 0.166656 16 2.24666 16 4.80666V11.5267C16 12.7573 15.5111 13.9375 14.641 14.8076C13.7708 15.6778 12.5906 16.1667 11.36 16.1667H4.64C2.08 16.1667 0 14.0867 0 11.5267V4.80666C0 3.57605 0.488856 2.39585 1.35902 1.52568C2.22919 0.655512 3.4094 0.166656 4.64 0.166656ZM4.48 1.76666C3.71618 1.76666 2.98364 2.07008 2.44353 2.61019C1.90343 3.15029 1.6 3.88283 1.6 4.64666V11.6867C1.6 13.2787 2.888 14.5667 4.48 14.5667H11.52C12.2838 14.5667 13.0164 14.2632 13.5565 13.7231C14.0966 13.183 14.4 12.4505 14.4 11.6867V4.64666C14.4 3.05466 13.112 1.76666 11.52 1.76666H4.48ZM12.2 2.96666C12.4652 2.96666 12.7196 3.07201 12.9071 3.25955C13.0946 3.44709 13.2 3.70144 13.2 3.96666C13.2 4.23187 13.0946 4.48623 12.9071 4.67376C12.7196 4.8613 12.4652 4.96666 12.2 4.96666C11.9348 4.96666 11.6804 4.8613 11.4929 4.67376C11.3054 4.48623 11.2 4.23187 11.2 3.96666C11.2 3.70144 11.3054 3.44709 11.4929 3.25955C11.6804 3.07201 11.9348 2.96666 12.2 2.96666ZM8 4.16666C9.06087 4.16666 10.0783 4.58808 10.8284 5.33823C11.5786 6.08837 12 7.10579 12 8.16666C12 9.22752 11.5786 10.2449 10.8284 10.9951C10.0783 11.7452 9.06087 12.1667 8 12.1667C6.93913 12.1667 5.92172 11.7452 5.17157 10.9951C4.42143 10.2449 4 9.22752 4 8.16666C4 7.10579 4.42143 6.08837 5.17157 5.33823C5.92172 4.58808 6.93913 4.16666 8 4.16666ZM8 5.76666C7.36348 5.76666 6.75303 6.01951 6.30294 6.4696C5.85286 6.91969 5.6 7.53014 5.6 8.16666C5.6 8.80318 5.85286 9.41363 6.30294 9.86371C6.75303 10.3138 7.36348 10.5667 8 10.5667C8.63652 10.5667 9.24697 10.3138 9.69706 9.86371C10.1471 9.41363 10.4 8.80318 10.4 8.16666C10.4 7.53014 10.1471 6.91969 9.69706 6.4696C9.24697 6.01951 8.63652 5.76666 8 5.76666Z"
                fill="black"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_0_1">
        <rect
          width="834"
          height="3188"
          fill="white"
          transform="translate(-24 -3047)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const IconFb = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M8.93215 0.166656C4.51448 0.166656 0.900024 3.77308 0.900024 8.21485C0.900024 12.2309 3.83978 15.5642 7.67914 16.1667V10.5442H5.63898V8.21485H7.67914V6.43975C7.67914 4.42369 8.87593 3.31525 10.7153 3.31525C11.5908 3.31525 12.5065 3.46786 12.5065 3.46786V5.4518H11.4944C10.4984 5.4518 10.1852 6.07027 10.1852 6.70481V8.21485H12.4181L12.0567 10.5442H10.1852V16.1667C12.0779 15.8677 13.8014 14.902 15.0445 13.4438C16.2877 11.9856 16.9686 10.131 16.9643 8.21485C16.9643 3.77308 13.3498 0.166656 8.93215 0.166656Z"
      fill="black"
    />
  </svg>
);

export const IconTyb = () => (
  <svg
    width="23"
    height="17"
    viewBox="0 0 23 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M9.66426 11.5952L15.3733 8.16666L9.66426 4.73809V11.5952ZM22.3803 2.64666C22.5233 3.1838 22.6223 3.9038 22.6883 4.81808C22.7653 5.73237 22.7983 6.52094 22.7983 7.20666L22.8643 8.16666C22.8643 10.6695 22.6883 12.5095 22.3803 13.6867C22.1053 14.7152 21.4673 15.3781 20.4773 15.6638C19.9603 15.8124 19.0143 15.9152 17.5623 15.9838C16.1323 16.0638 14.8233 16.0981 13.6133 16.0981L11.8643 16.1667C7.25526 16.1667 4.38426 15.9838 3.25126 15.6638C2.26126 15.3781 1.62326 14.7152 1.34826 13.6867C1.20526 13.1495 1.10626 12.4295 1.04026 11.5152C0.963258 10.6009 0.930258 9.81237 0.930258 9.12666L0.864258 8.16666C0.864258 5.6638 1.04026 3.8238 1.34826 2.64666C1.62326 1.61809 2.26126 0.955228 3.25126 0.669514C3.76826 0.520942 4.71426 0.418085 6.16626 0.349513C7.59626 0.269513 8.90526 0.235228 10.1153 0.235228L11.8643 0.166656C16.4733 0.166656 19.3443 0.349514 20.4773 0.669514C21.4673 0.955228 22.1053 1.61809 22.3803 2.64666Z"
      fill="black"
    />
  </svg>
);

export const IconTwitter = () => (
  <svg
    width="19"
    height="17"
    viewBox="0 0 19 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M14.9393 0.166656H17.6997L11.6697 6.94345L18.7643 16.1667H13.21L8.85657 10.574L3.88085 16.1667H1.11785L7.567 8.91571L0.764282 0.167918H6.46L10.3891 5.27889L14.9393 0.166656ZM13.9686 14.5424H15.4986L5.62428 1.70638H3.98371L13.9686 14.5424Z"
      fill="black"
    />
  </svg>
);

export const IconKutif = () => (
  <svg
    width="27"
    height="16"
    viewBox="0 0 27 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.50756 0.687999H13.3076L7.03556 15.696H0.595563L3.50756 0.687999ZM16.2196 0.687999H26.0196L19.7476 15.696H13.3076L16.2196 0.687999Z"
      fill="#252525"
    />
  </svg>
);

export const IconDompet = (width: string, height: string) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.6667 37.3333C46.6667 39.2653 45.0987 40.8333 43.1667 40.8333C41.2347 40.8333 39.6667 39.2653 39.6667 37.3333C39.6667 35.4013 41.2347 33.8333 43.1667 33.8333C45.0987 33.8333 46.6667 35.4013 46.6667 37.3333ZM53.6667 18.6667C52.3763 18.6667 51.3333 19.712 51.3333 21V49C51.3333 50.2857 50.288 51.3333 49 51.3333H11.6667C7.80733 51.3333 4.66667 48.1927 4.66667 44.3333V21V20.9883C6.65933 22.4817 9.107 23.3333 11.6643 23.3333H34.9977C36.288 23.3333 37.331 22.288 37.331 21C37.331 19.712 36.288 18.6667 34.9977 18.6667H11.6667C9.66933 18.6667 7.80967 17.7777 6.49367 16.324C7.77467 14.9077 9.60867 14 11.6667 14H28C29.2903 14 30.3333 12.9547 30.3333 11.6667C30.3333 10.3787 29.2903 9.33333 28 9.33333H11.6667C4.66667 9.33333 0 15.1667 0 21V44.3333C0 50.7663 5.23367 56 11.6667 56H49C52.8593 56 56 52.8593 56 49V21C56 19.712 54.957 18.6667 53.6667 18.6667ZM38.9737 9.82566L42 6.83666V21C42 22.288 43.043 23.3333 44.3333 23.3333C45.6237 23.3333 46.6667 22.288 46.6667 21V6.87866L49.707 9.83733C50.1597 10.2783 50.7453 10.5 51.3333 10.5C51.9423 10.5 52.549 10.2643 53.0063 9.79533C53.9047 8.87133 53.886 7.39433 52.9597 6.49599L47.7633 1.43733C45.8477 -0.473673 42.742 -0.47134 40.8333 1.42799L35.6907 6.50766C34.7737 7.41299 34.7667 8.88999 35.6697 9.80699C36.5797 10.7263 38.052 10.731 38.9713 9.82799L38.9737 9.82566Z"
      fill="white"
    />
  </svg>
);

export const IconPlan = (width: string, height: string) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M53.9394 2.05666C53.1029 1.20994 52.062 0.593286 50.9175 0.266287C49.7731 -0.0607122 48.5636 -0.0870057 47.4059 0.189946L10.0717 8.05348C7.92163 8.34834 5.89662 9.23762 4.22467 10.6212C2.55272 12.0047 1.30025 13.8276 0.608314 15.8845C-0.0836253 17.9414 -0.187553 20.1506 0.308233 22.2634C0.80402 24.3762 1.87983 26.3086 3.41451 27.843L7.42328 31.8494C7.64024 32.0663 7.81231 32.3239 7.92962 32.6073C8.04694 32.8908 8.1072 33.1946 8.10696 33.5014V40.8936C8.11211 41.933 8.35137 42.9578 8.80698 43.892L8.78831 43.9084L8.84898 43.969C9.53269 45.3437 10.6494 46.4555 12.0271 47.1331L12.0877 47.1938L12.1041 47.1751C13.0383 47.6307 14.0631 47.87 15.1025 47.8751H22.4946C23.1131 47.8746 23.7065 48.1197 24.1444 48.5565L28.1508 52.5629C29.2254 53.6494 30.5044 54.5125 31.914 55.1024C33.3237 55.6923 34.8362 55.9973 36.3643 56C37.6378 55.9984 38.9026 55.7904 40.1094 55.384C42.1475 54.7148 43.958 53.4893 45.3368 51.8459C46.7155 50.2025 47.6075 48.2065 47.9123 46.0831L55.7875 8.66716C56.0788 7.49962 56.0616 6.27641 55.7377 5.11752C55.4137 3.95862 54.794 2.90384 53.9394 2.05666ZM10.7274 28.5547L6.71626 24.5482C5.78225 23.6367 5.12762 22.4777 4.82921 21.2072C4.5308 19.9367 4.601 18.6074 5.03155 17.3754C5.44898 16.1114 6.22066 14.994 7.2548 14.1559C8.28894 13.3178 9.54202 12.7944 10.865 12.6479L47.8283 4.86606L12.7691 39.9299V33.5014C12.7726 32.5829 12.594 31.6727 12.2435 30.8236C11.8931 29.9745 11.3777 29.2033 10.7274 28.5547ZM43.3272 45.2851C43.148 46.5739 42.6132 47.7873 41.7827 48.789C40.9522 49.7907 39.859 50.5411 38.6257 50.956C37.3924 51.3709 36.0679 51.4339 34.8008 51.1378C33.5337 50.8417 32.3743 50.1983 31.4525 49.2798L27.4391 45.2664C26.7913 44.615 26.0207 44.0986 25.172 43.7469C24.3233 43.3953 23.4133 43.2154 22.4946 43.2177H16.0662L51.13 8.16548L43.3272 45.2851Z"
      fill="white"
    />
  </svg>
);

export const IconBayar = (width: string, height: string) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M48.8668 9.61569C48.4842 6.58469 46.1298 4.11603 43.1408 3.60969C33.0912 1.91103 22.9062 1.91103 12.8565 3.60969C9.86749 4.11369 7.51315 6.58469 7.13049 9.61803C5.63482 21.5787 5.57649 33.705 6.96015 45.661C7.13982 47.2197 7.97982 48.6547 9.25615 49.5904C10.1918 50.2787 11.1205 50.9694 12.0398 51.6554C13.8528 53.011 16.2865 53.1674 18.2325 52.0567C19.0398 51.597 19.8588 51.1187 20.6872 50.624C21.0558 50.4 21.5248 50.414 21.8818 50.659L24.7892 52.6704C25.7598 53.34 26.8728 53.6737 27.9811 53.6737C29.0895 53.6737 30.2025 53.34 31.1685 52.6727L34.0828 50.659C34.4328 50.4164 34.8972 50.4024 35.2728 50.624C36.1082 51.121 36.9342 51.6017 37.7462 52.0637C39.6945 53.172 42.1211 53.0134 43.9318 51.6647C44.8582 50.974 45.7938 50.281 46.7318 49.5904C48.0152 48.6524 48.8551 47.2174 49.0348 45.654C50.4185 33.7004 50.3578 21.5764 48.8645 9.61569H48.8668ZM44.4032 45.1197C44.3705 45.3904 44.2141 45.654 43.9762 45.8267C43.0242 46.5267 42.0815 47.2267 41.1435 47.922C40.8168 48.167 40.3922 48.1974 40.0538 48.0084C39.2675 47.5604 38.4695 47.096 37.6645 46.6154C35.7208 45.458 33.2708 45.5397 31.4205 46.8277L28.5225 48.832C28.1865 49.063 27.7735 49.063 27.4375 48.832L24.5442 46.83C22.6938 45.5444 20.2392 45.4604 18.2932 46.62C17.4952 47.0984 16.7018 47.5604 15.9225 48.006C15.5842 48.1974 15.1572 48.1624 14.8282 47.9174C13.8995 47.2244 12.9615 46.5267 12.0165 45.829C11.7808 45.6587 11.6245 45.395 11.5918 45.1244C10.2548 33.5394 10.3085 21.7887 11.7575 10.199C11.8858 9.19336 12.6558 8.37669 13.6335 8.21103C23.1628 6.60336 32.8298 6.60336 42.3591 8.21103C43.3368 8.37669 44.1068 9.19336 44.2351 10.1967C45.6842 21.7864 45.7402 33.537 44.4008 45.1197H44.4032Z"
      fill="white"
    />
    <path
      d="M36.1667 13.5753H19.8333C18.543 13.5753 17.5 14.6207 17.5 15.9087C17.5 17.1967 18.543 18.242 19.8333 18.242H36.1667C37.457 18.242 38.5 17.1967 38.5 15.9087C38.5 14.6207 37.457 13.5753 36.1667 13.5753Z"
      fill="white"
    />
    <path
      d="M36.1667 23.3311H19.8333C18.543 23.3311 17.5 24.3764 17.5 25.6644C17.5 26.9524 18.543 27.9977 19.8333 27.9977H36.1667C37.457 27.9977 38.5 26.9524 38.5 25.6644C38.5 24.3764 37.457 23.3311 36.1667 23.3311Z"
      fill="white"
    />
    <path
      d="M28.371 33.0867H19.8333C18.543 33.0867 17.5 34.132 17.5 35.42C17.5 36.708 18.543 37.7533 19.8333 37.7533H28.371C29.6613 37.7533 30.7043 36.708 30.7043 35.42C30.7043 34.132 29.6613 33.0867 28.371 33.0867Z"
      fill="white"
    />
  </svg>
);

export const IconPlus = (width: string, height: string) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 0C22.4621 0 17.0486 1.64217 12.444 4.71885C7.83947 7.79553 4.25064 12.1685 2.13139 17.2849C0.012132 22.4012 -0.542361 28.0311 0.538025 33.4625C1.61841 38.894 4.28515 43.8831 8.20102 47.799C12.1169 51.7149 17.106 54.3816 22.5375 55.462C27.9689 56.5424 33.5988 55.9879 38.7151 53.8686C43.8315 51.7494 48.2045 48.1605 51.2811 43.556C54.3578 38.9514 56 33.5379 56 28C55.992 20.5764 53.0394 13.4592 47.7901 8.20988C42.5408 2.9606 35.4236 0.00802919 28 0ZM28 51.3333C23.3851 51.3333 18.8738 49.9648 15.0367 47.4009C11.1996 44.8371 8.20887 41.1929 6.44282 36.9293C4.67678 32.6657 4.2147 27.9741 5.11502 23.4479C6.01534 18.9217 8.23763 14.7641 11.5009 11.5008C14.7641 8.23762 18.9217 6.01533 23.4479 5.11501C27.9741 4.21469 32.6657 4.67677 36.9293 6.44281C41.1929 8.20885 44.8371 11.1995 47.401 15.0367C49.9649 18.8738 51.3333 23.3851 51.3333 28C51.3265 34.1863 48.866 40.1173 44.4917 44.4916C40.1173 48.866 34.1863 51.3265 28 51.3333ZM39.6667 28C39.6667 28.6188 39.4208 29.2123 38.9833 29.6499C38.5457 30.0875 37.9522 30.3333 37.3333 30.3333H30.3333V37.3333C30.3333 37.9522 30.0875 38.5457 29.6499 38.9832C29.2123 39.4208 28.6188 39.6667 28 39.6667C27.3812 39.6667 26.7877 39.4208 26.3501 38.9832C25.9125 38.5457 25.6667 37.9522 25.6667 37.3333V30.3333H18.6667C18.0478 30.3333 17.4543 30.0875 17.0168 29.6499C16.5792 29.2123 16.3333 28.6188 16.3333 28C16.3333 27.3812 16.5792 26.7877 17.0168 26.3501C17.4543 25.9125 18.0478 25.6667 18.6667 25.6667H25.6667V18.6667C25.6667 18.0478 25.9125 17.4543 26.3501 17.0167C26.7877 16.5792 27.3812 16.3333 28 16.3333C28.6188 16.3333 29.2123 16.5792 29.6499 17.0167C30.0875 17.4543 30.3333 18.0478 30.3333 18.6667V25.6667H37.3333C37.9522 25.6667 38.5457 25.9125 38.9833 26.3501C39.4208 26.7877 39.6667 27.3812 39.6667 28Z"
      fill="white"
    />
  </svg>
);
