import React from "react";
import { fb, ig, linkin, email, twiter } from "../../../../assets/icon";
import { logoudin2 } from "../../../../assets/icon";
import "./style.css";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <section id="contact" className="border-[6px] border-[#E9EAEA] mt-28 mb-10 rounded-3xl ms-2 vector bg-[#1A1A1A] text-white py-8 px-8">
      <div className="flex flex-col gap-6 md:flex-row justify-between items-start md:items-center">
        <div className="flex flex-col items-center md:items-start mb-6 md:mb-0">
          <div className="flex flex-col  justify-start mb-4">
            {logoudin2()}
            <div className="text-start my-5 ">
              Ikuti kami di media sosial untuk <br />
              mendapatkan update terbaru dan tips dari kami.
            </div>
          </div>
          <div className="flex space-x-7">
            <Link to="#">{fb()}</Link>
            <Link to="#">{ig()}</Link>
            <Link to="#">{linkin()}</Link>
            <Link to="#">{email()}</Link>
            <Link to="#">{twiter()}</Link>
          </div>
        </div>
        <div className="flex flex-col items-start  md:flex-row justify-between md:w-auto space-y-4 md:space-y-0 md:space-x-12">
          {/* <div className="flex flex-col w-40  gap-3 text-start text-base">
            <h3 className="font-bold mb-2">Perusahaan</h3>
            <Link to="#" className="hover:underline">
              Tentang Kami
            </Link>

            <Link to="#" className="hover:underline">
              Karir
            </Link>

            <Link to="#" className="hover:underline">
              BLog
            </Link>
          </div> */}
          <div className="flex flex-col gap-3 text-start text-base ">
            <h3 className="font-bold mb-2">Layanan</h3>

            <Link to="#" className="hover:underline">
              Integrasi Mudah
            </Link>

            <Link to="#" className="hover:underline">
              Keamanan Tinggi
            </Link>

            <Link to="#" className="hover:underline">
              Dukungan Multi-Currency
            </Link>

            <Link to="#" className="hover:underline">
              Laporan Real Time
            </Link>
          </div>
          <div className="flex flex-col  gap-3 text-start text-base">
            <h3 className="font-bold mb-2">Bantuan</h3>
            <Link to="#" className="hover:underline">
              FAQ
            </Link>

            <Link to="#" className="hover:underline">
              Panduan Pengguna
            </Link>

            <Link to="#" className="hover:underline">
              Dukungan Pelanggan
            </Link>

            <Link to="#" className="hover:underline">
              Hubungi Kami
            </Link>
          </div>
          <div className="flex flex-col gap-3  text-start text-base  ">
            <h3 className="font-bold mb-2">Hubungi Kami</h3>

            {/* <div>
              <p>Alamat: Jl. Komp. BSD No : A3/28,</p>
              <p> Desa/Kelurahan Lengkong Wetan, </p>
              <p>Kec. Serpong, Kota Tangerang Selatan, Provinsi Banten,</p>
              <p> Kode Pos : 15322</p>
            </div> */}

            <div className="w-96">Alamat: Jl. Komp. BSD No : A3/28 Desa/Kelurahan Lengkong Wetan, Kec. Serpong, Kota Tangerang Selatan, Provinsi Banten, Kode Pos : 15322</div>

            <p>Telepon: +62 8177 0912 555</p>
            <p>Email: official@ultradigitalindonesia.co.id</p>
          </div>
        </div>
      </div>
      <div className="mt-16 text-center">
        <p>© 2024 PT Ultra Digital Indonesia</p>
      </div>
    </section>
  );
};

export default Footer;
