import React, { useState, useEffect } from "react";
import Rekening from "./rekening";
import { cloudfile, pdficon } from "../../../assets/icon";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../features/stores";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile } from "../../../features/reducers/profileSlice";

interface Bank {
  _id: string;
  namaBank: string;
}

// interface ModalProps {
//   isOpen: boolean;
//   onClose: () => void;
//   onSave: (data: { nama_pemilik_rekening: string; bank: Bank; nomor_rekening: string; cabang_bank: string; alamat_bank: string }) => void;
//   title: string;
// }

const DataPerusahaan: React.FC = () => {
  const [nama_individu, setnama_individu] = useState("");
  const [nomor_ktp, setnomor_ktp] = useState("");
  const [foto_ktp, setfoto_ktp] = useState<File | null>(null);
  const [no_npwp, setno_npwp] = useState("");
  const [foto_npwp, setfoto_npwp] = useState<File | null>(null);

  const [alamat, setAlamat] = useState("");

  // UPLOAD PROGRES

  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgressNpwp, setUploadProgressNpwp] = useState<number>(0);
  const [isUploadingNpwp, setIsUploadingNpwp] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rekening, setrekening] = useState<{
    nama_pemilik_rekening: string;
    bank: Bank;
    nomor_rekening: string;
    cabang_bank: string;
    alamat_bank: string;
  } | null>(null);

  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector((state: RootState) => state.profile);

  // const [isVerified, setIsVerified] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State untuk mengontrol tampilan popup

  const isFormValid = () => {
    return nomor_ktp.length === 16 && foto_ktp && rekening && no_npwp.length === 16 && foto_npwp && alamat;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, setFile: React.Dispatch<React.SetStateAction<File | null>>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setFile(file);
      uploadFile(file);
    }
  };

  const uploadFile = (file: File) => {
    setIsUploading(true);
    const fakeUpload = setInterval(() => {
      setUploadProgress((prev) => {
        if (prev >= 100) {
          clearInterval(fakeUpload);
          setIsUploading(false);
          return 100;
        }
        return prev + 10;
      });
    }, 500);
  };

  const handleFileChangeNpwp = (e: React.ChangeEvent<HTMLInputElement>, setFile: React.Dispatch<React.SetStateAction<File | null>>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setFile(file);
      uploadFileNpwp(file);
    }
  };

  const uploadFileNpwp = (file: File) => {
    setIsUploadingNpwp(true);
    const fakeUpload = setInterval(() => {
      setUploadProgressNpwp((prev) => {
        if (prev >= 100) {
          clearInterval(fakeUpload);
          setIsUploadingNpwp(false);
          return 100;
        }
        return prev + 10;
      });
    }, 500);
  };

  // MODAL POPUP

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveRekening = (data: { nama_pemilik_rekening: string; bank: Bank; nomor_rekening: string; cabang_bank: string; alamat_bank: string }) => {
    setrekening(data);
    closeModal();
    // console.log(data);
  };

  const handleVerify = (e: React.FormEvent) => {
    // Open the confirmation popup
    setIsPopupOpen(true);

    e.preventDefault();
    if (!nama_individu) return alert("nama lengkap harus diisi");
    if (!nomor_ktp) return alert("no ktp harus diisi");
    if (!foto_ktp) return alert("file ktp harus diisi");
    if (!no_npwp) return alert("no npwp direktur harus harus diisi");
    if (!foto_npwp) return alert("file npwp harus diisi");
    if (!alamat) return alert("alamat harus diisi");
    if (!foto_ktp) return alert("file ktp harus diisi");
    if (!rekening) return alert("rekening harus diisi");
    // console.log(rekening);

    const formData = new FormData();
    formData.append("nama_individu", nama_individu);
    formData.append("nomor_ktp", nomor_ktp);
    formData.append("no_npwp", no_npwp);
    formData.append("alamat", alamat);

    if (rekening) {
      formData.append("nama_pemilik_rekening", rekening.nama_pemilik_rekening || "");
      formData.append("bank_id", rekening.bank._id);
      formData.append("nomor_rekening", rekening.nomor_rekening || "");
      formData.append("cabang_bank", rekening.cabang_bank || "");
      formData.append("alamat_bank", rekening.alamat_bank || "");
    }

    // console.log(rekening.nama_pemilik_rekening);

    // Menambahkan file PDF sebagai Blob
    formData.append("foto_ktp", foto_ktp as Blob);
    formData.append("foto_npwp", foto_npwp as Blob);

    const token = localStorage.getItem("token");

    axios
      .post(`${process.env.REACT_APP_API_URL}upload-user-data-individu`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          // alert("Data individu berhasil dikirim!");
          // Simpan status verifikasi di localStorage
          localStorage.setItem("isDataPerusahaanVerified", "true");
        }
      })
      .catch((error) => {
        // alert("Gagal terkirim");
        console.log(error);
      });
  };

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  useEffect(() => {
    if (user?.username) {
      setnama_individu(user.username); // Isi nama_pemilik_rekening dengan username
    }
  }, [user?.username]);

  const navigate = useNavigate();

  // Cek status verifikasi pada saat komponen di-mount
  useEffect(() => {
    const isVerified = localStorage.getItem("isDataPerusahaanVerified");
    if (isVerified === "true") {
      // navigate("/summary-perusahaan"); // Arahkan ke halaman SummaryPerusahaan jika sudah diverifikasi
    }
  }, [navigate]);

  const handlePopupYes = () => {
    setIsPopupOpen(false);
    navigate("/me/summary-individu");
    console.log(handleVerify); // Lanjut ke summary setelah verifikasi
  };

  const handlePopupNo = () => {
    setIsPopupOpen(false); // Tutup popup untuk pemeriksaan data kembali
  };

  return (
    <section>
      <div className=" mb-10 p-[50px] bg-white border border-[#DDE5E9] rounded-2xl  ">
        <div className="bg-[#FEEFE9]  rounded-xl font-medium p-3 mb-10 text-start">
          <div className="text-orange-600 ms-3">Pastikan nama di KTP dan rekening bank Anda sama. Ini diperlukan agar proses verifikasi dan transaksi berjalan tanpa masalah.</div>
        </div>
        <h2 className="text-xl font-bold mb-4 text-start">Data </h2>

        {/* MASUKAN NAMA INDIVIDU   */}
        <div className="w-full mb-4">
          <label className="block text-gray-700 text-start ">Nama Lengkap</label>
          <input
            type="text"
            className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9] uppercase cursor-pointer"
            placeholder="Masukkan Nama Lengkap"
            value={nama_individu}
            onChange={(e) => setnama_individu(e.target.value)}
            readOnly
          />
        </div>

        {/* UNGGAH KTP  */}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="w-full">
            <label className="block text-gray-700">Nomor KTP</label>
            <input
              type="number"
              className={`w-full mt-1 p-4 rounded-2xl placeholder-[#D9D9D9] outline-none ${nomor_ktp.length > 0 && nomor_ktp.length < 16 ? "border-red-500 border-2" : "border-none"}`}
              placeholder="Masukkan Nomor KTP Anda"
              value={nomor_ktp}
              onChange={(e) => setnomor_ktp(e.target.value.slice(0, 16))}
            />
          </div>

          <div className="w-full">
            <label className="block text-gray-700">Unggah KTP </label>
            <label className="flex items-center justify-center w-full mt-1 p-[10px] border-[1.5px] border-dashed border-orange-600 rounded-2xl cursor-pointer">
              <span className="ml-2 text-gray-500">
                {isUploading ? (
                  <div className="flex items-center">
                    <svg className="animate-spin h-5 w-5 mr-3 text-orange-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                    </svg>
                    <span>{uploadProgress}% File sedang diunggah</span>
                    <button className="ml-2 text-red-600" onClick={() => setfoto_ktp(null)}>
                      ✖
                    </button>
                  </div>
                ) : (
                  <div className="flex gap-2 items-center -z-10 ">
                    {cloudfile()}
                    <span> Pilih file untuk diunggah</span>
                  </div>
                )}
              </span>
              <input type="file" accept=".pdf" onChange={(e) => handleFileChange(e, setfoto_ktp)} id="file-upload" className="hidden" />
            </label>
            <p className="text-[#A6A7A8] text-sm mt-1">*Catatan: silakan lakukan unggah File PDF, Max 10 MB</p>
            {foto_ktp && !isUploading && (
              <div className="-mt-[78px] flex items-center justify-center w-full bg-white p-[8px] border-[1.5px] border-dashed border-orange-600 rounded-2xl z-10">
                <div className="flex gap-2 items-center bg-white py-2 px-1">
                  {pdficon()}
                  <p className="text-gray-700 text-sm w-[200px] truncate">{foto_ktp.name}</p>
                </div>
                <button className=" text-white  bg-orange-600 py-2 px-4 rounded-xl" onClick={() => setfoto_ktp(null)}>
                  Ganti File
                </button>
              </div>
            )}
          </div>
        </div>

        {/* UNGGAH NPWP DIREKTUR*/}
        <div className="mb-4 flex gap-10 justify-between text-start">
          <div className="w-full">
            <label className="block text-gray-700">Nomor NPWP </label>
            <input
              type="number"
              className={`w-full mt-1 p-4 rounded-2xl placeholder-[#D9D9D9] outline-none ${nomor_ktp.length > 0 && nomor_ktp.length < 16 ? "border-red-500 border-2" : "border-none"}`}
              placeholder="Masukkan Nomor NPWP Anda"
              value={no_npwp}
              onChange={(e) => setno_npwp(e.target.value.slice(0, 16))}
            />
          </div>

          <div className="w-full">
            <label className="block text-gray-700">Unggah NPWP</label>
            <label className="flex items-center justify-center w-full mt-1 p-[10px] border-[1.5px] border-dashed border-orange-600 rounded-2xl cursor-pointer">
              <span className="ml-2 text-gray-500">
                {isUploadingNpwp ? (
                  <div className="flex items-center">
                    <svg className="animate-spin h-5 w-5 mr-3 text-orange-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                    </svg>
                    <span>{uploadProgressNpwp}% File sedang diunggah</span>
                    <button className="ml-2 text-red-600" onClick={() => setfoto_npwp(null)}>
                      ✖
                    </button>
                  </div>
                ) : (
                  <div className="flex gap-2 items-center -z-10 ">
                    {cloudfile()}
                    <span> Pilih file untuk diunggah</span>
                  </div>
                )}
              </span>
              <input type="file" accept=".pdf" onChange={(e) => handleFileChangeNpwp(e, setfoto_npwp)} id="file-upload" className="hidden" />
            </label>
            <p className="text-[#A6A7A8] text-sm mt-1">*Catatan: silakan lakukan unggah File PDF, Max 10 MB</p>
            {foto_npwp && !isUploadingNpwp && (
              <div className="-mt-[78px] flex items-center justify-center w-full bg-white p-[8px] border-[1.5px] border-dashed border-orange-600 rounded-2xl z-10">
                <div className="flex gap-2 items-center bg-white py-2 px-1">
                  {pdficon()}
                  <p className="text-gray-700 text-sm w-[200px] truncate">{foto_npwp.name}</p>
                </div>
                <button className=" text-white  bg-orange-600 py-2 px-4 rounded-xl" onClick={() => setfoto_npwp(null)}>
                  Ganti File
                </button>
              </div>
            )}
          </div>
        </div>

        {/* UNGGAH REKENING */}
        <div className="mb-7 text-start">
          <label className="block text-gray-700">Rekening</label>
          <input type="text" className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9]" placeholder="Tambah Nomor Rekening Anda" value={rekening?.bank.namaBank} onClick={openModal} onFocus={openModal} readOnly />

          <button></button>
        </div>

        {/* UNGGAH ALAMAT */}
        <div className="mb-4 text-start">
          <label className="block text-gray-700">Alamat Perusahaan </label>
          <textarea className="w-full mt-1 p-4 bg-[#F9F9FA] rounded-2xl placeholder-[#D9D9D9]" placeholder="Masukkan Alamat Anda" value={alamat} onChange={(e) => setAlamat(e.target.value)} />
        </div>

        {/* Button */}
        <button className={`py-3 px-10 mt-6 rounded-full flex justify-start ${isFormValid() ? "bg-[#F15A24] text-white" : "bg-[#F3F4F8] text-[#BFBFBF] cursor-not-allowed"}`} onClick={handleVerify} disabled={!isFormValid()}>
          Lanjut Verifikasi
        </button>
      </div>

      {/* Modal Component */}
      <Rekening isOpen={isModalOpen} onClose={closeModal} onSave={handleSaveRekening} title="Tambah Rekening" nama_individu={nama_individu} />

      {/* Popup Component */}
      {isPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg ms-[270px]">
            <h3 className="text-3xl font-bold mb-4 text-[#222831]">Konfirmasi Verifikasi </h3>
            <div className="text-[#6D7588] text-lg w-96 mb-4">Setelah Anda memverifikasi, data ini tidak dapat diubah. Pastikan semua informasi sudah benar sebelum melanjutkan. Apakah Anda ingin melanjutkan verifikasi?</div>
            <div className="flex flex-col justify-center gap-5 mt-8">
              <button className="bg-[#F15A24] text-white px-4 py-3 rounded-full" onClick={handlePopupYes}>
                Ya, Verifikasi
              </button>
              <button className="bg-white border border-[#F15A24] text-[#F15A24] px-4 py-3 rounded-full" onClick={handlePopupNo}>
                Periksa Kembali
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default DataPerusahaan;
