import React from "react";
import { IconCsv, IconExcel, IconPdf, IconPrint } from "../../../../components/exportData/Icons";

interface ExportSaldoProps {
  onClose: () => void;
}
const ExportSaldo: React.FC<ExportSaldoProps> = ({ onClose }) => {
  return (
    <section
      className="bg-white w-[128px] rounded-[8px] p-[4px] border border-slate-100 shadow-sm 
        cursor-pointer">
      <div onClick={onClose} className="flex gap-[8px] px-[14px] hover:bg-slate-100 rounded-md">
        <div className="py-[15px]">
          <IconPrint />
        </div>
        <p className="text-[#4A4D4F] py-[16px] text-[14px] font-normal">Print</p>
      </div>
      <div className="flex gap-[8px] px-[14px] hover:bg-slate-100 rounded-md">
        <div className="py-[15px]">
          <IconExcel />
        </div>
        <p className="text-[#4A4D4F] py-[16px] text-[14px] font-normal">Excel</p>
      </div>
      <div className="flex gap-[8px] px-[14px] hover:bg-slate-100 rounded-md">
        <div className="py-[15px]">
          <IconCsv />
        </div>
        <p className="text-[#4A4D4F] py-[16px] text-[14px] font-normal">Csv</p>
      </div>
      <div className="flex gap-[8px] px-[14px] hover:bg-slate-100 rounded-md">
        <div className="py-[15px]">
          <IconPdf />
        </div>
        <p className="text-[#4A4D4F] py-[16px] text-[14px] font-normal">PDF</p>
      </div>
    </section>
  );
};

export default ExportSaldo;
