import { useState, useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { routes } from "./Routers";
import "./App.css";
import Spinner from "./paymentgateway/components/loading spiner";

function App() {
  const [loading, setLoading] = useState(false); // Initially, loading is false

  useEffect(() => {
    // Handle the location change or reload by showing the spinner
    const handleBeforeUnload = () => {
      setLoading(true); // Show spinner before the location changes or reloads
    };

    // Add event listeners for when the window location is about to change or reload
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the listener on unmount
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  return <div className="App">{loading ? <Spinner /> : <RouterProvider router={routes} />}</div>;
}

export default App;
