import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "../style.css";

const AturPin: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false); // Set to false initially
  const [isPinSet, setIsPinSet] = useState<boolean>(false);
  const [pin, setPin] = useState<string[]>(["", "", "", "", "", ""]);
  const [confirmPin, setConfirmPin] = useState<string[]>(["", "", "", "", "", ""]);
  const [isConfirmingPin, setIsConfirmingPin] = useState<boolean>(false);
  const [errormessage, seterrormessage] = useState("");

  const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false); // State to handle success popup
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    if (showSuccessModal) {
      const timer = setTimeout(() => {
        setShowSuccessModal(false);
      }, 3000); // 3000ms = 3 seconds

      return () => clearTimeout(timer); // Clear the timeout if the component unmounts or if showSuccessModal changes
    }
  }, [showSuccessModal]);

  useEffect(() => {
    if (isModalOpen) {
      inputRefs.current[0]?.focus();
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (isConfirmingPin) {
      inputRefs.current[0]?.focus();
    }
  }, [isConfirmingPin]);

  const handlePinChange = (value: string, index: number) => {
    if (/^\d*$/.test(value)) {
      const newPin = [...pin];
      newPin[index] = value;
      setPin(newPin);

      // Move to the next input field if the value is entered
      if (value && index < pin.length - 1) {
        inputRefs.current[index + 1]?.focus();
      } else if (!value && index > 0) {
        inputRefs.current[index - 1]?.focus();
      }

      // Enable button if all fields are filled
      if (newPin.every((digit) => digit !== "")) {
        setIsButtonEnabled(true);
      } else {
        setIsButtonEnabled(false);
      }
    }
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = () => {
    const pinString = pin.join("");

    if (!isConfirmingPin) {
      setIsConfirmingPin(true); // Ubah ke tahap konfirmasi PIN
    } else {
      const confirmPinString = confirmPin.join("");

      if (pinString !== confirmPinString) {
        seterrormessage("PIN dan konfirmasi PIN tidak cocok. Silakan coba lagi.");
        return;
      }

      const token = localStorage.getItem("token");

      axios
        .put(
          `${process.env.REACT_APP_API_URL}buat-pin`,
          { pin: pinString },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setIsPinSet(true);
          setShowSuccessModal(true);
          setIsModalOpen(false); // Tutup modal setelah berhasil
        })
        .catch((error) => {
          console.error("Terjadi kesalahan saat mengirim PIN:", error);
        });
    }
  };

  const handleConfirmPinChange = (value: string, index: number) => {
    if (/^\d*$/.test(value)) {
      const newConfirmPin = [...confirmPin];
      newConfirmPin[index] = value;
      setConfirmPin(newConfirmPin);
      seterrormessage("");

      // Move to the next input field if the value is entered
      if (value && index < confirmPin.length - 1) {
        inputRefs.current[index + 1]?.focus();
      } else if (!value && index > 0) {
        inputRefs.current[index - 1]?.focus();
      }

      // Enable button if all fields are filled
      if (newConfirmPin.every((digit) => digit !== "")) {
        setIsButtonEnabled(true);
      } else {
        setIsButtonEnabled(false);
      }
    }
  };

  return (
    <section>
      {/* Success Modal */}
      {showSuccessModal && (
        <div className="bg-[#FEEFE9]  p-4  w-full mt-10">
          <p className="text-sm text-center text-orange-600">PIN Anda telah berhasil disimpan. Anda dapat melanjutkan proses selanjutnya.</p>
        </div>
      )}

      <div className="flex flex-col  mb-7  space-y-6">
        <div className="border border-[#DDE5E9] rounded-2xl contentTwo ">
          <div className="w-full px-6 py-[20px]">
            <div className="w-[583px]">
              <h1 className="text-xl font-bold text-[#1A1A1A] text-start">Buat Pin Anda Aman</h1>

              <p className="text-[#1A1A1A] text-sm text-start mt-3">Lindungi akun Anda dengan PIN yang kuat. PIN ini akan digunakan untuk setiap transaksi dan aktivitas penting di Udin, memastikan keamanan dana dan data Anda.</p>
            </div>

            <div className="border-[1.5px] border-orange-600 rounded-xl bg-[#FEEFE9] p-5 w-[700px] text-start mt-3">
              <div className="mb-2">
                Intruksi :
                <ul className="list-decimal  ms-4">
                  <li>Masukkan PIN Baru: Buat PIN 6 digit yang mudah diingat namun sulit ditebak.</li>
                  <li>Konfirmasi PIN: Masukkan kembali PIN yang sama untuk verifikasi.</li>
                </ul>
              </div>

              <div>
                Tips Keamanan :
                <ul className="list-disc ms-4">
                  <li>Hindari menggunakan kombinasi sederhana seperti "123456" atau tanggal lahir.</li>
                  <li>Jangan bagikan PIN Anda kepada siapa pun.</li>
                  <li>Ubah PIN secara berkala untuk menjaga keamanan akun Anda.</li>
                </ul>
              </div>
            </div>
            <button
              className=" px-14 py-3 mt-7 bg-orange-600 text-white flex justify-start text-center rounded-full"
              onClick={handleModalOpen} // Open the modal on click
            >
              Buat PIN Baru
            </button>
          </div>
        </div>
      </div>

      {/* Display modal only if PIN is not set */}
      {isModalOpen && !isPinSet && (
        <div className="fixed inset-0 flex flex-col items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-3xl shadow-lg text-center w-[400px] ms-64">
            <h2 className="text-[32px] text-[#222831] font-medium mb-4">{isConfirmingPin ? "Konfirmasi PIN" : "Buat PIN Baru"}</h2>

            <div className="flex justify-center space-x-2 my-11">
              {(isConfirmingPin ? confirmPin : pin).map((digit, index) => (
                <input
                  key={index}
                  type="password"
                  maxLength={1}
                  value={digit}
                  onChange={(e) => (isConfirmingPin ? handleConfirmPinChange(e.target.value, index) : handlePinChange(e.target.value, index))}
                  ref={(el) => (inputRefs.current[index] = el)}
                  className={`w-4 h-4  text-center text-[#F15A24] text-[24px] font-bold rounded-full ${digit ? "bg-[#F15A24]" : "bg-[#F7F7F7]"} focus:outline-none`}
                />
              ))}
            </div>

            <div className="flex flex-col">
              <button className={`p-3 rounded-full mb-2 ${isButtonEnabled ? "bg-orange-600 text-white" : "bg-[#F3F4F8] text-[#BFBFBF] cursor-not-allowed"}`} disabled={!isButtonEnabled} onClick={handleSubmit}>
                {isConfirmingPin ? "Simpan PIN" : "Lanjut"}
              </button>
              <button className="text-orange-600 mt-1 rounded-full border border-orange-600 p-3" onClick={handleModalClose}>
                Batal
              </button>
              <div className="text-red-600 text-sm"> {errormessage}</div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default AturPin;
