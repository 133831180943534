import React from "react";
import "./style.css";
// import axios from "axios";

const AccsesKeyIndividu = () => {
  return (
    <section>
      <div className="border border-[#DDE5E9] rounded-2xl   w-full px-10 py-[25px] contentacsesI ">
        <h1 className="text-xl font-bold text-[#1A1A1A] text-start">Acces Key </h1>

        <div className="flex flex-col gap-5 text-start mt-7 blur-sm">
          <div className="flex gap-20 justify-start">
            <p className="w-28 font-semibold">ID Merchant</p>
            <p>:</p>
            <p>UDID-66bdaf9728b7c03b62c50eb2</p>
          </div>

          <div className="flex  gap-20 ">
            <p className="w-28 font-semibold">Server Key</p>
            <p>:</p>
            <p>Masih dalam proses</p>
          </div>

          <div className="flex  gap-20">
            <p className="w-28 font-semibold">Cabang Bank</p>
            <p>:</p>
            <p>Masih dalam proses</p>
          </div>
        </div>

        <button className="bg-orange-600 px-[20px] py-[10px] flex justify-start mt-10 rounded-full text-white">Upgrade Ke Perusahaan</button>
      </div>
    </section>
  );
};

export default AccsesKeyIndividu;
