import React from "react";
import People from "../../../../assets/images/people.png";
import Work from "../../../../assets/images/work.png";
import Card from "../../../../assets/images/card.png";
import { tseru2, arrowbotton2, arrowbotton } from "../../../../assets/icon";

const CaraKerja = () => {
  return (
    <section className="mt-24 mb-12">
      <div className="flex flex-col gap-6 justify-center font-bold">
        <h1 className="text-[48px]">
          Bagaimana <span className="bg-gradient-to-r from-[#EF2725]  via-[#EF2725] to-[#F26023] inline-block text-transparent bg-clip-text">Cara</span> Kami{" "}
          <span className="bg-gradient-to-r from-[#EF2725]  via-[#EF2725] to-[#F26023] inline-block text-transparent bg-clip-text">Bekerja</span>
          <p className="text-[20px] mt-3 font-normal">Kami mempunyai beberapa cara kerja</p>
        </h1>
      </div>

      {/* CONTENT 1 */}
      <div className="flex justify-between mt-12 ms-3">
        <div className="w-[546px]  text-start ">
          <div className="ms-[122px] -mb-12">{tseru2()}</div>
          <div className="flex gap-6 items-center">
            <div className="w-1 h-36  border  bg-[#FBCCBB] "></div>
            <h1 className="text-[128px] bg-gradient-to-r from-[#EF2725]  via-[#EF2725] to-[#F26023] inline-block text-transparent bg-clip-text relative">01</h1>
            {/* <div className="absolute left-44 top-[2440px] ">{tseru2()}</div> */}
          </div>

          <div className="ms-7">
            <h2 className="text-[32px] font-bold">Registrasi & Verifikasi</h2>
            <p>
              Mulailah dengan mendaftarkan akun bisnis Anda di platform kami. Proses ini melibatkan pengisian formulir pendaftaran dengan informasi bisnis Anda, seperti nama bisnis, alamat, dan rincian kontak. Setelah itu, lengkapi proses
              verifikasi identitas yang mungkin memerlukan dokumen seperti KTP, NPWP, dan dokumen legal lainnya
            </p>
          </div>
        </div>
        <div className="mt-[420px]">{arrowbotton()}</div>

        <div className="ms-4 relative">
          <img className="ms-4" src={People} alt="" />
        </div>
      </div>

      {/* CONTENT 2 */}
      <div className="flex justify-between mt-20 ms-3">
        <div className="ms-4 relative">
          <img className="ms-4" src={Work} alt="" />
        </div>

        <div className="mt-[420px]">{arrowbotton2()}</div>

        <div className="w-[546px]  text-start ">
          <div className="ms-[122px] -mb-12">{tseru2()}</div>
          <div className="flex gap-6 items-center">
            <div className="w-1 h-36  border  bg-[#FBCCBB] "></div>
            <h1 className="text-[128px] bg-gradient-to-r from-[#EF2725]  via-[#EF2725] to-[#F26023] inline-block text-transparent bg-clip-text relative">02</h1>
            {/* <div className="absolute right-[400px] top-[3080px] ">{tseru2()}</div> */}
          </div>

          <div className="ms-7">
            <h2 className="text-[32px] font-bold">Integrasi & Konfigurasi</h2>
            <p>
              Setelah akun Anda diverifikasi, langkah selanjutnya adalah mengintegrasikan payment gateway kami dengan website atau aplikasi Anda. Kami menyediakan API yang mudah digunakan. Anda dapat mengikuti dokumentasi kami untuk
              langkah-langkah integrasi atau meminta bantuan dari tim dukungan teknis kami.
            </p>
          </div>
        </div>
      </div>

      {/* CONTENT 3 */}
      <div className="flex justify-between  ms-3">
        <div className="w-[546px]  text-start ">
          <div className="ms-[122px] -mb-12">{tseru2()}</div>
          <div className="flex gap-6 items-center">
            <div className="w-1 h-36  border  bg-[#FBCCBB] "></div>
            <h1 className="text-[128px] bg-gradient-to-r from-[#EF2725]  via-[#EF2725] to-[#F26023] inline-block text-transparent bg-clip-text relative">03</h1>
            {/* <div className="absolute left-44 top-[3620px] ">{tseru2()}</div> */}
          </div>

          <div className="ms-7">
            <h2 className="text-[32px] font-bold">Mulai Terima Pembayaran</h2>
            <p>
              Setelah integrasi selesai, Anda siap menerima pembayaran dari pelanggan. Kami mendukung berbagai metode pembayaran seperti kartu kredit/debit, e-wallet, transfer bank, dan pembayaran tunai di gerai. Transaksi yang terjadi akan
              diproses secara aman dan cepat, dan Anda dapat memantau semua aktivitas transaksi melalui dashboard real-time kami.
            </p>
          </div>
        </div>
        {/* <div className="mt-[420px]">{arrowbotton()}</div> */}

        <div className="ms-4 relative">
          <img className="ms-4" src={Card} alt="" />
        </div>
      </div>
    </section>
  );
};

export default CaraKerja;
