import React from "react";
import { Doughnut } from "react-chartjs-2";
import { RootState } from "../../../../features/stores";
import { useSelector } from "react-redux";

const TransactionUdin: React.FC = () => {
  const { data } = useSelector((state: RootState) => state.transactions);

  const getData = (type_percentage: number = 0) => {
    return {
      datasets: [
        {
          data: [type_percentage, 100 - type_percentage],
          backgroundColor: ["#F15A24", "#D9D9D9"],
          borderWidth: 0,
        },
      ],
    };
  };

  return (
    <div className=" ">
      <div className=" items-center text-start rounded-2xl shadow-[2px_2px_20px_10px_rgba(0,0,0,0.05)] border border-[#DDE5E9] px-6 py-5">
        <div>
          <h3 className="text-xl ">Transaksi Udin</h3>
        </div>
        <div className="flex justify-center w-full lg:mt-[-15px]">
          <div className="relative h-[160px] w-[160px] ">
            <Doughnut
              data={getData(data?.persentaseUDIN.type_percentage)}
              options={{
                cutout: "70%",
                plugins: {
                  tooltip: { enabled: false },
                },
              }}
            />
            <span className="text-sm font-bold text-[#F15A24] absolute inset-0 lg:mt-[12px] flex items-center justify-center">{data?.persentaseUDIN.type_percentage}%</span>
          </div>
        </div>

        <div>
          <h1 className="text-xl font-bold flex justify-center w-full lg:mt-[30px]">Udin</h1>
          <div className="flex justify-center w-full gap-[10px] mt-[15px]">
            <div>
              <div className="flex gap-[10px]">
                <p className="w-[30px] h-[24px] bg-[#F15A24] rounded-full" />
                <h1 className="text-[16px] text-[#555770] font-inter flex  w-full">
                  {data?.persentaseUDIN.type} ( {data?.persentaseUDIN.type_percentage}% )
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionUdin;
