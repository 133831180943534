import React, { useState, useEffect, useRef } from "react";
import { logoudin } from "../../../../assets/icon";
import { Link, useLocation } from "react-router-dom";

interface NavLink {
  name: string;
  path: string;
  id: string;
}

const navLinks: NavLink[] = [
  { name: "Beranda", path: "/", id: "beranda" },
  { name: "Fitur Unggulan", path: "/fitur-unggulan", id: "fitur-unggulan" },
  { name: "Tentang Kami", path: "/tentang-kami", id: "tentang-kami" },
  { name: "Payment Gateway", path: "/payment-gateway", id: "payment-gateway" },
  { name: "Kontak", path: "/contact", id: "contact" },
];

const NavbarPG: React.FC = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const observer = useRef<IntersectionObserver | null>(null);

  const handleScroll = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setActiveLink(`#${id}`);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    };

    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveLink(`#${entry.target.id}`);
        }
      });
    }, options);

    navLinks.forEach((link) => {
      const element = document.getElementById(link.id);
      if (element) {
        observer.current?.observe(element);
      }
    });

    return () => {
      observer.current?.disconnect();
    };
  }, []);

  return (
    <nav className="fixed top-0 left-0 w-full flex items-center justify-between px-10 py-4 shadow-sm bg-white z-50 ">
      <div className="flex items-center">{logoudin()}</div>
      <div className="flex items-center space-x-12">
        {navLinks.map((link) => (
          <span
            key={link.name}
            className={`${activeLink === `#${link.id}` ? "text-[#EF2725]" : "text-black"} font-semibold hover:text-[#EF2725] cursor-pointer`}
            onClick={() => {
              setActiveLink(`#${link.id}`);
              handleScroll(link.id);
            }}>
            {link.name}
          </span>
        ))}
      </div>
      <div className="flex items-center space-x-6">
        <Link to="login" className="text-[#EF2725] font-semibold hover:text-black">
          Masuk
        </Link>
        <Link to="register" className="bg-black text-sm border-[3px] border-[#E9EAEA] text-white px-[50px] py-2 rounded-full hover:bg-gray-800">
          Daftar
        </Link>
      </div>
    </nav>
  );
};

export default NavbarPG;
