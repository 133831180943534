import React, { useState, useEffect } from "react";
import axios from "axios";
import "./style.css";
import { trash } from "../../assets/icon";

interface WhitelistItem {
  _id: string;
  ip_address: string;
  status: string;
}

const IpWhitelist = () => {
  const [ipAddress, setIpAddress] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [whitelist, setWhitelist] = useState<WhitelistItem[]>([]);
  const [publicIp, setPublicIp] = useState<string>("");

  useEffect(() => {
    fetchWhitelist();
    fetchPublicIp(); // Fetch the public IP address when the component mounts
  }, []);

  const fetchPublicIp = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      setPublicIp(response.data.ip);
    } catch (error) {
      console.error("Error fetching public IP address:", error);
      setPublicIp("IP tidak ditemukan");
    }
  };

  const handleIpAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    // Validasi: Hanya mengizinkan angka dan titik, dan maksimal 15 karakter
    const regex = /^[0-9.]*$/;
    if (regex.test(value) && value.length <= 15) {
      setIpAddress(value);
    }
  };

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage("");
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const handleAddIpWhitelist = () => {
    const data = { ip_address: ipAddress };
    const token = localStorage.getItem("token");

    axios
      .post(`${process.env.REACT_APP_API_URL}create-ip-whitelist`, data, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        if (response.data.status === "success") {
          setMessage(response.data.message);
          setIpAddress(""); // Reset input field

          // Tambahkan data baru ke whitelist tanpa refresh
          const newWhitelistItem = response.data.data;
          setWhitelist((prevWhitelist) => [...prevWhitelist, newWhitelistItem]);
        }
      })
      .catch((error) => {
        console.error("Error adding IP whitelist:", error);
        setMessage("Terjadi kesalahan saat menambahkan IP Whitelist.");
      });
  };

  const fetchWhitelist = () => {
    const token = localStorage.getItem("token");

    axios
      .get(`${process.env.REACT_APP_API_URL}get-ip-whitelists`, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        if (response.data.status === "success") {
          setWhitelist(response.data.data); // Set whitelist data
        }
      })
      .catch((error) => {
        console.error("Error fetching IP whitelist:", error);
      });
  };

  const handleToggleStatus = (id: string) => {
    const updatedStatus = whitelist.find((item) => item._id === id)?.status === "Aktif" ? "Diblokir" : "Aktif";
    const token = localStorage.getItem("token");

    axios
      .put(`${process.env.REACT_APP_API_URL}block-ip/${id}`, { status: updatedStatus }, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        if (response.data.status === "success") {
          setMessage("Status IP berhasil diperbarui.");
          // Update status locally
          setWhitelist(whitelist.map((item) => (item._id === id ? { ...item, status: updatedStatus } : item)));
        } else {
          setMessage("Terjadi kesalahan saat memperbarui status IP.");
        }
      })
      .catch((error) => {
        console.error("Error updating IP status:", error);
        setMessage("Terjadi kesalahan saat memperbarui status IP.");
      });
  };

  const handleDeleteIpWhitelist = (id: string) => {
    const token = localStorage.getItem("token");

    axios
      .delete(`${process.env.REACT_APP_API_URL}delete-ip/${id}`, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        if (response.data.status === "success") {
          setMessage("IP Whitelist berhasil dihapus.");
          setWhitelist(whitelist.filter((item) => item._id !== id));
        }
      })
      .catch((error) => {
        console.error("Error deleting IP whitelist:", error);
        setMessage("Terjadi kesalahan saat menghapus IP Whitelist.");
      });
  };

  return (
    <section>
      {message && <p className="text-orange-600 text-center p-4 bg-[#FEEFE9] w-[1010px] -mt-9  -ms-12 mb-3">{message}</p>}
      <div className="border border-[#DDE5E9] rounded-2xl contentIp">
        <div className="w-full px-6 py-[30px]">
          <div className="w-[583px]">
            <h1 className="text-xl font-bold text-[#1A1A1A] text-start">IP Anda {publicIp}</h1>
            <h1 className="text-xl font-bold text-[#1A1A1A] text-start mt-3">IP Whitelist</h1>
            <p className="text-[#1A1A1A] text-sm text-start mt-3">
              Amankan akses Anda dengan fitur IP Whitelist. Tentukan alamat IP yang diizinkan untuk mengakses akun atau melakukan transaksi, memastikan hanya perangkat tepercaya yang bisa masuk. Mudah diatur, memberikan perlindungan ekstra
              untuk bisnis Anda.
            </p>
          </div>

          <div className="flex gap-3 items-end mt-4 mb-2">
            <div className="text-start flex flex-col gap-2 w-full">
              <label htmlFor="ip-whitelist" className="ms-1 mb-1">
                Ip Whitelist
              </label>
              <input id="ip-whitelist" type="text" className="w-full bg-[#F0F0F0] p-4 placeholder:text-[#BABCBF] rounded-2xl" placeholder="Masukkan IP Whitelist Anda" value={ipAddress} onChange={handleIpAddressChange} />
            </div>

            <button className={`w-[300px] rounded-full h-12 text-[16px] text-white -mt-3 ${ipAddress ? "bg-orange-600 cursor-pointer" : "bg-[#E0E0E0] cursor-not-allowed"}`} onClick={handleAddIpWhitelist} disabled={!ipAddress}>
              Tambahkan Ip Whitelist
            </button>
          </div>
        </div>
      </div>

      {/* Tabel Whitelist */}
      <div className=" border shadow-[2px_2px_20px_10px_rgba(0,0,0,0.04)] border-[#DDE5E9] rounded-t-2xl min-w-full mt-6  mb-4 ">
        <table className="  min-w-full">
          <thead>
            <tr>
              <th className="px-6 py-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wide">No</th>
              <th className="px-6 py-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wide">IP</th>
              {/* <th className="px-6 py-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Project</th> */}
              <th className="px-6 py-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              <th className="px-6 py-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Aksi</th>
            </tr>
          </thead>
          <tbody>
            {whitelist.map((item, index) =>
              item && item.ip_address ? (
                <tr className="border" key={item._id}>
                  <td>{index + 1}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{item.ip_address}</td>
                  {/* <td>Project 1</td> */}
                  <td className={`whitespace-nowrap ${item.status === "Aktif" ? "text-green-500" : "text-red-500"}`}>
                    <span className={`px-4 inline-flex text-xs leading-5 font-semibold rounded-full ${item.status === "Aktif" ? "bg-[#DEFDE1] text-[#7EF124]" : "bg-[#FDDEDE] text-[#F12424]"}`}>{item.status}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <button className={`border-b ${item.status === "Aktif" ? "text-[#F12424] border-b border-[#F12424]" : "text-[#7EF124] border-b border-[#7EF124]"}`} onClick={() => handleToggleStatus(item._id)}>
                      {item.status === "Aktif" ? "Blokir" : "Buka Blokir"}
                    </button>
                  </td>
                  <td className="px-6">
                    <div className="cursor-pointer" onClick={() => handleDeleteIpWhitelist(item._id)}>
                      {trash()}
                    </div>
                  </td>
                </tr>
              ) : null
            )}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default IpWhitelist;
