import React, { useEffect, useState } from "react";
import { AppDispatch, RootState } from "../../../../../features/stores";
import { useDispatch, useSelector } from "react-redux";
import FilterData from "./filteredData";
import TransactionTable from "./tableData";
import Pagination from "./paginationData";
import { fetchTransactionHistory } from "../../../../../features/reducers/transactinHistorySlice";

const Transaksi: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const { data } = useSelector((state: RootState) => state.transactionHistory);
  const { merchantData } = useSelector((state: RootState) => state.merchant);
  const { selectedTipePembayaran, selectedStatus, startDate, endDate } = useSelector((state: RootState) => state.filterTransaksi);

  const token = localStorage.getItem("token");
  const merchantId = merchantData && merchantData.length > 0 ? merchantData[0].merchant_id : null;

  useEffect(() => {
    if (merchantId && token) {
      dispatch(fetchTransactionHistory({ merchantId, token }));
    }
  }, [dispatch, merchantId, token, selectedTipePembayaran, selectedStatus, startDate, endDate]);

  const filteredDataValue = (data || []).filter((item) => {
    const matchTipePembayaran = selectedTipePembayaran.length === 0 || selectedTipePembayaran.includes(item.tipe_pembayaran);
    const matchStatus = selectedStatus.length === 0 || selectedStatus.includes(item.status);
    const transactionDate = new Date(item.tanggal_transaksi);
    const start = startDate ? new Date(startDate) : undefined;
    const end = endDate ? new Date(endDate) : undefined;

    const dateInRange = (!start && !end) || (start && end && transactionDate >= start && transactionDate <= end) || (start && !end && transactionDate >= start) || (!start && end && transactionDate <= end);

    return matchTipePembayaran && matchStatus && dateInRange;
  });

  const totalPages = Math.ceil(filteredDataValue.length / itemsPerPage);
  const currentItems = filteredDataValue.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <section className="border w-full rounded-lg shadow-md  border-slate-100 ">
      <FilterData setCurrentPage={setCurrentPage} />
      <TransactionTable currentItems={currentItems} />
      <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={setCurrentPage} />
    </section>
  );
};

export default Transaksi;
