import React from "react";
import { arrow2, arrowright } from "../../../../assets/icon";
import { Link } from "react-router-dom";

const features = [
  {
    title: "Integrasi Mudah",
    description: "Integrasikan payment gateway kami dengan berbagai platform e-commerce dengan cepat dan tanpa kendala.",
  },
  {
    title: "Keamanan Tinggi",
    description: "Kami menggunakan teknologi enkripsi terbaru untuk memastikan setiap transaksi terlindungi dari ancaman cyber.",
  },
  {
    title: "Dukungan Multi-Bank",
    description: "Terima pembayaran dalam berbagai mata uang untuk menjangkau pelanggan internasional dengan mudah.",
  },
  {
    title: "Laporan Real-Time",
    description: "Pantau transaksi dan kinerja bisnis Anda secara real-time dengan dashboard analitik kami yang komprehensif.",
  },
  {
    title: "Otomatis Pembayaran",
    description: "Sistem otomatisasi pembayaran kami memungkinkan Anda untuk mengatur transaksi berulang dengan mudah.",
  },
  {
    title: "Fraud Detection",
    description: "Keamanan transaksi Anda adalah prioritas kami. Fitur deteksi mencegah aktivitas mencurigakan secara real-time.",
  },
  {
    title: "Dukungan Pelanggan 24/7",
    description: "Kami menyediakan layanan dukungan pelanggan 24 jam , 7 hari seminggu. Tim kami selalu siap membantu Anda.",
  },
  {
    title: "Kustomisasi Branding",
    description: "Pantau transaksi dan kinerja bisnis Anda secara real-time dengan dashboard analitik kami yang komprehensif.",
  },
];

const FiturUnggulan = () => {
  return (
    <section id="fitur-unggulan" className="mt-24 ">
      <div className="flex justify-between items-center ">
        <div className="flex text-[45px] bg-gradient-to-r from-[#EF2725]  via-[#EF2725] to-[#F26023] text-transparent bg-clip-text ms-6 font-bold ">
          Fitur <span className="text-black">Unggulan</span> <div className="-mt-2 -ms-5">{arrow2()}</div>
        </div>
        <p className="text-xl">Kami Mempunyai beberapa fitur unggulan di payment gateway</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 ms-3 text-start mb-4 mt-5 justify-around ">
        {features.map((feature, index) => (
          <FeatureCard key={index} title={feature.title} description={feature.description} />
        ))}
      </div>
    </section>
  );
};

const FeatureCard: React.FC<{ title: string; description: string }> = ({ title, description }) => {
  return (
    <div className="bg-white p-3 rounded-2xl shadow-[2px_2px_20px_10px_rgba(0,0,0,0.05)] border border-[#DDE5E9] ">
      <h3 className="text-[20px] font-bold mb-2 text-[#EF2725]">{title}</h3>
      <p className="text-gray-600 mb-4 text-sm">{description}</p>
      <div className="flex items-end">
        <Link to="#" className="text-black text-[16px] hover:underline inline-flex items-center">
          Lihat lebih detail
        </Link>

        <div className="">{arrowright()}</div>
      </div>
    </div>
  );
};

export default FiturUnggulan;
