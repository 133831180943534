import React, { useState, useEffect, useCallback } from "react";
// import { Link } from "react-router-dom";
import axios from "axios";

interface Bank {
  _id: string;
  namaBank: string;
}

const Modal: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true); // Ubah sesuai kebutuhan
  const [nama_pemilik_rekening, setnama_pemilik_rekening] = useState("");
  const [bank, setBank] = useState<Bank | null>(null);
  const [nomor_rekening, setnomor_rekening] = useState("");
  const [cabang_bank, setcabang_bank] = useState("");
  const [alamat_bank, setalamat_bank] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [banks, setBanks] = useState<Bank[]>([]);

  //   const nama_perusahaan = "Nama Perusahaan"; // Ubah sesuai kebutuhan
  const title = "Tambah Nomor Rekening"; // Ubah sesuai kebutuhan

  useEffect(() => {
    if (isOpen) {
      axios
        .get(`${process.env.REACT_APP_API_URL}all-bank-terdaftar`)
        .then((response) => {
          setBanks(response.data.bank_terdaftar); // Menyimpan data bank dalam state banks
        })
        .catch((error) => {
          alert("Gagal Mengambil Data ID");
          console.error("Error fetching bank data:", error);
        });
    }
  }, [isOpen]);

  const validateForm = useCallback(() => {
    if (nama_pemilik_rekening && bank && nomor_rekening && cabang_bank && alamat_bank) {
      if (bank.namaBank === "Mandiri" && nomor_rekening.length !== 13) {
        return false;
      }

      if (bank.namaBank === "BRI" && nomor_rekening.length !== 15) {
        return false;
      }

      if (bank.namaBank === "BCA" && nomor_rekening.length !== 10) {
        return false;
      }

      if (bank.namaBank === "BNI" && nomor_rekening.length !== 10) {
        return false;
      }

      //   if (nama_pemilik_rekening !== nama_perusahaan) {
      //     return false;
      //   }
      return true;
    }
    return false;
  }, [nama_pemilik_rekening, bank, nomor_rekening, cabang_bank, alamat_bank]);

  useEffect(() => {
    setIsFormValid(validateForm());
  }, [nama_pemilik_rekening, bank, nomor_rekening, cabang_bank, alamat_bank, validateForm]);

  const handleSave = () => {
    if (isFormValid && bank) {
      const savedData = {
        nama_pemilik_rekening,
        bank_id: bank._id, // Menggunakan ID bank yang dipilih
        nomor_rekening,
        cabang_bank,
        alamat_bank,
      };

      const token = localStorage.getItem("token"); // Ganti dengan token autentikasi Anda
      axios
        .put(`${process.env.REACT_APP_API_URL}edit-rekening-data`, savedData, {
          headers: {
            "Content-Type": "application/json", // Atur content type
            Authorization: `Bearer ${token}`, // Sertakan token dalam header
          },
        })
        .then((response) => {
          console.log("Data berhasil disimpan:", response.data);
          // Tutup modal setelah menyimpan
          window.location.href = "/me/saldo";
        })
        .catch((error) => {
          console.error("Gagal menyimpan data:", error);
          alert("Gagal menyimpan data, silakan coba lagi.");
          setIsOpen(false);
        });
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 text-sm z-50">
      <div className="bg-white rounded-2xl px-6 py-4 w-[400px] ms-[265px] shadow-lg text-start">
        <div className="flex justify-between items-center">
          <h2 className="text-lg text-start font-bold mb-4">{title}</h2>

          {/* <div className="font-bold text-lg cursor-pointer -mt-3">
            <Link to="/me/saldo">x</Link>
          </div> */}
        </div>

        <form className="text-[13px]">
          <div>
            <label htmlFor="pemilikRekening" className="font-semibold">
              Nama Pemilik Rekening <span className="text-red-600">*</span>
            </label>
            <input
              id="pemilikRekening"
              type="text"
              className="w-full mb-4 p-2 border border-[#DDE5E9] placeholder:text-[#DDE5E9] rounded-lg mt-1"
              placeholder="Masukkan Nama Pemilik Rekening"
              value={nama_pemilik_rekening}
              onChange={(e) => setnama_pemilik_rekening(e.target.value)}
            />
          </div>

          <div>
            <label htmlFor="bank" className="font-semibold">
              Bank <span className="text-red-600">*</span>
            </label>
            <select
              id="bank"
              className="w-full mb-4 p-2 border border-[#DDE5E9] rounded-lg mt-1 "
              value={bank?._id || ""}
              onChange={(e) => {
                const selectedBank = banks.find((bank) => bank._id === e.target.value);
                setBank(selectedBank || null);
              }}>
              <option value="">Pilih Bank</option>
              {banks.map((bank) => (
                <option key={bank._id} value={bank._id}>
                  {bank.namaBank}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="nomorRekening" className="font-semibold">
              Nomor Rekening <span className="text-red-600">*</span>
            </label>
            <input
              id="nomorRekening"
              type="number"
              className="w-full mb-4 p-2 border border-[#DDE5E9] placeholder:text-[#DDE5E9] rounded-lg mt-1"
              placeholder="Masukkan Nomor Rekening"
              value={nomor_rekening}
              onChange={(e) => setnomor_rekening(e.target.value)}
            />
          </div>

          <div>
            <label htmlFor="cabangBank" className="font-semibold">
              Cabang Bank <span className="text-red-600">*</span>
            </label>
            <input
              id="cabangBank"
              type="text"
              className="w-full mb-4 p-2 border border-[#DDE5E9] placeholder:text-[#DDE5E9] rounded-lg mt-1"
              placeholder="Masukkan Nama Cabang Bank"
              value={cabang_bank}
              onChange={(e) => setcabang_bank(e.target.value)}
            />
          </div>

          <div>
            <label htmlFor="alamatBank" className="font-semibold">
              Alamat Bank <span className="text-red-600">*</span>
            </label>
            <textarea id="alamatBank" className="w-full mb-4 p-2 border border-[#DDE5E9] placeholder:text-[#DDE5E9] rounded-lg mt-1" placeholder="Masukkan Alamat Bank" value={alamat_bank} onChange={(e) => setalamat_bank(e.target.value)} />
          </div>
        </form>

        <div className="flex justify-end w-full mt-2">
          <button className={`bg-orange-600 text-white p-2 rounded-full w-full ${!isFormValid && "cursor-not-allowed bg-slate-200 "}`} onClick={handleSave} disabled={!isFormValid}>
            Simpan
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
