import React from "react";

const TransaksiProyekSandbox = () => {
  return (
    <div>
      <div>Transaksi Proyek Sandbox</div>
    </div>
  );
};

export default TransaksiProyekSandbox;
