import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../features/stores";

import {
  logoudinmenu,
  berandaWhite,
  berandaOrange,
  transaksiwhite,
  transaksiOrange,
  transaksigray,
  saldowhite,
  saldoOrange,
  saldogray,
  akunwhite,
  akunOrange,
  logoutwhite,
  logoutOrange,
  dataOrange,
  datawhite,
  arrowdownwhite,
  arrowdownorange,
  proyekorange,
  proyekwhite,
  proyekgray,
} from "../../assets/icon";
import axios from "axios";

const Sidebar: React.FC = () => {
  const mode = useSelector((state: RootState) => state.mode.mode);
  const location = useLocation();
  const navigate = useNavigate();
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isTransaksiOpen, setisTransaksiOpen] = useState(false);
  const [isAktif, setIsAktif] = useState<Boolean>(true);
  const token = localStorage.getItem("token");
  const [legalitas, setLegalitas] = useState<string | null>(null);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [navigate, token]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}get-profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const companyData = response.data.users;

        if (companyData.is_aktif === "Verifikasi") {
          setIsAktif(true);
        } else {
          setIsAktif(false);
        }

        setLegalitas(companyData.legalitas);
      })
      .catch((error) => {
        console.error("Gagal mengambil data pengguna:", error);
      });
  }, [token]);

  // Arahkan berdasarkan mode saat komponen pertama kali dimuat
  useEffect(() => {
    // Get the current path from the location object
    const currentPath = location.pathname;

    const storedMode = localStorage.getItem("app_mode") || mode;
    let targetPath = currentPath;

    // Paths that should not get "-sandbox"
    const excludedPaths = ["/me/proyek", "/me/data", "/me/pin", "/me/profile", "/me/logout", "/me/summary-perusahaan", "/me/summary-individu"];

    // Check if the current path is excluded from "-sandbox" logic
    const isExcludedPath = excludedPaths.some((path) => currentPath.startsWith(path));

    if (!isExcludedPath) {
      // Only modify the path if it's not one of the excluded paths
      if (storedMode === "production" && currentPath.includes("-sandbox")) {
        targetPath = currentPath.replace("-sandbox", "");
      } else if (storedMode === "sandbox" && !currentPath.includes("-sandbox")) {
        targetPath = `${currentPath}-sandbox`;
      }
    }

    // Navigate to the target path if it's different from the current path
    if (targetPath !== currentPath) {
      navigate(targetPath);
    }
  }, [mode, navigate, location]);

  const getActiveClass = (path: string): string => {
    // Check if the mode is sandbox, keep text white regardless of active state
    if (mode === "sandbox") {
      return location.pathname.startsWith(path) ? "bg-[#E9AA991A] text-white" : "text-white hover:bg-[#E9AA991A]";
    }
    return location.pathname.startsWith(path) ? "bg-[#E9AA991A] text-[#F15A24]" : "text-white hover:bg-[#E9AA991A]";
  };

  const getActiveClassTwo = (path: string): string => {
    // Check if the mode is sandbox, keep text white regardless of active state
    if (mode === "sandbox") {
      return location.pathname.startsWith(path) ? "text-white bg-[#E9AA991A]" : "text-white";
    }
    return location.pathname.startsWith(path) ? "text-[#F15A24] bg-[#E9AA991A]" : "text-white";
  };

  const getDisabledClass = (): string => {
    return "text-[#ffffff30] cursor-not-allowed";
  };

  const getIcon = (path: string, activeIcon: () => JSX.Element, inactiveIcon: () => JSX.Element, disabledIcon: () => JSX.Element): JSX.Element => {
    if (!isAktif) {
      return disabledIcon();
    }
    if (mode === "sandbox") {
      return inactiveIcon(); // Pastikan ikon tetap putih dalam mode sandbox
    }
    if (!isAktif) {
      return disabledIcon();
    }
    return location.pathname === path ? activeIcon() : inactiveIcon();
  };

  const getIconTwo = (path: string, activeIcon: () => JSX.Element, inactiveIcon: () => JSX.Element): JSX.Element => {
    if (mode === "sandbox") {
      return inactiveIcon(); // Pastikan ikon tetap putih dalam mode sandbox
    }
    return location.pathname === path ? activeIcon() : inactiveIcon();
  };

  const getLinkClass = (path: string): string => {
    return isAktif ? getActiveClass(path) : getDisabledClass();
  };

  const getLinkClassTwo = (path: string): string => {
    return isAktif ? getActiveClassTwo(path) : getDisabledClass();
  };

  return (
    <div className={`flex items-start font-inter ${mode === "sandbox" ? "bg-[#D95120]" : "bg-[#1A1A1A]"} text-white w-[275px] h-full p-4 fixed overflow-auto`}>
      <div className=" ">
        <div>
          <div className="mb-14">
            <h1 className={`text-[14px] font-bold flex gap-5 items-center justify-center  ${mode === "sandbox" ? "text-white" : "text-white"}`}>
              <div className="bg-[#ffffff15] px-4 py-3 rounded-full">{logoudinmenu()}</div>
              Ultra Digital Indonesia
            </h1>
          </div>

          <div className="text-start mb-3 ms-5 font-semibold">Menu</div>
          <ul className="ms-5 me-2 ">
            <li className="mb-4">
              <li className="mb-4">
                <Link to={mode === "production" ? "/me/beranda" : "/me/beranda-sandbox"} className={`flex items-center p-2 text-sm rounded ${getActiveClass("/me/beranda")}`}>
                  <span className="icon-menu mr-3">{getIconTwo("/me/beranda", berandaOrange, berandaWhite)}</span>
                  Beranda
                </Link>
              </li>
            </li>
            {legalitas === "individu" ? (
              <li className="mb-4">
                <Link to={isAktif ? (mode === "production" ? "/me/transaksi" : "/me/transaksi-sandbox") : "#"} className={`flex items-center p-2 text-sm rounded ${getLinkClass("/me/transaksi")}`}>
                  <span className="icon-menu mr-3">{getIcon("/me/transaksi", transaksiOrange, transaksiwhite, transaksigray)}</span>
                  Transaksi
                </Link>
              </li>
            ) : (
              <li className="mb-4 relative">
                <button onClick={() => setisTransaksiOpen(!isTransaksiOpen)} className={`flex items-center p-2 text-sm rounded w-full ${getActiveClass("/me/transaksi")}`}>
                  <span className="icon-menu mr-3">{getIconTwo("/me/transaksi", transaksiOrange, transaksiwhite)}</span>
                  Transaksi
                  <span className={`ml-auto transform ${isTransaksiOpen ? "rotate-180" : "rotate-0"}`}>{getIconTwo("/me/transaksi", arrowdownorange, arrowdownwhite)}</span>
                </button>
                {isTransaksiOpen && (
                  <div className={`left-0 mt-2 ${mode === "sandbox" ? "bg-[#D95120]" : "bg-[#1A1A1A]"} text-white rounded-md w-full`}>
                    <ul className="text-start">
                      <li>
                        <Link to={isAktif ? (mode === "production" ? "/me/transaksi" : "/me/transaksi-sandbox") : "#"} className={`block px-4 py-2 text-sm ps-10 hover:bg-[#E9AA991A] rounded-t-md ${getLinkClassTwo("/me/transaksi")}`}>
                          Perusahaan
                        </Link>
                      </li>
                      <li>
                        <Link to={isAktif ? (mode === "production" ? "/me/transpro" : "/me/transpro-sandbox") : "#"} className={`block px-4 py-2 text-sm ps-10 hover:bg-[#E9AA991A] rounded-b-md ${getLinkClassTwo("/me/transpro")}`}>
                          Proyek
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </li>
            )}

            <li className="mb-4">
              <Link to={isAktif ? (mode === "production" ? "/me/saldo" : "/me/saldo-sandbox") : "#"} className={`flex items-center p-2 text-sm rounded ${getLinkClass("/me/saldo")}`}>
                <span className="icon-menu mr-3">{getIcon("/me/saldo", saldoOrange, saldowhite, saldogray)}</span>
                Saldo
              </Link>
            </li>
            {legalitas !== "individu" && (
              <li className="mb-4">
                <Link to={isAktif ? (mode === "production" ? "/me/proyek" : "/me/proyek") : "#"} className={`flex items-center p-2 text-sm rounded ${getLinkClass("/me/proyek")}`}>
                  <span className="icon-menu mr-3">{getIcon("/me/proyek", proyekorange, proyekwhite, proyekgray)}</span>
                  Proyek
                </Link>
              </li>
            )}

            <li className="mb-4">
              <Link to="/me/data" className={`flex items-center p-2 text-sm rounded ${getActiveClassTwo("/me/data")}`}>
                <span className="icon-menu mr-3">{getIconTwo("/me/data", dataOrange, datawhite)}</span>
                Data
              </Link>
            </li>
          </ul>
        </div>

        <div>
          <p className="text-start mb-3 ms-4 font-semibold text-base">Pengaturan</p>
          <ul className="ms-5">
            <li className="mb-4 relative">
              <button onClick={() => setIsProfileOpen(!isProfileOpen)} className={`flex items-center p-2 text-sm rounded w-full ${getActiveClass("/me/profile")}`}>
                <span className="icon-menu mr-3">{getIconTwo("/me/profile", akunOrange, akunwhite)}</span>
                Akun
                <span className={`ml-auto transform ${isProfileOpen ? "rotate-180" : "rotate-0"}`}>{getIconTwo("/me/profile", arrowdownorange, arrowdownwhite)}</span>
              </button>
              {isProfileOpen && (
                <div className={`left-0 mt-2  ${mode === "sandbox" ? "bg-[#D95120]" : "bg-[#1A1A1A]"} text-white rounded-md w-full`}>
                  <ul className="text-start">
                    <li>
                      <Link to="/me/profile" className={`block px-4 py-2 text-sm ps-10 hover:bg-[#E9AA991A] rounded-t-md ${getActiveClassTwo("/me/profile")}`}>
                        Profile
                      </Link>
                    </li>
                    <li>
                      <Link to={isAktif ? "/me/ip-whitlist" : "#"} className={`block px-4 py-2 text-sm ps-10 hover:bg-[#E9AA991A] rounded-b-md  ${getLinkClassTwo("/me/ip-whitlist")}`}>
                        IP Whitelist
                      </Link>
                    </li>
                    <li>
                      <Link to="/me/pin" className={`block px-4 py-2 text-sm ps-10 hover:bg-[#E9AA991A] rounded-b-md ${getActiveClassTwo("/me/pin")}`}>
                        Atur PIN
                      </Link>
                    </li>
                    <li>
                      <Link to={isAktif ? "/me/accses-key" : "#"} className={`block px-4 py-2 text-sm  ps-10 hover:bg-[#E9AA991A] rounded-b-md ${getLinkClassTwo("/me/accses-key")}`}>
                        Accses Key
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </li>
            <li>
              <Link to="/me/logout" className={`flex items-center p-2 text-sm rounded ${getActiveClass("/me/logout")}`}>
                <span className="icon-menu mr-3">{getIconTwo("/me/logout", logoutOrange, logoutwhite)}</span>
                Keluar
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
