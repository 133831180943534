import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FiltersTransaksiState {
  selectedTipePembayaran: string[];
  selectedStatus: string[];
  startDate: string | undefined;
  endDate: string | undefined;
}

const initialState: FiltersTransaksiState = {
  selectedTipePembayaran: [],
  selectedStatus: [],
  startDate: undefined,
  endDate: undefined,
};

const FiltersTransaksiSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setTipePembayaran(state, action: PayloadAction<string[]>) {
      state.selectedTipePembayaran = action.payload;
    },
    setStatus(state, action: PayloadAction<string[]>) {
      state.selectedStatus = action.payload;
    },
    setDateRange(
      state,
      action: PayloadAction<{
        start: string | undefined;
        end: string | undefined;
      }>
    ) {
      state.startDate = action.payload.start;
      state.endDate = action.payload.end;
    },
  },
});

export const { setTipePembayaran, setStatus, setDateRange } =
  FiltersTransaksiSlice.actions;
export default FiltersTransaksiSlice.reducer;
