export const loading = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 20 20"
      fill="ffffff30"
    >
      <g clipPath="url(#clip0_888_3322)">
        <path
          d="M7.73906 10.8892C5.80823 11.2609 4.85156 13.4109 4.43656 14.7509C4.39742 14.8758 4.38826 15.0082 4.40981 15.1373C4.43136 15.2664 4.48302 15.3886 4.56061 15.494C4.63819 15.5994 4.73951 15.6851 4.85637 15.7441C4.97323 15.803 5.10234 15.8336 5.23323 15.8334H8.33239C8.67999 15.8335 9.02377 15.761 9.3418 15.6208C9.65983 15.4805 9.94511 15.2754 10.1794 15.0187C10.4137 14.7619 10.5919 14.4591 10.7026 14.1296C10.8134 13.8002 10.8541 13.4512 10.8224 13.1051C10.7781 12.7514 10.6613 12.4107 10.4792 12.1042C10.2971 11.7978 10.0537 11.5323 9.76429 11.3243C9.47484 11.1163 9.14556 10.9703 8.79708 10.8954C8.44859 10.8205 8.0884 10.8184 7.73906 10.8892Z"
          fill="#ffffff30"
        />
        <path
          d="M19.1667 7.4825C18.9457 7.4825 18.7337 7.5703 18.5774 7.72658C18.4211 7.88286 18.3333 8.09482 18.3333 8.31583V12.5H15C14.337 12.5 13.7011 12.7634 13.2322 13.2322C12.7634 13.7011 12.5 14.337 12.5 15V18.3333H4.16667C3.50363 18.3333 2.86774 18.0699 2.3989 17.6011C1.93006 17.1323 1.66667 16.4964 1.66667 15.8333V4.16667C1.66667 3.50363 1.93006 2.86774 2.3989 2.3989C2.86774 1.93006 3.50363 1.66667 4.16667 1.66667H13.3683C13.5893 1.66667 13.8013 1.57887 13.9576 1.42259C14.1139 1.26631 14.2017 1.05435 14.2017 0.833333C14.2017 0.61232 14.1139 0.400358 13.9576 0.244078C13.8013 0.0877974 13.5893 0 13.3683 0L4.16667 0C3.062 0.00132321 2.00296 0.440735 1.22185 1.22185C0.440735 2.00296 0.00132321 3.062 0 4.16667L0 15.8333C0.00132321 16.938 0.440735 17.997 1.22185 18.7782C2.00296 19.5593 3.062 19.9987 4.16667 20H13.6192C14.1665 20.0015 14.7086 19.8945 15.2143 19.6851C15.72 19.4757 16.179 19.168 16.565 18.78L18.78 16.565C19.168 16.179 19.4757 15.72 19.6851 15.2143C19.8945 14.7086 20.0015 14.1665 20 13.6192V8.31583C20 8.09482 19.9122 7.88286 19.7559 7.72658C19.5996 7.5703 19.3877 7.4825 19.1667 7.4825ZM15.3867 17.6017C15.0506 17.934 14.6278 18.165 14.1667 18.2683V15C14.1667 14.779 14.2545 14.567 14.4107 14.4107C14.567 14.2545 14.779 14.1667 15 14.1667H18.2708C18.1675 14.6278 17.9365 15.0506 17.6042 15.3867L15.3867 17.6017Z"
          fill="#ffffff30"
        />
        <path
          d="M12.1375 11.8083C11.9727 11.8083 11.8116 11.7594 11.6746 11.6678C11.5376 11.5763 11.4308 11.4461 11.3677 11.2939C11.3047 11.1416 11.2882 10.9741 11.3203 10.8125C11.3524 10.6508 11.4318 10.5024 11.5483 10.3858L18.0925 3.84167C18.1656 3.76868 18.2236 3.68197 18.2632 3.58652C18.3028 3.49108 18.3232 3.38875 18.3232 3.28542C18.3232 3.18208 18.3028 3.07976 18.2632 2.98431C18.2236 2.88886 18.1656 2.80216 18.0925 2.72917C18.0181 2.65182 17.9279 2.59133 17.8282 2.5518C17.7284 2.51226 17.6213 2.4946 17.5141 2.5C17.407 2.50233 17.3015 2.52678 17.2043 2.57181C17.1071 2.61684 17.0202 2.68147 16.9491 2.76167L10.6158 9.7675C10.4676 9.93149 10.2603 10.0299 10.0396 10.0411C9.81884 10.0522 9.6027 9.97528 9.4387 9.82708C9.27471 9.67889 9.1763 9.47163 9.16513 9.25088C9.15396 9.03014 9.23093 8.81399 9.37912 8.65L15.7125 1.65C15.9346 1.40206 16.2047 1.20182 16.5065 1.06143C16.8084 0.921049 17.1356 0.843447 17.4683 0.833335C17.8015 0.826193 18.1328 0.886102 18.4425 1.0095C18.7521 1.1329 19.0338 1.31727 19.2708 1.55167C19.7302 2.01207 19.9881 2.63588 19.9881 3.28625C19.9881 3.93662 19.7302 4.56044 19.2708 5.02084L12.7266 11.565C12.6494 11.6425 12.5575 11.7039 12.4564 11.7457C12.3553 11.7874 12.2469 11.8087 12.1375 11.8083Z"
          fill="#ffffff30"
        />
      </g>
      <defs>
        <clipPath id="clip0_888_3322">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const detail = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
    >
      <path
        d="M9.5 7.25V9.75C9.5 11.2665 8.2665 12.5 6.75 12.5H2.75C1.2335 12.5 0 11.2665 0 9.75V5.75C0 4.2335 1.2335 3 2.75 3H5.25C5.6645 3 6 3.3355 6 3.75C6 4.1645 5.6645 4.5 5.25 4.5H2.75C2.061 4.5 1.5 5.061 1.5 5.75V9.75C1.5 10.439 2.061 11 2.75 11H6.75C7.439 11 8 10.439 8 9.75V7.25C8 6.8355 8.3355 6.5 8.75 6.5C9.1645 6.5 9.5 6.8355 9.5 7.25ZM10.25 0.5H7.75C7.3355 0.5 7 0.8355 7 1.25C7 1.6645 7.3355 2 7.75 2H9.4395L4.2195 7.2195C3.9265 7.512 3.9265 7.9875 4.2195 8.28C4.366 8.4265 4.558 8.4995 4.75 8.4995C4.942 8.4995 5.134 8.4265 5.2805 8.28L10.5 3.0605V4.75C10.5 5.1645 10.8355 5.5 11.25 5.5C11.6645 5.5 12 5.1645 12 4.75V2.25C12 1.285 11.215 0.5 10.25 0.5Z"
        fill="url(#paint0_linear_580_11186)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_580_11186"
          x1="-3.56391"
          y1="-3.59023"
          x2="11.6692"
          y2="12.6654"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF2725" />
          <stop offset="1" stopColor="#F26023" />
        </linearGradient>
      </defs>
    </svg>
  );
};
