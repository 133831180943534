import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./style.css";
import { AppDispatch, RootState } from "../../../features/stores";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile } from "../../../features/reducers/profileSlice";
import { Link } from "react-router-dom";

interface Project {
  _id: string;
  nama_project: string;
  publicKeySandbox: string | null;
}

const AccsesKeyPerusahaan: React.FC = () => {
  const [accessKeyData, setAccessKeyData] = useState<Project[]>([]);
  const [unlockedProjects, setUnlockedProjects] = useState<string[]>([]);
  const [showPinPopup, setShowPinPopup] = useState(false);
  const [pin, setPin] = useState<string[]>(["", "", "", "", "", ""]);
  const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);
  const [isPinValid, setIsPinValid] = useState<boolean | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [currentProjectId, setCurrentProjectId] = useState<string | null>(null);
  const [timer, setTimer] = useState<{ [key: string]: number }>({});
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const hasEmptyProjectName = accessKeyData.some((project) => project.nama_project === "");

  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector((state: RootState) => state.profile);

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  useEffect(() => {
    const token = localStorage.getItem("token");

    axios
      .get(`${process.env.REACT_APP_API_URL}get-access-key`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          const accessKeyInfo = response.data.data;
          setAccessKeyData(accessKeyInfo);
        }
      })
      .catch((error) => {
        console.error("Gagal mengambil data access key:", error);
        if (error.response?.status === 401) {
          localStorage.removeItem("token");
          window.location.href = "/login";
        }
      });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimers) => {
        const newTimers = { ...prevTimers };
        Object.keys(newTimers).forEach((projectId) => {
          if (newTimers[projectId] > 0) {
            newTimers[projectId] -= 1;
          } else {
            setUnlockedProjects((prev) => prev.filter((id) => id !== projectId));
            delete newTimers[projectId];
          }
        });
        return newTimers;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (showPinPopup) {
      inputRefs.current[0]?.focus();
    }
  }, [showPinPopup]);

  const handlePinSubmit = () => {
    const pinString = pin.join("");
    const token = localStorage.getItem("token");

    axios
      .post(
        `${process.env.REACT_APP_API_URL}pin`,
        { pin: pinString },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === "success" && currentProjectId) {
          setIsPinValid(true);
          setUnlockedProjects((prev) => [...prev, currentProjectId]);
          setTimer((prevTimers) => ({
            ...prevTimers,
            [currentProjectId]: 60,
          }));
          setShowPinPopup(false);
          setErrorMessage("");
          setPin(Array(6).fill("")); // Reset PIN input menjadi kosong
          // setIsPinValid(true); // Reset validasi PIN
        } else {
          setIsPinValid(false);
          setErrorMessage("PIN Tidak Valid");
          setPin(["", "", "", "", "", ""]); // Kosongkan semua input PIN
          inputRefs.current[0]?.focus(); // Fokus kembali ke input pertama
        }
      })
      .catch((error) => {
        console.error("Gagal validasi PIN:", error);
        setIsPinValid(false);
        setErrorMessage("PIN Tidak Valid");
        setPin(["", "", "", "", "", ""]); // Kosongkan semua input PIN
        inputRefs.current[0]?.focus(); // Fokus kembali ke input pertama
      });
  };

  const handlePinChange = (value: string, index: number) => {
    if (/^\d*$/.test(value)) {
      const newPin = [...pin];
      newPin[index] = value;
      setPin(newPin);

      // Hilangkan pesan error ketika PIN diubah
      setIsPinValid(true);
      setErrorMessage("");

      // Move to the next input field if the value is entered
      if (value && index < pin.length - 1) {
        inputRefs.current[index + 1]?.focus();
      } else if (!value && index > 0) {
        // Move to the previous input field if the value is deleted
        inputRefs.current[index - 1]?.focus();
      }

      // Enable button if all fields are filled
      if (newPin.every((digit) => digit !== "")) {
        setIsButtonEnabled(true);
      } else {
        setIsButtonEnabled(false);
      }
    }
  };

  const handleOpenPopup = (projectId: string) => {
    setCurrentProjectId(projectId);
    setShowPinPopup(true);
  };

  const handleClosePopup = () => {
    setShowPinPopup(false);
    setCurrentProjectId(null);
    setPin(Array(6).fill("")); // Reset PIN input menjadi kosong
    setIsPinValid(true); // Reset validasi PIN
    setErrorMessage(""); // Reset pesan error jika ada
  };

  return (
    <section>
      <div className="">
        {accessKeyData.map((project) => (
          <div key={project._id} className=" border border-[#d1d1d1] rounded-2xl w-full p-[37px] mb-10   flex flex-col gap-5 contentacsesP">
            <div className="flex gap-8 mb-3">
              <h1 className="text-xl font-bold text-[#1A1A1A] text-start">
                Access Key <span className="uppercase text-orange-600">{project.nama_project}</span>{" "}
              </h1>
              {unlockedProjects.includes(project._id) && <div className=" text-[#F15A24] bg-[#FEEFE9] px-3 py-1 rounded-full">{timer[project._id] ? `${Math.floor(timer[project._id] / 60)}:${timer[project._id] % 60}` : "00:00"}</div>}
            </div>

            <div className={`flex flex-col gap-5 ${unlockedProjects.includes(project._id) ? "" : "blur-sm cursor-not-allowed select-none"}`}>
              <div className="flex gap-20 justify-start text-start">
                <p className="w-28 font-semibold">Nama Project</p>
                <p>:</p>
                <p className="uppercase">{project.nama_project}</p>
              </div>

              <div className="flex gap-20 justify-start text-start">
                <p className="w-28 font-semibold">ID Project</p>
                <p>:</p>
                <p>{project._id}</p>
              </div>

              <div className="flex gap-20 justify-start text-start">
                <p className="w-28 font-semibold">Public Key</p>
                <p>:</p>
                <p>{project.publicKeySandbox || "Tidak tersedia"}</p>
              </div>
            </div>

            {!unlockedProjects.includes(project._id) && (
              <button onClick={() => handleOpenPopup(project._id)} className="self-start bg-orange-600 text-white py-2 px-4 rounded-full mt-1">
                Lihat Access Key
              </button>
            )}
          </div>
        ))}

        {hasEmptyProjectName ? (
          <div className="fixed inset-0 flex flex-col items-center justify-center z-50 bg-black bg-opacity-70">
            <div className="bg-white p-8 rounded-3xl shadow-lg text-center w-[500px] ms-64">
              <p className="text-red-500 text-[20px]">Anda belum mempunyai proyek, Silahkan Buat Proyek terlebih dahulu!</p>
              <Link to="/me/proyek" className="flex justify-center">
                <div className="mt-4 rounded-full bg-orange-600 text-white py-2 px-5 w-[250px] flex flex-col justify-center">Buat Proyek</div>
              </Link>
            </div>
          </div>
        ) : user?.pin ? (
          showPinPopup && (
            <div className="fixed inset-0 flex flex-col items-center justify-center z-50 bg-black bg-opacity-50">
              <div className="bg-white p-8 rounded-3xl shadow-lg text-center w-[400px] ms-64">
                <h2 className="text-[32px] text-[#222831] font-medium mb-4">Masukan PIN</h2>
                <div className="flex justify-center space-x-5 my-10">
                  {pin.map((pin, index) => (
                    <input
                      key={index}
                      type="password"
                      maxLength={1}
                      value={pin}
                      onChange={(e) => handlePinChange(e.target.value, index)}
                      ref={(el) => (inputRefs.current[index] = el)}
                      className={`w-4 h-4 text-center text-[#F15A24] text-[24px] font-bold rounded-full ${pin ? "bg-[#F15A24]" : "bg-[#F7F7F7]"} focus:outline-none`}
                    />
                  ))}
                </div>

                <div className="flex flex-col">
                  <button className={`p-3 rounded-full mb-2 ${isButtonEnabled ? "bg-orange-600 text-white" : "bg-[#F3F4F8] text-[#BFBFBF] cursor-not-allowed"}`} disabled={!isButtonEnabled} onClick={handlePinSubmit}>
                    Lanjut
                  </button>
                  <button className="text-orange-600 mt-1 rounded-full border border-orange-600 p-3" onClick={handleClosePopup}>
                    Batal
                  </button>
                  {isPinValid === false && <p className="text-red-500 mt-2">{errorMessage}</p>}
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="fixed inset-0 flex flex-col items-center justify-center z-50 bg-black bg-opacity-70">
            <div className="bg-white p-8 rounded-3xl shadow-lg text-center w-[500px] ms-64">
              <p className="text-red-500 text-[20px]">Anda belum mempunyai PIN untuk melihat Access Key, Silahkan Buat PIN terlebih dahulu!</p>
              <Link to="/me/pin" className="flex justify-center">
                <div className="mt-4 rounded-full bg-orange-600 text-white py-2 px-5 w-[250px] flex flex-col justify-center">Buat PIN</div>
              </Link>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default AccsesKeyPerusahaan;
