import React, { useState } from "react";
import { IconCsv, IconExcel, IconPdf, IconPrint } from "./Icons";
import { useSelector } from "react-redux";
import { RootState } from "../../features/stores";
import axios from "axios";

interface ExportDataProps {
  onClose: () => void;
}

const ExportData: React.FC<ExportDataProps> = ({ onClose }) => {
  const [isExporting, setIsExporting] = useState(false);

  const { selectedTipePembayaran, selectedStatus, startDate, endDate } = useSelector((state: RootState) => state.filterTransaksi);

  const token = localStorage.getItem("token");
  const { merchantData } = useSelector((state: RootState) => state.merchant);

  const merchantId = merchantData && merchantData.length > 0 ? merchantData[0].merchant_id : null;

  const handleExport = async (format: "pdf" | "excel" | "csv", fileName: string) => {
    if (!merchantId) {
      console.error("Merchant ID is not available.");
      return;
    }

    const paymentMethod = selectedTipePembayaran.join(",");
    const status = selectedStatus.join(",");

    setIsExporting(true);

    try {
      const response = await axios.get<Blob>(`${process.env.REACT_APP_API_URL}export-${format}`, {
        params: {
          merchant_id: merchantId,
          start_date: startDate || "",
          end_date: endDate || "",
          payment_method: paymentMethod,
          status: status,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      });

      const blobUrl = window.URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error(`Failed to export ${format.toUpperCase()}:`, error);
    } finally {
      setIsExporting(false);
    }
  };

  const handleExportClick = (e: React.MouseEvent, format: "pdf" | "excel" | "csv", fileName: string) => {
    e.stopPropagation();
    handleExport(format, fileName);
    onClose();
  };

  if (!merchantId) {
    return null;
  }

  return (
    <section onClick={onClose} className={`bg-white w-[128px] rounded-[8px] p-[4px] border border-slate-100 shadow-sm ${isExporting ? "cursor-progress" : "cursor-pointer"}`}>
      <div className="flex gap-[8px] px-[14px] hover:bg-slate-100 rounded-md">
        <div className="py-[15px]">
          <IconPrint />
        </div>
        <p className="text-[#4A4D4F] py-[16px] text-[14px] font-normal">Print</p>
      </div>
      <div className="flex gap-[8px] px-[14px] hover:bg-slate-100 rounded-md" onClick={(e) => handleExportClick(e, "excel", "Data Transaksi.xlsx")}>
        <div className="py-[15px]">
          <IconExcel />
        </div>
        <p className="text-[#4A4D4F] py-[16px] text-[14px] font-normal">Excel</p>
      </div>
      <div className="flex gap-[8px] px-[14px] hover:bg-slate-100 rounded-md" onClick={(e) => handleExportClick(e, "csv", "Data Transaksi.csv")}>
        <div className="py-[15px]">
          <IconCsv />
        </div>
        <p className="text-[#4A4D4F] py-[16px] text-[14px] font-normal">Csv</p>
      </div>
      <div className="flex gap-[8px] px-[14px] hover:bg-slate-100 rounded-md" onClick={(e) => handleExportClick(e, "pdf", "Data Transaksi.pdf")}>
        <div className="py-[15px]">
          <IconPdf />
        </div>
        <p className="text-[#4A4D4F] py-[16px] text-[14px] font-normal">PDF</p>
      </div>
    </section>
  );
};

export default ExportData;
