import React, { useEffect, useState } from "react";

interface TransactionTableProps {
  currentItems: any[];
}

const getStatusColor = (status: string) => {
  switch (status) {
    case "Berhasil":
      return "#7EF124";
    case "Dibatalkan":
      return "#F12424";
    case "Menunggu":
      return "#F1AB24";
    case "Challenge":
      return "#1A1A1A";
    case "Setlement":
      return "#2439F1";
    default:
      return "#000000";
  }
};

const TransactionTable: React.FC<TransactionTableProps> = ({ currentItems }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <table className="table-auto w-full ">
      <thead>
        <tr className="text-[#85898C]  font-medium border-b border-t text-[14px]">
          <th className="p-2 w-[150px]">Order ID</th>
          <th className="p-2">Tanggal Transaksi</th>
          <th className="p-2">Jumlah</th>
          <th className="p-2">Tipe Pembayaran</th>
          <th className="p-2">Status</th>
          <th className="p-2 w-[120px]">Aksi</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan={6} className="text-center p-2">
              Processing...
            </td>
          </tr>
        ) : currentItems.length > 0 ? (
          currentItems.map((item, index) => (
            <tr key={index} className="border-b">
              <td className="p-2">{item.order_id}</td>
              <td className="p-2">
                {new Date(item.tanggal_transaksi)
                  .toLocaleDateString("id-ID", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })
                  .replace(/\//g, "-")}
              </td>

              <td className="p-2">Rp.{item.jumlah?.toLocaleString("id-ID")}</td>
              <td className="p-2">{item.tipe_pembayaran}</td>

              <td className="p-2 " style={{ color: getStatusColor(item.status) }}>
                <p className="px-[5px] py-[5px] bg-[#FDDEDE] rounded-full">{item.status}</p>
              </td>
              <td className="p-2">Detail</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={6} className="text-center p-2">
              No data available
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default TransactionTable;
