import React from "react";
import { arrowewalet } from "../../../../assets/icon";
import Phone from "../../../../assets/images/ewalet.png";

const Ewalet = () => {
  const handleClick = () => {
    window.location.href = "/udin-payment";
  };
  return (
    <section className="flex justify-between mt-24 mb-5 ms-4">
      <div className="w-[725px]">
        <div className=" text-[60px] mb-4 font-bold ">
          <div className="mb-2 text-start ">
            <h1>Kami Memiliki </h1>
            <h1 className="-mt-6">
              E-Wallet <span className="bg-gradient-to-r from-[#EF2725]  via-[#EF2725] to-[#F26023] inline-block text-transparent bg-clip-text">Udin</span>{" "}
            </h1>
          </div>
        </div>
        <div className="text-lg text-start mb-5  text-[#696B6D]">
          Udin adalah solusi pembayaran digital yang dirancang untuk memudahkan transaksi sehari-hari Anda. Dengan Udin, Anda dapat melakukan berbagai jenis pembayaran dengan cepat, aman, dan nyaman. Mulai dari membayar tagihan, berbelanja
          online, hingga transfer uang ke teman dan keluarga, semua bisa dilakukan dengan beberapa sentuhan.
        </div>
        <button onClick={handleClick} className="bg-black border-[3px] border-[#E9EAEA]  py-3 px-12 flex justify-start rounded-full text-white">
          Kunjungi Website Udin{" "}
        </button>
      </div>

      <div className="mt-60">{arrowewalet()}</div>

      <div>
        <img src={Phone} alt="" />
      </div>
    </section>
  );
};

export default Ewalet;
