import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import transactionSlice from "../reducers/transactionSlice";
import profileSlice from "../reducers/profileSlice";
import merchantSlice from "../reducers/merchantSlice";
import transactionHistorySlice from "../reducers/transactinHistorySlice";
import exportDataSlice from "../reducers/exportData";
import filterTransaksiSlice from "../reducers/filterTransaksi";
import proyekSlice from "../reducers/proyekSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import modeReducer from "../reducers/mode";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["transactions", "profile", "merchant", "filterTransaksi", "project"],
};

const rootReducer = combineReducers({
  transactions: transactionSlice,
  transactionHistory: transactionHistorySlice,
  profile: profileSlice,
  merchant: merchantSlice,
  exportData: exportDataSlice,
  filterTransaksi: filterTransaksiSlice,
  project: proyekSlice,
  mode: modeReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
        ignoredPaths: ["register"],
      },
    }),
});

// export const mode = configureStore({
//   reducer: {
//     mode: modeReducer,
//   },
// });

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
