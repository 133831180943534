import React, { useState, useEffect } from "react";
import axios from "axios";
import PinPopup from "./gantinorek/index";
import WithdrawBalance from "./withdrawBalance";
import { IconChevronTop } from "./icons";
import DetailSaldo from "./DetailSaldo";
import { detail } from "../../assets/loading";

interface RekeningData {
  nama_pemilik_rekening: string;
  bank_id: {
    namaBank: string;
  };
  nomor_rekening: string;
  cabang_bank: string;
  alamat_bank: string;
}

const Saldo: React.FC = () => {
  const [isPinPopupOpen, setPinPopupOpen] = useState(false);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [rekeningData, setRekeningData] = useState<RekeningData | null>(null);

  const handleWithdrawClick = () => {
    setShowWithdraw(true);
  };

  const handleCloseWithdraw = () => {
    setShowWithdraw(false);
  };

  const handleGantiRekeningClick = () => {
    setPinPopupOpen(true);
  };

  const closePinPopup = () => {
    setPinPopupOpen(false);
  };

  const handleOpenDetailSaldo = () => {
    setShowExport(true);
  };

  const handleCloseDetailSaldo = () => {
    setShowExport(false);
  };

  useEffect(() => {
    const fetchRekeningData = () => {
      const token = localStorage.getItem("token");

      axios
        .get(`${process.env.REACT_APP_API_URL}profile-perusahaan`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const data = response.data.data.rekening; // Assuming response.data.rekening contains the rekening data
          setRekeningData({
            nama_pemilik_rekening: data.nama_pemilik_rekening,
            bank_id: data.bank_id,
            nomor_rekening: data.nomor_rekening,
            cabang_bank: data.cabang_bank,
            alamat_bank: data.alamat_bank,
          });
        })
        .catch((error) => {
          console.error("Terjadi kesalahan saat mengambil data:", error);
        });
    };

    fetchRekeningData();
  }, []);

  return (
    <section className="flex gap-8">
      <div>
        <div className="w-[450px] h-[280px] relative bg-[#1a1a1a] rounded-[22px] overflow-hidden">
          {/* Lingkaran yang berada di dalam batas kontainer */}
          <div className="w-[377px] h-[377px] left-[-109px] top-[210px] absolute bg-[#e8e8e8]/10 object-cover rounded-full " />
          <div className="w-[377px] h-[377px] left-[200px] top-[-307px] absolute bg-[#e8e8e8]/10 object-cover rounded-full " />

          {/* Konten saldo */}
          <div className="w-[413px] h-[31px] left-[30px] top-[43px] absolute justify-start items-center gap-2.5 inline-flex">
            <div className="text-white text-xl font-semibold leading-normal">Total Saldo</div>
          </div>

          <div className="w-[413px] h-[58px] left-[30px] top-[107px] absolute justify-start items-center gap-1.5 inline-flex">
            <div className="grow shrink basis-0 text-white text-5xl text-start font-semibold leading-[57.60px]">Rp. 0</div>
          </div>

          {/* Tombol Tarik Saldo */}
          <div className="w-[158px] left-[260px] top-[215px] absolute rounded-[100px] justify-start items-center inline-flex cursor-pointer">
            <div className="grow shrink basis-0 h-[40px] px-5 py-2.5 bg-[#f15a24] rounded-[100px] justify-start items-start flex">
              <div onClick={handleWithdrawClick} className="grow shrink basis-0 text-center text-white text-sm font-medium tracking-tight">
                Tarik Saldo
              </div>
            </div>
          </div>
        </div>
        <div>{showWithdraw && <WithdrawBalance onClose={handleCloseWithdraw} />}</div>

        <div className="w-[450px] h-[346px] relative bg-white rounded-[22px] border border-[#f4f7f8] overflow-hidden mt-6 mb-6">
          {/* Elemen dekoratif melingkar */}
          <div className="w-[377px] h-[377px] left-[-144px] top-[304px] absolute bg-[#1a1a1a]/5 rounded-full border" />
          <div className="w-[377px] h-[377px] left-[210px] top-[-338px] absolute bg-[#1a1a1a]/5 rounded-full border" />

          {/* Konten utama */}
          <section className="h-[274px] left-[32px] top-[35.5px] absolute flex flex-col justify-start items-start gap-5">
            <article className="w-full flex flex-col justify-start items-start gap-7">
              <div className="w-[380px] flex flex-col justify-start text-start items-start gap-5">
                {/* Nama Pemilik Rekening */}
                <div className="w-full flex items-start gap-5">
                  <p className="text-[#1a1a1a] text-sm font-bold tracking-tight w-40">Nama Pemilik Rekening</p>
                  <span className="text-[#1a1a1a] text-sm font-medium tracking-tight">:</span>
                  <p className="text-[#1a1a1a] text-sm font-normal leading-[16.8px]">{rekeningData?.nama_pemilik_rekening}</p>
                </div>

                <div className="w-full flex items-start gap-5">
                  <p className="text-[#1a1a1a] text-sm font-bold tracking-tight w-40">Nama Bank</p>
                  <span className="text-[#1a1a1a] text-sm font-medium tracking-tight">:</span>
                  <p className="text-[#1a1a1a] text-sm font-normal leading-[16.8px]">{rekeningData?.bank_id.namaBank}</p>
                </div>

                <div className="w-full flex items-start gap-5">
                  <p className="text-[#1a1a1a] text-sm font-bold tracking-tight w-40">Nomor Rekening</p>
                  <span className="text-[#1a1a1a] text-sm font-medium tracking-tight">:</span>
                  <p className="text-[#1a1a1a] text-sm font-normal leading-[16.8px]">{rekeningData?.nomor_rekening}</p>
                </div>

                <div className="w-full flex items-start gap-5">
                  <p className="text-[#1a1a1a] text-sm font-bold tracking-tight w-40">Cabang Bank</p>
                  <span className="text-[#1a1a1a] text-sm font-medium tracking-tight">:</span>
                  <p className="text-[#1a1a1a] text-sm font-normal leading-[16.8px]">{rekeningData?.cabang_bank}</p>
                </div>

                <div className="w-full flex items-start gap-5">
                  <p className="text-[#1a1a1a] text-sm font-bold tracking-tight w-[160px]">Alamat Bank</p>
                  <span className="text-[#1a1a1a] text-sm font-medium tracking-tight">:</span>
                  <p className="text-[#1a1a1a] text-sm font-normal leading-[16.8px] w-[180px]">{rekeningData?.alamat_bank}</p>
                </div>
              </div>
            </article>

            {/* Tombol */}
            <button className="w-full px-5 py-3 rounded-[100px] border border-[#bababa] text-[#bababa] text-base font-medium tracking-tight cursor-not-allowed pointer-events-none pointer" onClick={handleGantiRekeningClick}>
              Ganti Nomor Rekening
            </button>
          </section>
        </div>
      </div>

      <div className="w-full border border-[#F4F7F8] rounded-2xl p-2">
        <div className="flex gap-5 justify-between mb-[20px]">
          <h1 className="text-[#1A1A1A] text-xl font-semibold">Riwayat</h1>
          <div onClick={handleOpenDetailSaldo} className="flex items-center gap-2 cursor-pointer">
            <p className="text-[#f15a24] font-medium">Lihat detail mutasi</p>
            {detail()}
          </div>
        </div>

        {showExport && <DetailSaldo onClose={handleCloseDetailSaldo} />}

        <div className="flex justify-between">
          <div className="flex gap-[15px] text-left">
            <div className="bg-[#DDDDDD] border border-[#E8E8E8] p-[12px] w-[36px] h-[38px] rounded-[9px]">
              <IconChevronTop />
            </div>
            <div>
              <p className="text-[#818284]  text-[14px]">********** 896</p>
              <p className="text-[#4A4D4F] text-[12px]">Rabu, 17 / 07 / 2024, 14.00 WIB</p>
            </div>
          </div>
          <p className="text-[#222831] font-medium ">Rp. 2.000.000</p>
        </div>
      </div>

      {/* PIN Popup */}
      <PinPopup isOpen={isPinPopupOpen} onClose={closePinPopup} />
    </section>
  );
};

export default Saldo;
